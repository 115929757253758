import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Stage, Layer, Rect, Text, Path } from 'react-konva';
import { InPageToggleButtonGroup, InPageToggleButton } from './CustomToggleButton'
import { ViewStream, TableRows, TableChart, ViewDay } from '@mui/icons-material';
import './InvoicePanel.css';

const QuotePanel = ({ totalCost, invoicesState, setInvoicesState, setNumInvoices }) => {
  const containerRef = React.useRef(null);
  const [numInvoices, setNumInvoicesState] = React.useState(invoicesState.length);
  const [containerDimensions, setContainerDimensions] = React.useState({ width: 0, height: 0 });
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const PADDING = 20;

  React.useEffect(() => {
    if (containerRef.current) {
      setContainerDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, [containerRef.current]);

  React.useEffect(() => {
    const newInvoices = Array.from({ length: numInvoices }, (_, i) => ({
      id: i + 1,
      percentage: Math.floor(100 / numInvoices),
    }));

    const totalAssigned = newInvoices.reduce((acc, invoice) => acc + invoice.percentage, 0);
    newInvoices[newInvoices.length - 1].percentage += 100 - totalAssigned;

    setInvoicesState(newInvoices);
  }, [numInvoices, setInvoicesState]);

  const handleLineDrag = (e, index) => {
    const newInvoices = [...invoicesState];
    const totalHeight = containerDimensions.height - 2 * PADDING;
    const newY = e.target.y() - PADDING;
    const newPercentage = Math.round((newY / totalHeight) * 100);

    const prevInvoiceHeight = invoicesState.slice(0, index).reduce((acc, inv) => acc + inv.percentage, 0);
    const currentHeight = invoicesState[index].percentage;
    const nextHeight = invoicesState[index + 1].percentage;

    if (newPercentage <= prevInvoiceHeight || newPercentage >= prevInvoiceHeight + currentHeight + nextHeight) {
      return;
    }

    newInvoices[index].percentage = newPercentage - prevInvoiceHeight;
    newInvoices[index + 1].percentage = currentHeight + nextHeight - newInvoices[index].percentage;

    setInvoicesState(newInvoices);
  };

  const handleInvoiceClick = (index) => {
    setSelectedInvoice(index === selectedInvoice ? null : index);
  };

  const drawRoundedRect = (x, y, width, height, radius) => {
    return `
      M ${x + radius} ${y}
      L ${x + width - radius} ${y}
      Q ${x + width} ${y} ${x + width} ${y + radius}
      L ${x + width} ${y + height - radius}
      Q ${x + width} ${y + height} ${x + width - radius} ${y + height}
      L ${x + radius} ${y + height}
      Q ${x} ${y + height} ${x} ${y + height - radius}
      L ${x} ${y + radius}
      Q ${x} ${y} ${x + radius} ${y}
      Z
    `;
  };

  const InvoiceText = ({ x, y, text, index }) => (
    <Text
      x={x}
      y={y}
      text={text}
      fontSize={18}
      fill="black"
      align="center"
      width={containerDimensions.width * 0.95 - 40}
      offsetX={(containerDimensions.width * 0.95 - 40) / 2}
      onClick={() => handleInvoiceClick(index)}
    />
  );

  return (
    <Box p={2} border="0px solid #fff" borderRadius="8px" boxShadow={0} style={{
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)',
      height: '90%',
      overflow: 'hidden',
      position: 'relative'
    }}>
      <Box my={2}>
        <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: 'bold', margin: '5px 0px', color: 'black' }}>Invoices</Typography>
        <InPageToggleButtonGroup value={numInvoices} exclusive onChange={(e, val) => setNumInvoicesState(val)} fullWidth>
          <InPageToggleButton value={1}>1</InPageToggleButton>
          <InPageToggleButton value={2}>2</InPageToggleButton>
          <InPageToggleButton value={3}>3</InPageToggleButton>
          <InPageToggleButton value={4}>4</InPageToggleButton>
        </InPageToggleButtonGroup>
      </Box>
      <Box my={2}>
        <InPageToggleButtonGroup exclusive fullWidth>
          <InPageToggleButton value={1}><ViewStream /></InPageToggleButton>
          <InPageToggleButton value={2}><TableRows /></InPageToggleButton>
          <InPageToggleButton value={3}><TableChart /></InPageToggleButton>
          <InPageToggleButton value={4}><ViewDay /></InPageToggleButton>
        </InPageToggleButtonGroup>
      </Box>
      <div className="konva-container" ref={containerRef} style={{ flex: 1, height: 'calc(100% - 160px)' }}>
        <Stage width={containerDimensions.width * 0.95} height={containerDimensions.height + 2 * PADDING}>
          <Layer y={PADDING}>
            {invoicesState.map((invoice, index) => {
              const topY = invoicesState.slice(0, index).reduce((acc, inv) => acc + (inv.percentage / 100) * (containerDimensions.height - 2 * PADDING), 0);
              const height = (invoice.percentage / 100) * (containerDimensions.height - 2 * PADDING);
              const handleY = topY + height;
              const rectPath = drawRoundedRect(20, topY, containerDimensions.width * 0.95 - 40, height, 10);

              return (
                <React.Fragment key={index}>
                  <Path
                    data={rectPath}
                    fill={selectedInvoice === index ? 'lightblue' : 'rgba(250,250,250, 0.8)'}
                    stroke={selectedInvoice === index ? 'blue' : 'grey'}
                    strokeWidth={1}
                  />
                  <InvoiceText x={20 + (containerDimensions.width * 0.95 - 40) / 2} y={topY + height / 2 - 50} text={`Invoice ${invoice.id}`} index={index} />
                  <InvoiceText x={20 + (containerDimensions.width * 0.95 - 40) / 2} y={topY + height / 2 - 20} text={`${invoice.percentage}%`} index={index} />
                  <InvoiceText x={20 + (containerDimensions.width * 0.95 - 40) / 2} y={topY + height / 2 + 10} text={`$${((invoice.percentage / 100) * totalCost).toFixed(2)}`} index={index} />
                  {index < invoicesState.length - 1 && (
                    <Rect
                      x={containerDimensions.width * 0.95 / 2 - 30}
                      y={handleY - 10}
                      width={60}
                      height={20}
                      fill="blue"
                      draggable
                      dragBoundFunc={(pos) => ({
                        x: containerDimensions.width * 0.95 / 2 - 30,
                        y: pos.y,
                      })}
                      onDragMove={(e) => handleLineDrag(e, index)}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </Layer>
        </Stage>
      </div>
    </Box>
  );
}



export default QuotePanel;
