import React, { useState, useEffect, useRef } from 'react';
import { PowerInputRounded, Light, Window, DoorFront, PhotoLibrary,ViewQuilt,ColorLens,FormatPaint,CheckBoxOutlineBlank,Construction,MeetingRoomOutlined} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // For select icon
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Tooltip from '@mui/material/Tooltip';

import {Box,Typography,TextField,Divider,FormControl,InputLabel,Select,MenuItem} from '@mui/material';
import theme from './theme';
import {NavToggleButtonGroup,NavToggleButton,InPageToggleButtonGroup,InPageToggleButton } from './CustomToggleButton';
import { PIXELS_PER_METER } from './components/Floorplan/Constants';
import CustomTooltip from './components/CustomTooltip';

const thumbnailImages = {
  image0001: '/images/ai/bedroom_template/white/thumbnails/bedroom_white_001.webp',
  image0002: '/images/ai/bedroom_template/white/thumbnails/bedroom_white_002.webp',
  image0003: '/images/ai/bedroom_template/white/thumbnails/bedroom_white_003.webp',
  image0004: '/images/ai/bedroom_template/white/thumbnails/bedroom_white_004.webp',
  image0005: '/images/ai/bedroom_template/white/thumbnails/bedroom_white_005.webp',
};

const largeImages = {
  image0001: '/images/ai/bedroom_template/white/screen/bedroom_white_001.webp',
  image0002: '/images/ai/bedroom_template/white/screen/bedroom_white_002.webp',
  image0003: '/images/ai/bedroom_template/white/screen/bedroom_white_003.webp',
  image0004: '/images/ai/bedroom_template/white/screen/bedroom_white_004.webp',
  image0005: '/images/ai/bedroom_template/white/screen/bedroom_white_005.webp',
};

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const inputStyles = { fontSize: '18px', padding: '2px' };
const { SwatchBoxRoom, SwatchColorBoxRoom, SwatchNameRoom } = theme.styledComponents;

const fetchAndCacheData = async (url, key) => {
  if (window.dataCache && window.dataCache[key]) {
    return window.dataCache[key];
  }
  const cachedData = localStorage.getItem(key);
  if (cachedData) {
    const parsedData = JSON.parse(cachedData);
    if (!window.dataCache) window.dataCache = {};
    window.dataCache[key] = parsedData;
    return parsedData;
  }
  const response = await fetch(url);
  const data = await response.json();
  localStorage.setItem(key, JSON.stringify(data));
  if (!window.dataCache) window.dataCache = {};
  window.dataCache[key] = data;
  return data;
};

const RoomOptions = ({
  roomName,
  setRoomName,
  roomLength,
  setRoomLength,
  roomWidth,
  setRoomWidth,
  paintBrandRoom,
  setPaintBrandRoom,
  paintColor,
  setPaintColor,
  skirtingBoards,
  setSkirtingBoards,
  architrave,
  setArchitrave,
  windows,
  setWindows,
  doors,
  setDoors,
  ceiling,
  setCeiling,
  paintFinish,
  setPaintFinish,
  rooms,
  setRooms,
  selectedRoomId,
}) => {
  const [photos, setPhotos] = useState([]);
  const [selectedOption, setSelectedOption] = useState('colour');
  const [selectedSwatch, setSelectedSwatch] = useState(null);
  const [paintBrands, setPaintBrands] = useState([]);
  const [paintTypes, setPaintTypes] = useState([]);
  const [selectedPaintType, setSelectedPaintType] = useState('');
  const [paintSizes, setPaintSizes] = useState([]);
  const [paintSize, setPaintSize] = useState('');
  const [selectedCanvas, setSelectedCanvas] = useState(null);
  const [style, setStyle] = useState('allWalls');
  const canvasWidth = 230;
  const canvasRefs = useRef([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);
  const fullscreenCanvasRef = useRef(null);




  const handleOpenFullscreen = (index) => {
    console.log('FullScreen Open', index)
    setIsFullscreenOpen(true);
    setSelectedImageUrl(photos[index]?.url || photos[index]?.displayImageUrl);
  };

  useEffect(() => {
    if (isFullscreenOpen && selectedImageUrl) {
      const img = new Image();
      img.src = selectedImageUrl;
      img.onload = () => {
        const canvas = fullscreenCanvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        }
      };
    }
  }, [isFullscreenOpen, selectedImageUrl]); // Check if both dependencies trigger re-render
  

 
const handleDeletePhoto = (index) => {
  // Implement your delete logic here
  // For example, remove the photo from the 'photos' array
  setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
};


const [selectedPhotos, setSelectedPhotos] = useState([]);

const handleSelectPhoto = (index) => {
  setSelectedPhotos((prevSelected) =>
    prevSelected.includes(index)
      ? prevSelected.filter((i) => i !== index)
      : [...prevSelected, index]
  );
};

 useEffect(() => {
    if (selectedRoomId && selectedOption === 'album') {
      const fetchPhotos = async () => {
        try {
          const response = await fetch(`${API_ENDPOINT}/api/photos/room/${selectedRoomId}`);
          const data = await response.json();
          if (response.ok) {
            setPhotos(data);
          } else {
            console.error(data.message);
          }
        } catch (error) {
          console.error('Error fetching photos:', error);
        }
      };
      

      fetchPhotos();
    }
  }, [selectedRoomId, selectedOption]);

  const handleCanvasClick = (index, source = 'config') => {
    setSelectedCanvas(index);

    let newImageUrl;
    if (source === 'config') {
      const imageUrls = Object.values(largeImages);
      newImageUrl = imageUrls[index];
    } else if (source === 'album') {
      newImageUrl = photos[index]?.url || photos[index]?.displayImageUrl;
    }

    if (newImageUrl) {
      setSelectedImageUrl(null);
      setTimeout(() => {
        setSelectedImageUrl(newImageUrl);
        localStorage.setItem('selectedImageUrl', newImageUrl);
      }, 0);
    } else {
      console.warn('selectedImageUrl is null or undefined');
    }
  };

  useEffect(() => {
    if (selectedOption === 'config') {
      const imageUrls = Object.values(thumbnailImages);
      canvasRefs.current.forEach((canvas, index) => {
        if (canvas && imageUrls[index]) {
          const ctx = canvas.getContext('2d');
          const img = new Image();
          img.src = imageUrls[index];

          img.onload = () => {
            ctx.fillStyle = 'rgba(128, 128, 128, 0.5)';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            const aspectRatio = img.width / img.height;
            let drawWidth = canvas.width;
            let drawHeight = canvas.width / aspectRatio;

            const offsetX = (canvas.width - drawWidth) / 2;
            const offsetY = (canvas.height - drawHeight) / 2;
            ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
          };
          img.onerror = () => {
            console.error(`Failed to load image: ${img.src}`);
          };
        }
      });
    }
  }, [selectedOption]);

  const ThumbnailOverlay = () => {
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          gap: '10px',
        }}
      >
        {/* <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <span style={{ fontSize: '64px' }}>🔘</span>
        </div> */}
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const brandData = await fetchAndCacheData('/json/paintBrands.json', 'paintBrands');
      setPaintBrands(brandData.paintBrands);
      const typeData = await fetchAndCacheData('/json/paintTypes.json', 'paintTypes');
      setPaintTypes(typeData);
      // const sizeData = await fetchAndCacheData('/json/paintSizes.json', 'paintSizes');
      // setPaintSizes(sizeData);
    };
    fetchData();
  }, []);

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  useEffect(() => {
    if (selectedRoomId) {
      const selectedRoom = rooms.find((room) => room.id === selectedRoomId);
      if (selectedRoom) {
        setRoomWidth((selectedRoom.width / PIXELS_PER_METER).toFixed(1));
        setRoomLength((selectedRoom.length / PIXELS_PER_METER).toFixed(1));
        setRoomName(selectedRoom.name);
      }
    }
  }, [selectedRoomId, rooms]);

  const handleChange = (name) => (e) => {
    const value = e.target.value;
    setRooms((prevRooms) =>
      prevRooms.map((room) =>
        room.id === selectedRoomId
          ? {
              ...room,
              [name]: name === 'name' ? value : parseFloat(value) * PIXELS_PER_METER,
            }
          : room
      )
    );
  };

  const getPaintDetails = (rgbColor) => {
    // Retrieve and parse `savedPalettes` from local storage
    const savedPalettes = JSON.parse(localStorage.getItem('savedPalettes')) || [];
  
    for (const paletteItem of savedPalettes) {
      const foundSwatch = paletteItem.swatchData.find((swatch) => swatch.rgb === rgbColor);
      if (foundSwatch) {
        return { brand: foundSwatch.brand, name: foundSwatch.name };
      }
    }
    return { brand: "Unknown", name: "Unknown" };
  };
  
  

  const handleStyleChange = (event, newStyle) => {
    if (newStyle !== null) {
      setStyle(newStyle);
    }
  };

  
  const renderThumbnailImages = () => {
    return (
      <Box mt={1} display="grid" gridTemplateColumns="repeat(1, 1fr)" gap={2}>
        {photos.map((photo, index) => (
          <div key={index} style={{ position: 'relative' }}>
            <canvas
              ref={(el) => (canvasRefs.current[index] = el)}
              width={460}
              height={Math.round((460 * 9) / 16)}
              onClick={() => handleCanvasClick(index, 'album')}
              style={{
                width: '230px',
                height: `${Math.round((230 * 9) / 16)}px`,
                border: '2px solid white',
                borderRadius: '2px',
                backgroundColor: 'rgba(128, 128, 128, 0.2)',
                cursor: 'pointer',
                boxShadow:
                  selectedCanvas === index ? '0 0 5px 3px rgba(0, 123, 255, 0.3)' : 'none',
                borderColor: selectedCanvas === index ? 'white' : 'white',
              }}
            />
            {selectedCanvas === index && <ThumbnailOverlay />}
            <img
              src={photo.displayImageUrl}
              alt="thumbnail"
              style={{
                width: '100%',
                height: 'auto',
                display: 'none',
              }}
              onLoad={(e) => {
                const img = e.target;
                const canvas = canvasRefs.current[index];
                if (canvas) {
                  const ctx = canvas.getContext('2d');
                  ctx.clearRect(0, 0, canvas.width, canvas.height);
                  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                }
              }}
            />
  
            {/* Delete Icon */}
            <IconButton
              onClick={() => handleDeletePhoto(index)}
              style={{
                position: 'absolute',
                top: 5,
                right: 5,
                color: 'white',
              }}
            >
              <DeleteIcon />
            </IconButton>
  
            {/* Select Icon */}
            <IconButton
              onClick={() => handleSelectPhoto(index)}
              style={{
                position: 'absolute',
                bottom: 5,
                left: 5,
                color: selectedPhotos.includes(index) ? 'green' : 'white',
              }}
            >
              <CheckCircleIcon />
            </IconButton>
  
            {/* Fullscreen Icon */}
            <IconButton
              onClick={() => handleOpenFullscreen(index)}
              style={{
                position: 'absolute',
                bottom: 5,
                right: 5,
                color: 'white',
              }}
            >
              <FullscreenIcon />
            </IconButton>
          </div>
        ))}
      </Box>
    );
  };
  

  const renderOptionInputs = () => {
    switch (selectedOption) {
      case 'paint':
        return (
          <Box>
            <FormControl fullWidth margin="normal">
              <InputLabel style={{ fontSize: '14px' }}>Paint Brand</InputLabel>
              <Select
                value={paintBrandRoom || ''}
                onChange={(e) => setPaintBrandRoom(e.target.value)}
                style={{ fontSize: '14px' }}
              >
                {paintBrands &&
                  paintBrands.map((brand) => (
                    <MenuItem key={brand.brandID} value={brand.name}>
                      {brand.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel style={{ fontSize: '14px' }}>Paint Type</InputLabel>
              <Select
                value={selectedPaintType}
                onChange={(e) => setSelectedPaintType(e.target.value)}
                style={{ fontSize: '14px' }}
              >
                {paintTypes &&
                  paintTypes.map((type, index) => (
                    <MenuItem key={index} value={type.type}>
                      {type.type}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        );
      case 'colour':
        const selectedRoom = rooms.find((room) => room.id === selectedRoomId);
        if (!selectedRoom) return null;

        switch (style) {
          case 'allWalls':
    const wallColors = [
      {
        name: 'Front Wall',
        color: selectedRoom.walls.top.color || '#FFFFFF',
        wall: 'top',
        ...getPaintDetails(selectedRoom.walls.top.color || '#FFFFFF'),
      },
      {
        name: 'Rear Wall',
        color: selectedRoom.walls.bottom.color || '#FFFFFF',
        wall: 'bottom',
        ...getPaintDetails(selectedRoom.walls.bottom.color || '#FFFFFF'),
      },
      {
        name: 'Left Wall',
        color: selectedRoom.walls.left.color || '#FFFFFF',
        wall: 'left',
        ...getPaintDetails(selectedRoom.walls.left.color || '#FFFFFF'),
      },
      {
        name: 'Right Wall',
        color: selectedRoom.walls.right.color || '#FFFFFF',
        wall: 'right',
        ...getPaintDetails(selectedRoom.walls.right.color || '#FFFFFF'),
      },
    ];

    return (
      <Box sx={{ mb: 4, p: 0 }}>
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={1} mt={2}>
          {wallColors.map((wallColor, index) => (
            <SwatchBoxRoom key={index} selected={selectedSwatch === wallColor.wall}><span style={{ fontWeight: 'bold', paddingTop: '4px', paddingBottom: '4px', display: 'block' }}>
            {wallColor.wall}
          </span>
              <SwatchColorBoxRoom bgcolor={wallColor.color}/>
              <SwatchNameRoom>
                <span style={{ fontWeight: 'bold' }}>{wallColor.brand}</span> <br />
                {wallColor.name}
              </SwatchNameRoom>
            </SwatchBoxRoom>
          ))}
        </Box>
      </Box>

    );

  case 'Ceiling':
    const ceilingColor = selectedRoom.ceilingColour?.color || '#FFFFFF';
    const ceilingDetails = getPaintDetails(ceilingColor);

    return (
      <Box sx={{ mb: 4, p: 0 }}>
        <Box display="grid" gridTemplateColumns="repeat(1, 1fr)" gap={1} mt={2}>
          <SwatchBoxRoom selected={selectedSwatch === 'ceilingColour'}>
            <Tooltip title={`${ceilingDetails.brand} - ${ceilingDetails.name}`} arrow>
              <SwatchColorBoxRoom bgcolor={ceilingColor} />
            </Tooltip>
            <SwatchNameRoom>Ceiling</SwatchNameRoom>
          </SwatchBoxRoom>
        </Box>
      </Box>
    );
          case 'Doors':
            const doorColor = selectedRoom.doorColour?.color || '#FFFFFF';
            return (
              <Box sx={{ mb: 4, p: 0 }}>
                <Box display="grid" gridTemplateColumns="repeat(1, 1fr)" gap={1} mt={2}>
                  <SwatchBoxRoom
                    // onClick={() => handleSwatchClick(doorColor, 'doorColour')}
                    selected={selectedSwatch === 'doorColour'}
                  >
                    <SwatchColorBoxRoom bgcolor={doorColor} />
                    <SwatchNameRoom>Doors</SwatchNameRoom>
                  </SwatchBoxRoom>
                </Box>
              </Box>
            );
          case 'Windows':
            const windowColor = selectedRoom.windowColour?.color || '#FFFFFF';
            return (
              <Box sx={{ mb: 4, p: 0 }}>
                <Box display="grid" gridTemplateColumns="repeat(1, 1fr)" gap={1} mt={2}>
                  <SwatchBoxRoom
                    // onClick={() => handleSwatchClick(windowColor, 'windowColour')}
                    selected={selectedSwatch === 'windowColour'}
                  >
                    <SwatchColorBoxRoom bgcolor={windowColor} />
                    <SwatchNameRoom>Windows</SwatchNameRoom>
                  </SwatchBoxRoom>
                </Box>
              </Box>
            );
          case 'Architrave':
            const architraveColor = selectedRoom.architraveColour?.color || '#FFFFFF';
            return (
              <Box sx={{ mb: 4, p: 0 }}>
                <Box display="grid" gridTemplateColumns="repeat(1, 1fr)" gap={1} mt={2}>
                  <SwatchBoxRoom
                    // onClick={() => handleSwatchClick(architraveColor, 'architraveColour')}
                    selected={selectedSwatch === 'architraveColour'}
                  >
                    <SwatchColorBoxRoom bgcolor={architraveColor} />
                    <SwatchNameRoom>Architrave</SwatchNameRoom>
                  </SwatchBoxRoom>
                </Box>
              </Box>
            );
          case 'Skirting':
            const skirtingColor = selectedRoom.skirtingBoardColour?.color || '#FFFFFF';
            return (
              <Box sx={{ mb: 4, p: 0 }}>
                <Box display="grid" gridTemplateColumns="repeat(1, 1fr)" gap={1} mt={2}>
                  <SwatchBoxRoom
                    // onClick={() => handleSwatchClick(skirtingColor, 'skirtingBoardColour')}
                    selected={selectedSwatch === 'skirtingBoardColour'}
                  >
                    <SwatchColorBoxRoom bgcolor={skirtingColor} />
                    <SwatchNameRoom>Skirting</SwatchNameRoom>
                  </SwatchBoxRoom>
                </Box>
              </Box>
            );
          default:
            return null;
        }
      case 'woodwork':
        return (
          <Box my={2}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '700',
                fontSize: '1.1rem',
                fontFamily: '"Figtree", sans-serif',
                paddingLeft: '8px',
              }}
            >
              Number of windows
            </Typography>
            <InPageToggleButtonGroup
              value={windows}
              exclusive
              onChange={(e, value) => setWindows(value)}
              fullWidth
            >
              <InPageToggleButton value={1}>1</InPageToggleButton>
              <InPageToggleButton value={2}>2</InPageToggleButton>
              <InPageToggleButton value={3}>3</InPageToggleButton>
            </InPageToggleButtonGroup>
            <Typography
              variant="subtitle1"
              sx={{
                mt: 2,
                fontWeight: '700',
                fontSize: '1.1rem',
                fontFamily: '"Figtree", sans-serif',
                paddingLeft: '8px',
              }}
            >
              Number of doors
            </Typography>
            <InPageToggleButtonGroup
              value={doors}
              exclusive
              onChange={(e, value) => setDoors(value)}
              fullWidth
            >
              <InPageToggleButton value={1}>1</InPageToggleButton>
              <InPageToggleButton value={2}>2</InPageToggleButton>
              <InPageToggleButton value={3}>3</InPageToggleButton>
            </InPageToggleButtonGroup>
          </Box>
        );
      case 'config':
        return (
          <Box mt={2} display="grid" gridTemplateColumns="repeat(1, 1fr)" gap={2}>
            {[...Array(5)].map((_, index) => (
              <canvas
                key={index}
                ref={(el) => (canvasRefs.current[index] = el)}
                width={canvasWidth}
                height={Math.round((canvasWidth * 9) / 16)}
                onClick={() => handleCanvasClick(index, 'config')}
                onMouseEnter={(e) => (e.target.style.borderColor = 'lightblue')}
                onMouseLeave={(e) =>
                  (e.target.style.borderColor = selectedCanvas === index ? 'white' : 'black')
                }
                style={{
                  border: '3px solid black',
                  borderRadius: '8px',
                  backgroundColor: 'rgba(128, 128, 128, 0.5)',
                  cursor: 'pointer',
                  boxShadow:
                    selectedCanvas === index ? '0 0 5px 3px rgba(0, 123, 255, 0.8)' : 'none',
                  borderColor: selectedCanvas === index ? 'white' : 'black',
                }}
              />
            ))}
          </Box>
        );
      case 'album':
        return renderThumbnailImages();
      default:
        return null;
    }
  };

  return (
    <Box
      p={0}
      borderRadius="0px"
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0)',
        backdropFilter: 'blur(5px)',
        WebkitBackdropFilter: 'blur(5px)',
      }}
    >
      <Box my={0}>
        <NavToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth >
          <CustomTooltip title="Paint Options"><NavToggleButton value="paint"><FormatPaint /></NavToggleButton></CustomTooltip>
          <CustomTooltip title="Colour Options"><NavToggleButton value="colour"><ColorLens /></NavToggleButton></CustomTooltip>
          <CustomTooltip title="Windows + Doors"><NavToggleButton value="woodwork"><ViewQuilt /></NavToggleButton></CustomTooltip>
          <CustomTooltip title="Room Config"><NavToggleButton value="config"><Construction /> </NavToggleButton></CustomTooltip>
          <CustomTooltip title="Photo Album"><NavToggleButton value="album"><PhotoLibrary /></NavToggleButton></CustomTooltip>
        </NavToggleButtonGroup>
      </Box>
      <Box sx={theme.innerBox}>
        <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)',boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',}}>
          <TextField fullWidth
            label="Room Name"
            value={roomName}
            onChange={handleChange('name')}
            margin="normal"
            InputProps={{ style: inputStyles }}
            InputLabelProps={{ style: { fontSize: '14px' } }} />
          <Typography gutterBottom style={{ fontSize: '12px' }}>
            Dimensions (meters)
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              label="Width"
              type="number"
              value={roomWidth}
              onChange={handleChange('width')}
              margin="normal"
              style={{ width: '45%' }}
              InputProps={{ style: inputStyles, inputProps: { step: 0.1, min: 0 } }}
              InputLabelProps={{ style: { fontSize: '12px' } }}
            />
            <TextField
              label="Length"
              type="number"
              value={roomLength}
              onChange={handleChange('length')}
              margin="normal"
              style={{ width: '45%' }}
              InputProps={{ style: inputStyles, inputProps: { step: 0.1, min: 0 } }}
              InputLabelProps={{ style: { fontSize: '14px' } }}
            />
          </Box>
          <Divider sx={{ marginLeft: 0 }} />
          <Box my={2}>
            <InPageToggleButtonGroup value={style} exclusive onChange={handleStyleChange} fullWidth>
              <CustomTooltip title="Walls"><InPageToggleButton value="allWalls" sx={{ padding: '4px', minWidth: '36px', height: '36px' }}><CheckBoxOutlineBlank fontSize="small" /></InPageToggleButton></CustomTooltip>
              <CustomTooltip title="Ceiling"><InPageToggleButton value="Ceiling" sx={{ padding: '4px', minWidth: '36px', height: '36px' }} ><Light fontSize="small" /></InPageToggleButton></CustomTooltip>
              <CustomTooltip title="Windows"><InPageToggleButton value="Windows" sx={{ padding: '4px', minWidth: '36px', height: '36px' }}><Window fontSize="small" /></InPageToggleButton></CustomTooltip>
              <CustomTooltip title="Doors"><InPageToggleButton value="Doors" sx={{ padding: '4px', minWidth: '36px', height: '36px' }}><DoorFront fontSize="small" /> </InPageToggleButton></CustomTooltip>
              <CustomTooltip title="Architrave"><InPageToggleButton value="Architrave"sx={{ padding: '4px', minWidth: '36px', height: '36px' }}><MeetingRoomOutlined fontSize="small" /></InPageToggleButton></CustomTooltip>
              <CustomTooltip title="Skirting"><InPageToggleButton value="Skirting" sx={{ padding: '4px', minWidth: '36px', height: '36px' }}><PowerInputRounded fontSize="small" /></InPageToggleButton></CustomTooltip>
            </InPageToggleButtonGroup>
          </Box>
          {renderOptionInputs()}
        </Box>
      </Box>
      
    </Box>
  );
};

export default RoomOptions;
