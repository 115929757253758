import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, TextField, Button, Typography, Paper } from '@mui/material';
import palettesData from './login_palettes.json';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const Register = () => {
  const [palette, setPalette] = useState(null);
  const [serverError, setServerError] = useState('');
  const { control, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const randomPalette = palettesData.palettes[Math.floor(Math.random() * palettesData.palettes.length)];
    setPalette(randomPalette);
  }, []);

  const onSubmit = async (data) => {
    setServerError(''); // Clear any previous errors
    try {
      const res = await axios.post(`${API_ENDPOINT}/api/auth/register`, data);
      localStorage.setItem('token', res.data.token);
      document.cookie = `userId=${res.data.userId}; path=/; max-age=${24 * 60 * 60};`; 
      navigate('/dashboard');
    } catch (err) {
      if (err.response && err.response.data.message) {
        setServerError(err.response.data.message);
      } else {
        setServerError('A user with this email already exists');
      }
    }
  };

  if (!palette) return null; // or a loading spinner

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100vh',
        position: 'relative',
        overflow: 'hidden',
        background: `url(/images/register/background_0001.webp) center center / cover no-repeat`,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `linear-gradient(90deg, rgba(255,255,255,0.0) 0%, rgba(255,255,255,0.0) 50%, rgba(255,255,255,0.0) 100%)`,
          backdropFilter: 'blur(0px)',
        }}
      />
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={7.2} sx={{ position: 'relative', zIndex: 1 }}>
          <Box sx={{ 
            position: 'absolute',
            top: '5%',  
            left: '50%',
            transform: 'translateX(-50%)',
            width: '90%',
            padding: '15px',
            bgcolor: 'rgba(255, 255, 255, 0.4)', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
            backdropFilter: 'blur(10px)'
          }}>
            <Typography>Paint Quote Pro</Typography>
          </Box>
          
          <Box sx={{ 
            position: 'absolute',
            bottom: '10%',  
            left: '50%',
            transform: 'translateX(-50%)',
            width: '90%',  
            bgcolor: 'rgba(0, 0, 0, 0.3)',  
            borderRadius: '8px',
            padding: '10px',
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}>
              {[1, 2, 3, 4, 5].map((num) => (
                <Box 
                  key={num}
                  sx={{ 
                    width: 125, 
                    height: 75, 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  <img 
                    src={`/images/logos/logo_${num}.webp`}
                    alt={`Paint Company Logo ${num}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3.6} sx={{ bgcolor: 'transparent', zIndex: 1, backdropFilter: 'blur(10px)' }}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Paper elevation={0} sx={{ p: 4, width: '60%', maxWidth: 300, bgcolor: 'white' }}>
              <Typography variant="h5" component="h1" gutterBottom>
                Register
              </Typography>
              {serverError && (
                <Typography variant="body2" color="error" gutterBottom>
                  {serverError}
                </Typography>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name"
                      fullWidth
                      margin="normal"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Email is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      fullWidth
                      margin="normal"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Password is required', minLength: { value: 6, message: 'Password must be at least 6 characters long' } }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password"
                      type="password"
                      fullWidth
                      margin="normal"
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  )}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ 
                    mt: 3, 
                    bgcolor: palette.accent, 
                    '&:hover': { 
                      bgcolor: palette.accent,
                      filter: 'brightness(90%)'
                    } 
                  }}
                >
                  Register
                </Button>
              </form>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={1.2} sx={{ bgcolor: 'transparent', zIndex: 1, backdropFilter: 'blur(0px)' }} />
      </Grid>
    </Box>
  );
};

export default Register;
