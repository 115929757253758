import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, CircularProgress, Tabs, Tab, Paper } from '@mui/material';
import { PictureAsPdf, Email } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import './Inspection.css';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`room-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const styles = {
  container: {
    padding: '32px',
    fontSize: '12px',
    margin: '32px',
  },
  logo: {
    width: '160px',
    height: 'auto',
  },
  invoiceTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  invoiceHeader: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  reportContent: {
    width: '90%',
    marginLeft: '12px',
    marginBottom: '2px',
    fontSize: '12px',
  },
  sectionTitle: {
    marginTop: '12px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  imagePlaceholder: {
    width: '100%',
    height: '250px',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    borderRadius: '16px 16px 0 0',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    color: '#999',
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: 'divider',
    marginBottom: '20px',
    backgroundColor: '#fff',
  },
  tab: {
    textTransform: 'none',
    minHeight: 48,
    fontWeight: 'normal',
    '&.Mui-selected': {
      fontWeight: 'bold',
    },
  },
};

const Inspection = ({
  logo,
  setLogo,
  companyName,
  clientFullName,
  clientEmail,
  clientPhone,
  clientStreetAddress,
  clientCity,
  clientState,
  clientPostalCode,
  clientCountry,
  numInvoices,
  activeTab = 0,
  setActiveTab = () => {},
}) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [rooms, setRooms] = useState([]);
  const [clientDetails, setClientDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [pdfProgress, setPdfProgress] = useState(0);
  const [inspectionReports, setInspectionReports] = useState({});
  const [reportImages, setReportImages] = useState({});

  useEffect(() => {
    const appData = JSON.parse(localStorage.getItem('appData'));
    const appState = JSON.parse(localStorage.getItem('appState'));
    const currentJobID = appState?.currentJobID;

    if (appData?.jobs) {
      const currentJob = appData.jobs.find(job => job._id === currentJobID);

      if (currentJob?.ClientID) {
        const client = appData.clients.find(client => client._id === currentJob.ClientID._id);
        setClientDetails(client);
      }

      if (currentJob?.FloorPlanID?.Floors) {
        const roomsList = currentJob.FloorPlanID.Floors.flatMap(floor => floor.rooms || []).map(room => ({
          id: room._id,
          name: room.name || 'Unnamed Room',
        })).filter(room => room.id && room.name);
        setRooms(roomsList);
      }

      const reportsByRoomId = currentJob.inspectionReports || {};
      setInspectionReports(reportsByRoomId);
      // Set images if available in reports
      const images = {};
      Object.keys(reportsByRoomId).forEach(roomId => {
        images[roomId] = reportsByRoomId[roomId]?.photoUrls || [];
      });
      setReportImages(images);
    }

    if (logo) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = logo;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        setLogo(canvas.toDataURL('image/png'));
      };
    }
  }, [logo, setLogo]);

  useEffect(() => {
    const handleInspectionReportUpdate = () => {
      const appData = JSON.parse(localStorage.getItem('appData'));
      const appState = JSON.parse(localStorage.getItem('appState'));
      const currentJobID = appState?.currentJobID;
  
      if (appData?.jobs) {
        const currentJob = appData.jobs.find(job => job._id === currentJobID);
        const reportsByRoomId = currentJob?.inspectionReports || {};
        setInspectionReports(reportsByRoomId);
        
        const updatedImages = {};
        Object.keys(reportsByRoomId).forEach(roomId => {
          updatedImages[roomId] = reportsByRoomId[roomId]?.photoUrls || [];
        });
        setReportImages(updatedImages);
      }
    };
  
    window.addEventListener("inspectionReportUpdate", handleInspectionReportUpdate);
    
    return () => {
      window.removeEventListener("inspectionReportUpdate", handleInspectionReportUpdate);
    };
  }, []);
  

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const hasReports = () => {
    return rooms.some(room => inspectionReports[room.id]);
  };

  const generatePDF = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const originalActiveTab = activeTab;
    setLoading(true);
    let currentPage = 0;

    try {
      for (let i = 0; i < rooms.length; i++) {
        const room = rooms[i];
        const report = inspectionReports[room.id];
        if (!report) continue;

        currentPage++;
        setPdfProgress(currentPage);
        setActiveTab(i);

        await new Promise(resolve => setTimeout(resolve, 500));
        const reportElement = document.getElementById(`report-container-${room.id}`);
        
        if (!reportElement) {
          console.warn(`Report element not found for room: ${room.name}`);
          continue;
        }

        const canvas = await html2canvas(reportElement, {
          useCORS: true,
          allowTaint: true,
          scale: 3,
          logging: false,
          width: reportElement.offsetWidth,
          height: reportElement.offsetHeight,
        });

        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        if (i > 0) pdf.addPage();

        pdf.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 0, 0, imgWidth, imgHeight);
      }

      return pdf;
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    } finally {
      setActiveTab(originalActiveTab);
      setLoading(false);
      setPdfProgress(0);
    }
  };

  const downloadPDF = async () => {
    if (!hasReports()) return;

    try {
      const pdf = await generatePDF();
      pdf.save('inspection_report.pdf');
    } catch (error) {
      console.error('Error downloading PDF:', error);
      alert('Error generating PDF. Please try again.');
    }
  };

  const handleEmailPDF = async () => {
    if (!hasReports()) return;

    try {
      setLoading(true);
      setPdfProgress(0);

      const pdf = await generatePDF();
      const pdfBlob = pdf.output('blob');

      const formData = new FormData();
      formData.append('pdf', pdfBlob, 'complete_inspection_report.pdf');
      formData.append('clientEmail', clientDetails.clientEmail);
      formData.append('clientName', clientDetails.clientFullName);

      const response = await axios.post(`${API_ENDPOINT}/api/send-inspection-report`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.success) {
        alert('Email sent successfully!');
      } else {
        alert(`Failed to send email: ${response.data.error}`);
      }
    } catch (err) {
      console.error('Error sending email:', err);
      alert('An error occurred while sending the email.');
    } finally {
      setLoading(false);
      setPdfProgress(0);
    }
  };

  const placeholderReport = {
    detailed_inspection_report: {
      room_overview: { type: 'N/A', condition: 'N/A' },
      walls: { condition: 'N/A', paint_finish: 'N/A', preparation_required: 'N/A' },
      ceiling: { condition: 'N/A', ceiling_features: 'N/A', preparation_required: 'N/A' },
      woodwork_and_trim: { condition: 'N/A', preparation_required: 'N/A' },
      windows_and_doors: { condition: 'N/A', preparation_required: 'N/A' },
      flooring: { condition: 'N/A', preparation_required: 'N/A' },
      furniture_and_obstructions: { description: 'N/A' },
      special_considerations: { notes: 'N/A' },
      summary: 'N/A',
    },
  };

  return (
    <Box sx={styles.container}>
      <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
        <IconButton onClick={downloadPDF} sx={{ fontSize: 30 }} disabled={loading || !hasReports()}>
          <PictureAsPdf fontSize="inherit" />
        </IconButton>
        <IconButton onClick={handleEmailPDF} sx={{ fontSize: 30 }} disabled={loading || !hasReports()}>
          {loading ? <CircularProgress size={24} /> : <Email fontSize="inherit" />}
        </IconButton>
        {loading && (
          <Box ml={2} display="flex" alignItems="center">
            <CircularProgress size={20} />
            <Typography variant="body2" ml={1}>
              {pdfProgress === 0
                ? 'Preparing PDF...'
                : `Processing page ${pdfProgress} of ${rooms.filter(room => inspectionReports[room.id]).length}`}
            </Typography>
          </Box>
        )}
      </Box>

      <Paper sx={styles.tabsContainer}>
        <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" aria-label="room tabs">
          {rooms.map((room, index) => (
            <Tab key={room.id} label={room.name} id={`room-tab-${index}`} sx={styles.tab} />
          ))}
        </Tabs>
      </Paper>

      {rooms.map((room, index) => {
        const reportData = inspectionReports[room.id] || placeholderReport;
        const report = reportData.detailed_inspection_report || placeholderReport;
        const isCurrentTab = index === activeTab;
        const imageUrls = reportImages[room.id];

        return (
          <TabPanel
            key={room.id}
            value={activeTab}
            index={index}
            className={isCurrentTab ? undefined : "hidden"}
          >

            <Box id={`report-container-${room.id}`}>
              <Box className="report">
                <Box className="header" display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Box className="logo" ml={1}>
                      {logo ? (
                        <img
                          src={logo}
                          alt="Company Logo"
                          style={{ maxWidth: '200px', height: 'auto' }}
                        />
                      ) : (
                        <Typography variant="h5" className="quote-title">
                          {companyName}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box className="invoice-details" textAlign="right" sx={{ marginRight: '24px' }}>
                    <Typography style={styles.invoiceHeader}>INSPECTION REPORT</Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>{companyName}</Typography>
                    <Typography>Date: {new Date().toLocaleDateString()}</Typography>
                    <Typography>Room: {room.name}</Typography>
                  </Box>


                </Box>

                <Box sx={styles.imagePlaceholder}>
                  {imageUrls && imageUrls.length > 0 ? (
                    <img src={imageUrls[0]} alt="Uploaded Room" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  ) : (
                    <Typography>No Image Available</Typography>
                  )}
                </Box>

                <Box component="div" className="report-content" sx={styles.reportContent}>
                  <Typography variant="h6" style={styles.sectionTitle}>Summary</Typography>
                  <Typography style={styles.reportContent}>{report?.summary}</Typography>

                  <Typography style={styles.sectionTitle}>Room Overview</Typography>
                  <Typography style={styles.reportContent}>
                    {report?.room_overview?.type} {report?.room_overview?.condition}
                  </Typography>
                  
                  <Typography style={styles.sectionTitle}>Walls</Typography>
                  <Typography style={styles.reportContent}>
                    {report?.walls?.condition} {report?.walls?.paint_finish}
                  </Typography>
                  <Typography style={styles.reportContent}>
                    {report?.walls?.preparation_required}
                  </Typography>

                  <Typography style={styles.sectionTitle}>Ceiling</Typography>
                  <Typography style={styles.reportContent}>
                    {report?.ceiling?.condition} {report?.ceiling?.ceiling_features}
                  </Typography>
                  <Typography style={styles.reportContent}>
                    {report?.ceiling?.preparation_required}
                  </Typography>

                  <Typography style={styles.sectionTitle}>Woodwork and Trim</Typography>
                  <Typography style={styles.reportContent}>
                    {report?.woodwork_and_trim?.condition} {report?.woodwork_and_trim?.preparation_required}
                  </Typography>
                  
                  <Typography style={styles.sectionTitle}>Windows and Doors</Typography>
                  <Typography style={styles.reportContent}>
                    {report?.windows_and_doors?.condition} {report?.windows_and_doors?.preparation_required}
                  </Typography>
                  
                  <Typography style={styles.sectionTitle}>Flooring</Typography>
                  <Typography style={styles.reportContent}>
                    {report?.flooring?.condition}
                  </Typography>
                  <Typography style={styles.reportContent}>
                    {report?.flooring?.preparation_required}
                  </Typography>

                  <Typography style={styles.sectionTitle}>Furniture and Obstructions</Typography>
                  <Typography style={styles.reportContent}>
                    {report?.furniture_and_obstructions?.description}
                  </Typography>

                  <Typography style={styles.sectionTitle}>Special Considerations</Typography>
                  <Typography style={styles.reportContent}>
                    {report?.special_considerations?.notes}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default Inspection;
