import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import { Info, MonetizationOn, AccountBalance, Share } from '@mui/icons-material';
import { NavToggleButtonGroup, NavToggleButton, InPageToggleButtonGroup, InPageToggleButton } from './CustomToggleButton'


import CustomTooltip from './components/CustomTooltip';

import AccountInfo from './components/Account/AccountInfo';
import AccountBanking from './components/Account/AccountBanking';
import AccountInvoicing from './components/Account/AccountInvoicing';
import AccountSocial from './components/Account/AccountSocial';


import useFetchAndCacheData from './utils/useFetchAndCacheData';
import theme from './theme';

const DEFAULT_LOGO = '/images/avatars/painter.webp';

/**
 * AccountPanel component manages and displays account information, banking details, invoicing, and social media settings.
 * 
 * This component allows users to view and update their account details, including company information, banking options,
 * invoicing preferences, and social media links. It provides a toggle interface for switching between different sections
 * of the account settings.
 * 
 * @returns {JSX.Element} The rendered AccountPanel component.
 */
const AccountPanel = () => {
  const [accountState, setAccountState] = useState({
    ownerFirstName: 'First Name',
    ownerSurname: 'Surname',
    companyName: '',
    companyNumber: '',
    vatNumber: '',
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    logo: DEFAULT_LOGO,
    email: '',
    phoneNumber: '',
    whatsApp: '',
    website: '',
    socialMedia: {
      facebook: '',
      instagram: '',
      twitter: '',
      pinterest: '',
    },
    primaryContactName: '',
    primaryContactPosition: '',
    secondaryContactName: '',
    secondaryContactPhone: '',
    industryType: '',
    businessType: '',
    preferredContactMethod: '',
    paymentTerms: '',
    invoiceNotes: '',
    invoiceFormat: 'INV-YYYY-MM-DD-XXXX',
    invoiceStartNum: 0,
    invoiceTemplate: null,
    latePaymentFees: 10,
    selectedInvoiceCurrency: 'GBP',
    invoiceDiscounts: 2,
    customerReferencePO: 'Optional',
    invoicePaymentInstructions: 'Default Value',
    creditLimit: 0,
    isActive: true,
    tags: [],
    bankingOptions: {
    selectedBank: '',
    sortCode: '00-00-00',
    accountNumber: '12345678',
    },  
  });

  const [selectedOption, setSelectedOption] = useState('info');
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [newLogoFile, setNewLogoFile] = useState(null);
  const [selectedBank, setSelectedBank] = useState('');
  const [selectedInvoiceFormat, setSelectedInvoiceFormat] = useState(accountState.invoiceFormat);
  const [selectedInvoiceCurrency, setSelectedInvoiceCurrency] = useState('');
  const [selectedTaxRate, setSelectedTaxRate] = useState('');

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  


  useEffect(() => {
    const storedAppData = localStorage.getItem('appData');
    if (storedAppData) {
      const { account = {} } = JSON.parse(storedAppData);
      setAccountState((prevState) => ({
        ...prevState,
        ...account,
        socialMedia: { ...prevState.socialMedia, ...account.socialMedia },
        logo: account.logo || DEFAULT_LOGO,
        bankingOptions: { ...prevState.bankingOptions, ...account.bankingOptions },
      }));
      
    }
  }, []);
  
  

  /**
   * Updates the account state for a specific field.
   * 
   * @param {string} field - The field in the account state to update.
   * @param {any} value - The new value for the specified field.
   * @param {boolean} [nested=false] - Indicates if the update is for a nested field.
   */
  const updateState = useCallback((field, value, nested = false) => {
    setAccountState((prevState) => nested ? {
      ...prevState,
      [field]: { ...prevState[field], ...value },
    } : {
      ...prevState,
      [field]: value,
    });
  }, []);

  /**
   * Handles input changes for account fields.
   * 
   * @param {string} field - The field to update.
   * @param {any} value - The new value for the field.
   * @param {boolean} [nested=false] - Indicates if the field is nested.
   */
  const handleInputChange = useCallback((field, value, nested = false) => {
    updateState(field, value, nested);
  }, [updateState]);

  /**
   * Handles changes to social media fields.
   * 
   * @param {string} platform - The social media platform to update.
   * @param {string} value - The new value for the social media platform.
   */
  const handleSocialMediaChange = useCallback((platform, value) => {
    updateState('socialMedia', { [platform]: value }, true);
  }, [updateState]);

  /**
   * Handles form submission to update account information.
   * 
   * @param {React.FormEvent} event - The form submission event.
   */
  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    const userId = document.cookie.split('; ').find(row => row.startsWith('userId=')).split('=')[1];

    if (!accountState.companyName || !userId) {
      alert('Company Name and User ID are required');
      return;
    }

    try {
      setIsUpdating(true);
      let updatedLogo = accountState.logo;

      if (newLogoFile) {
        const formData = new FormData();
        formData.append('logo', newLogoFile);
        formData.append('userId', userId);

        const logoResponse = await axios.post(`${API_ENDPOINT}/api/uploadlogo`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        updatedLogo = logoResponse.data.logoUrl;
      }

      const updatedAccountData = { ...accountState, logo: updatedLogo };

      const response = await axios.post(`${API_ENDPOINT}/api/accounts`, { userId, ...updatedAccountData });

      const storedAppData = JSON.parse(localStorage.getItem('appData')) || {};
      storedAppData.account = response.data; 
      localStorage.setItem('appData', JSON.stringify(storedAppData)); 

      setIsUpdating(false);
      setUpdateSuccess(true);
      setTimeout(() => setUpdateSuccess(false), 3000);
    } catch (error) {
      console.error('Error updating account:', error);
      setIsUpdating(false);
    }
  }, [accountState, newLogoFile]);

  /**
   * Handles image upload for the account logo.
   * 
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event from the file input.
   */
  const handleImageUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      setNewLogoFile(file);
      setAccountState((prevState) => ({
        ...prevState,
        logo: URL.createObjectURL(file),
      }));
    }
  }, []);

  const { data: banksData, loading: loadingBanks, error: errorBanks } = useFetchAndCacheData('/json/data/banks.json', 'banks');
  
  const { data: invoiceFormatData, loading: loadingInvoiceFormat, error: errorInvoiceFormat } = useFetchAndCacheData('/json/data/invoiceFormat.json', 'invoiceFormat');
  
  const { data: currencyData = { invoiceCurrency: [] }, loading: loadingCurrencyData, error: errorCurrencyData } = useFetchAndCacheData('/json/data/currencies.json', 'invoiceCurrency');
  const { data: taxCodeData = { taxCodes: [] }, loading: loadingTaxCodeData, error: errorTaxCodeData } = useFetchAndCacheData('/json/data/taxCodes.json', 'taxCodes');


  const loading = loadingBanks || loadingInvoiceFormat || loadingCurrencyData || loadingTaxCodeData;
  const error = errorBanks || errorInvoiceFormat || errorCurrencyData || errorTaxCodeData;
  

  const banks = banksData?.banks || [];
  const invoiceFormat = invoiceFormatData?.invoiceFormat || [];
  const invoiceCurrency = currencyData?.currencies || [];
  const taxCodes = taxCodeData?.taxRates || [];


 
  /**
   * Renders the input fields based on the selected option.
   * 
   * @returns {JSX.Element|null} The rendered input fields for the selected option.
   */
  const renderOptionInputs = useCallback(() => {
    const commonProps = {
      accountState,
      handleInputChange,
      handleImageUpload,
      handleSubmit,
      updateSuccess,
      isUpdating,
      newLogoFile,
      setNewLogoFile,
    };

    switch (selectedOption) {
      case 'info':
        return <AccountInfo {...commonProps} />;
      case 'banking':
        return <AccountBanking {...commonProps} banks={banks} loading={loading} error={error} selectedBank={selectedBank} setSelectedBank={setSelectedBank} />;
      case 'invoicing':
        return <AccountInvoicing {...commonProps} 
        //currency
        invoiceCurrency={invoiceCurrency} 
        selectedInvoiceCurrency={selectedInvoiceCurrency}
        setSelectedInvoiceCurrency={setSelectedInvoiceCurrency}
        //Invoice Format
        invoiceFormat={invoiceFormat} 
        selectedInvoiceFormat={selectedInvoiceFormat}
        setSelectedInvoiceFormat={setSelectedInvoiceFormat}
        //Tax Codes
        taxCodes={taxCodes}
        selectedTaxRate={selectedTaxRate}
        setSelectedTaxRate={setSelectedTaxRate}

        loading={loading} 
        error={error} 
        
         />;
      case 'social':
        return <AccountSocial {...commonProps} handleSocialMediaChange={handleSocialMediaChange} />;
      default:
        return null;
    }
  }, [selectedOption, accountState, handleInputChange, handleSocialMediaChange, handleImageUpload, handleSubmit, updateSuccess, isUpdating, banks, loading, error, selectedBank, newLogoFile]);

  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
      <Box my={0}>
        <NavToggleButtonGroup value={selectedOption} exclusive onChange={(e, newOption) => setSelectedOption(newOption)} fullWidth>
          <CustomTooltip title="Account details"><NavToggleButton value="info"><Info /></NavToggleButton></CustomTooltip>
          <CustomTooltip title="Banking and Payments"><NavToggleButton value="banking"><AccountBalance /></NavToggleButton></CustomTooltip>
          <CustomTooltip title="Invoicing"><NavToggleButton value="invoicing"><MonetizationOn /></NavToggleButton></CustomTooltip>
          <CustomTooltip title="Social Media"><NavToggleButton value="social"><Share /></NavToggleButton></CustomTooltip>
        </NavToggleButtonGroup>
      </Box>
      {renderOptionInputs()}
    </Box>
  );
};

export default AccountPanel;
