import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, InputAdornment, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { Home, LocationCity, LocationOn, Place, Public, Add, Check, Delete } from '@mui/icons-material';
import { AddressAutofill } from '@mapbox/search-js-react';
import axios from 'axios';
import {  InPageToggleButtonGroup, InPageToggleButton } from '../../CustomToggleButton'
import theme from '../../theme';

const NewJobForm = ({ jobData, isUpdating, onJobCreated, onJobUpdated, onDeleteJob, userId, setCurrentFloorplanId, setCurrentFloorId, setFloorNames }) => {
  const INITIAL_JOB_STATE = {
    id: null,
    jobName: '',
    selectedClient: '',
    numFloors: 1,
    style: '',
    coverage: 0,
    metersSquared: 0,
    roomHeight: 0,
    wallCoats: 1,
    roomPrep: 'Low',
    color: '',
    jobStreetAddressOne: '',
    jobStreetAddressTwo: '',
    jobCity: '',
    jobState: '',
    jobPostalCode: '',
    jobCountry: '',
    paintWalls: true,
    paintCeiling: false,
    paintSkirtingArchitrave: false,
    paintDoors: false,
    paintWindows: false,
    undercoatPrimer: false,
  };

  const [jobState, setJobState] = useState(INITIAL_JOB_STATE);
  const [clients, setClients] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  useEffect(() => {
    const loadClients = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/clients`, { params: { userId } });
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    if (isUpdating && jobData) {
      setJobState({
        id: jobData.id || jobData._id,
        jobName: jobData.JobDetails?.JobName || '',
        selectedClient: jobData.ClientID?._id || '',
        numFloors: jobData.NumFloors?.toString() || "1",
        style: jobData.JobDetails?.Style || '',
        coverage: jobData.JobDetails?.Coverage || 0,
        metersSquared: jobData.JobDetails?.MetersSquared || 0,
        roomHeight: jobData.JobDetails?.RoomHeight || 0,
        wallCoats: jobData.JobDetails?.WallCoats || 1,
        roomPrep: jobData.JobDetails?.RoomPrep || 'Low',
        color: jobData.JobDetails?.Color || '',
        jobStreetAddressOne: jobData.jobStreetAddressOne || '',
        jobStreetAddressTwo: jobData.jobStreetAddressTwo || '',
        jobCity: jobData.jobCity || '',
        jobState: jobData.jobState || '',
        jobPostalCode: jobData.jobPostalCode || '',
        jobCountry: jobData.jobCountry || '',
        paintWalls: jobData.paintWalls || true,
        paintCeiling: jobData.paintCeiling || false,
        paintSkirtingArchitrave: jobData.paintSkirtingArchitrave || false,
        paintDoors:  jobData.paintDoors || false,
        paintWindows: jobData.paintWindows || false,
        undercoatPrimer: jobData.paintWindows || false,
      });
    }

    loadClients();
    setDeleteConfirmation(false);
  }, [isUpdating, jobData, userId]);

  const handleInputChange = (field, value) => {
    setJobState((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Prevent double submission
    if (isSubmitting) return;
  
    setIsSubmitting(true);
    setSubmitSuccess(false);
  
    try {
      const jobData = {
        userId: userId,
        JobDetails: {
          JobName: jobState.jobName,
          Style: jobState.style,
          Coverage: jobState.coverage,
          MetersSquared: jobState.metersSquared,
          RoomHeight: jobState.roomHeight,
          WallCoats: jobState.wallCoats,
          RoomPrep: jobState.roomPrep,
          Color: jobState.color,
        },
        ClientID: jobState.selectedClient,
        NumFloors: parseInt(jobState.numFloors, 10),
        jobStreetAddressOne: jobState.jobStreetAddressOne,
        jobStreetAddressTwo: jobState.jobStreetAddressTwo,
        jobCity: jobState.jobCity,
        jobState: jobState.jobState,
        jobPostalCode: jobState.jobPostalCode,
        jobCountry: jobState.jobCountry,
        paintWalls: jobState.paintWalls,
        paintCeiling: jobState.paintCeiling,
        paintSkirtingArchitrave: jobState.paintSkirtingArchitrave,
        paintDoors:  jobState.paintDoors,
        paintWindows: jobState.paintWindows,
        undercoatPrimer: jobState.undercoatPrimer,
      };
  
      let result;
  
      if (jobState.id) {
        // Update existing job using onJobUpdated function passed from JobOptionsV2
        result = await onJobUpdated(jobState.id, jobData);
      } else {
        // Create new job using onJobCreated function passed from JobOptionsV2
        result = await onJobCreated(jobData);
      }
  
      // Handle floorplan if present
      if (result.FloorPlanID) {
        setCurrentFloorplanId(result.FloorPlanID._id);
  
        if (result.FloorPlanID.Floors && result.FloorPlanID.Floors.length > 0) {
          setCurrentFloorId(result.FloorPlanID.Floors[0]._id);
          setFloorNames(result.FloorPlanID.Floors.map(floor => floor.Name));
        }
      }
  
      setSubmitSuccess(true);
      setTimeout(() => setSubmitSuccess(false), 3000);
  
    } catch (error) {
      console.error('Error submitting job:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Error occurred while submitting the job.';
      alert(`Error: ${errorMessage}`);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  

  const handleDeleteClick = async () => {
    if (!deleteConfirmation) {
      setDeleteConfirmation(true);
      setTimeout(() => setDeleteConfirmation(false), 3000);
    } else {
      try {
        await axios.delete(`${API_ENDPOINT}/api/jobs/${jobState.id}`);
        onDeleteJob(jobState.id);
      } catch (error) {
        console.error('Error deleting job:', error);
        alert(`Error: ${error.response?.data?.message || error.message}`);
      }
    }
  };

  return (
    <Box sx={theme.innerBox}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 3, border: '1px solid #fff', borderRadius: '8px', p: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <Typography variant="subtitle1" gutterBottom sx={theme.customToggleButton}>Job Details</Typography>
          <TextField
            label="Job Name"
            name="jobName"
            value={jobState.jobName}
            onChange={(e) => handleInputChange('jobName', e.target.value)}
            fullWidth
            required
            variant="outlined"
            sx={{
              ...theme.inputStyle,
              '.MuiOutlinedInput-root': {
                padding: '8px', 
                fontSize: '1.25rem', 
                minHeight: '50px', 
                borderRadius: '12px', 
                '&.Mui-focused': {
                  backgroundColor: 'white', 
                  fontWeight: 'bold', 
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#1565c0', 
                },
              },
              '.MuiInputLabel-root': {
                fontSize: '1rem', 
              }
            }}
          />

          <FormControl fullWidth variant="outlined"
          sx={{
            ...theme.inputStyle,
            '.MuiOutlinedInput-root': {
              padding: '8px', 
              fontSize: '1.25rem', 
              minHeight: '50px', 
              borderRadius: '12px', 
              '&.Mui-focused': {
                backgroundColor: 'white', 
                fontWeight: 'bold', 
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1565c0', 
              },
            },
            '.MuiInputLabel-root': {
              fontSize: '1rem', 
            }
          }}>
            <InputLabel id="client-select-label">Client</InputLabel>
            <Select
              labelId="client-select-label"
              id="client-select"
              name="selectedClient"
              value={jobState.selectedClient}
              label="Client"
              onChange={(e) => handleInputChange('selectedClient', e.target.value)}
              required
            >
              {clients.map((client) => (
                <MenuItem key={client._id} value={client._id}>
                  {client.clientFullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="subtitle1" gutterBottom sx={theme.customToggleButton}>Number of floors</Typography>
          <InPageToggleButtonGroup
            value={jobState.numFloors}
            exclusive
            onChange={(e, val) => handleInputChange('numFloors', val)}
            fullWidth
          >
            {[1, 2, 3, 4, 5].map((num) => (
              <InPageToggleButton key={num} value={num.toString()} sx={theme.customToggleButton}>
                {num}
              </InPageToggleButton>
            ))}
          </InPageToggleButtonGroup>

          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 'bold',
              fontSize: '1.2rem',
              fontFamily: '"Figtree", sans-serif',
              mt: 2
            }}
            gutterBottom
          >
            Address Details
          </Typography>

          <AddressAutofill
            accessToken={MAPBOX_ACCESS_TOKEN}
            onRetrieve={(event) => {
              const feature = event.features?.[0];
              if (feature) {
                const {properties} = feature;
                const context = feature.context || [];

                const addressNumber = properties.address_number || '';
                const street = properties.street || feature.text_en || '';
                const postcode = properties.postcode || context.find((c) => c.id.includes('postcode'))?.text || '';
                const place = properties.place || context.find((c) => c.id.includes('place'))?.text || '';
                const region = properties.region || context.find((c) => c.id.includes('region'))?.text || '';
                const country = properties.country || context.find((c) => c.id.includes('country'))?.text || '';

                handleInputChange('jobStreetAddressOne', `${addressNumber} ${street}`.trim());
                handleInputChange('jobCity', place);
                handleInputChange('jobState', region);
                handleInputChange('jobPostalCode', postcode);
                handleInputChange('jobCountry', country);
              }
            }}
          >
            <TextField
              label="Street Address 1"
              name="jobStreetAddressOne"
              value={jobState.jobStreetAddressOne}
              onChange={(e) => handleInputChange('jobStreetAddressOne', e.target.value)}
              fullWidth
              sx={theme.inputStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Home />
                  </InputAdornment>
                ),
              }}
            />
          </AddressAutofill>

          {[
            { name: 'jobStreetAddressTwo', label: 'Street Address 2', icon: <Home /> },
            { name: 'jobCity', label: 'City', icon: <LocationCity /> },
            { name: 'jobState', label: 'State/Province', icon: <LocationOn /> },
            { name: 'jobPostalCode', label: 'Postal/ZIP Code', icon: <Place /> },
            { name: 'jobCountry', label: 'Country', icon: <Public /> },
          ].map((field) => (
            <TextField
              key={field.name}
              label={field.label}
              name={field.name}
              value={jobState[field.name]}
              onChange={(e) => handleInputChange(field.name, e.target.value)}
              fullWidth
              sx={theme.inputStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {field.icon}
                  </InputAdornment>
                ),
              }}
            />
          ))}

          <Button
            type="submit"
            variant="contained"
            color={submitSuccess ? "success" : "primary"}
            fullWidth
            sx={{
              mt: 2,
              ...(submitSuccess && theme.successButton),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={24} />
            ) : submitSuccess ? (
              <>
                {isUpdating ? 'Job Updated' : 'Job Created'}
                <Check style={{ marginLeft: '8px' }} />
              </>
            ) : (
              <>
                {isUpdating ? 'Update Job' : 'Create New Job'}
                {/* <Add style={{ marginLeft: '8px' }} /> */}
              </>
            )}
          </Button>

          {isUpdating && (
            <Button
              variant="contained"
              color={deleteConfirmation ? "warning" : "error"}
              onClick={handleDeleteClick}
              fullWidth
              sx={{ mt: 2 }}
            >
              {deleteConfirmation ? "ARE YOU SURE?" : "Delete Job"}
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
}

export default NewJobForm;