//AccountBanking.js
import React, { useEffect } from 'react';
import {Avatar,TextField, Box, Typography, InputAdornment, Button, Select, MenuItem, FormControl, InputLabel} from '@mui/material';
import { Business, ConfirmationNumber, Check } from '@mui/icons-material';
import theme from '../../theme';

const AccountBanking = ({
  accountState,
  handleInputChange,
  handleSubmit,
  updateSuccess,
  isUpdating,
  handleImageUpload,
  loading,
  error,
  banks,
  selectedBank,
  setSelectedBank,
}) => {

  useEffect(() => {
    if (banks.length > 0 && !selectedBank && accountState.bankingOptions.selectedBank) {
      setSelectedBank(accountState.bankingOptions.selectedBank);
    }
  }, [banks, selectedBank, accountState.bankingOptions.selectedBank, setSelectedBank]);

  
  return (
    <Box sx={theme.customFormBox}>
      <Box sx={theme.customLogoBox} onClick={() => document.getElementById('company-logo-input').click()}>
        <Box sx={theme.customLogoGreyBox} />
        <Avatar
          sx={theme.customLogoAvatar}
          src={accountState.logo || '/images/avatars/painter.webp'}
          onClick={(e) => {
            e.stopPropagation();
            document.getElementById('company-logo-input').click();
          }}
        />
      </Box>

      <input
        id="company-logo-input"
        type="file"
        hidden
        onChange={handleImageUpload}
      />

      <Box sx={{ ...theme.customFormBox }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            fontFamily: '"Figtree", sans-serif',
          }}
          gutterBottom
        >
          Banking Details
        </Typography>
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel>Select Bank</InputLabel>
          <Select
            value={selectedBank || ''}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedBank(value);
              handleInputChange('bankingOptions', { selectedBank: value }, true);
            }}
            label="Select Bank"
          >
            {loading ? (
              <MenuItem disabled>Loading banks...</MenuItem>
            ) : error ? (
              <MenuItem disabled>Error loading banks</MenuItem>
            ) : banks.length > 0 ? (
              banks.map((bank) => (
                <MenuItem key={bank.id} value={bank.name}>
                  {bank.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No banks available</MenuItem>
            )}
          </Select>
        </FormControl>
        <TextField
          label="Sort Code/Routing Code"
          value={accountState.bankingOptions.sortCode}
          onChange={(e) => handleInputChange('bankingOptions', { sortCode: e.target.value }, true)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Business />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Account Number"
          value={accountState.bankingOptions.accountNumber}
          onChange={(e) => handleInputChange('bankingOptions', { accountNumber: e.target.value }, true)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ConfirmationNumber />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color={updateSuccess ? 'success' : 'primary'}
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
          disabled={isUpdating}
        >
          {isUpdating
            ? 'Updating...'
            : updateSuccess
            ? 'Account Updated'
            : 'Update Details'}
          {updateSuccess && <Check style={{ marginLeft: '4px' }} />}
        </Button>
      </Box>
    </Box>
  );
};

export default AccountBanking;
