import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './Mapping.css';

const MapPanel = () => {
  const [steps, setSteps] = useState([]);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const fetchDirections = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}/api/directions?start=OX25%205QH&end=OX2%209RH`);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data.routes || data.routes.length === 0) {
          console.error('No routes found:', data);
          return;
        }

        // Extract step-by-step directions from the route
        const steps = data.routes[0].legs[0].steps.map(step => step.maneuver.instruction);
        setSteps(steps);
      } catch (error) {
        console.error('Failed to fetch directions:', error);
      }
    };

    fetchDirections();
  }, [API_ENDPOINT]);

  return (
    <Box p={2} border="0px solid #fff" borderRadius="8px" boxShadow={0} style={{
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)', // For Safari support
    }}>
      <Typography variant="h6" gutterBottom>
        Step-by-Step Directions
      </Typography>
      <Box className="directions-container">
        {steps.map((instruction, index) => (
          <Box key={index} className={`direction-step ${index % 2 === 0 ? 'even-step' : 'odd-step'}`}>
            <LocationOnIcon className="step-icon" />
            <Box className="step-details">
              <Typography className="step-instruction" dangerouslySetInnerHTML={{ __html: instruction }}></Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MapPanel;
