import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import {Cancel, SaveAlt, Save, AutoAwesome, MoreVert} from '@mui/icons-material';
import { ModifyButton, EnhanceButton,CloseButton, SaveButton, UploadButton, DownloadButton, ImageContainer, Canvas, PositionedActionButton } from '../Buttons';
import '../../App.css';

const CanvasWithPrompt = ({ selectedRoomId, initialImageUrl = '', style, defaultWidth = 960, defaultHeight = 540, prompt: initialPrompt, setShowCanvas, selectedImageUrl }) => {
  // console.log('CanvasWithPrompt component rendered with selectedImageUrl:', selectedImageUrl);
  const canvasRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(initialImageUrl || selectedImageUrl);
  const [isPromptVisible, setIsPromptVisible] = useState(true); 
  const [prompt, setPrompt] = useState(initialPrompt || ''); 
  const [currentType, setCurrentType] = useState('fast_image'); 
  const [isLoading, setIsLoading] = useState(false); 
  const [areButtonsVisible, setAreButtonsVisible] = useState(true); 
  const [isCloseHovered, setIsCloseHovered] = useState(false); 
  const [isSaveHovered, setIsSaveHovered] = useState(false);   
  const [enhancing, setEnhancing] = useState(false); // State for enhancing process
  const [enhancedImageUrl, setEnhancedImageUrl] = useState(null); // State to store enhanced image
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false); // State to handle upload process

   

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  
  // console.log('CanvasWithPrompt component rendered with selectedImageUrl:', selectedImageUrl);

  useEffect(() => {
    if (selectedImageUrl) {
      // console.log('CanvasWithPrompt: Drawing image due to selectedImageUrl change:', selectedImageUrl);
      setImageUrl(selectedImageUrl);
      drawImage(selectedImageUrl);
    }
  }, [selectedImageUrl]);
  

// Effect to draw the image whenever imageUrl changes
useEffect(() => {
  if (imageUrl) {
    // console.log('Drawing image from imageUrl:', imageUrl);
    // Draw the image based on its type (array or single URL)
    if (Array.isArray(imageUrl)) {
      drawImage(imageUrl[0]); // Draw the first image in the array
    } else {
      drawImage(imageUrl); // Draw the single image URL
    }
  }
}, [imageUrl]);


  useEffect(() => {
    if (selectedImageUrl) {
      setImageUrl(selectedImageUrl); // Update state when a new image is selected
    }
  }, [selectedImageUrl]);

  // Ensure prompt updates when initialPrompt changes
  useEffect(() => {
    if (initialPrompt) {
      setPrompt(initialPrompt);
      // console.log("CanvasWithPrompt received updated prompt:", initialPrompt); // Logs updated prompt correctly
    }
  }, [initialPrompt]);

  // Function to draw a single image on the canvas
  const drawImage = (url, width = defaultWidth, height = defaultHeight) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error('Canvas not available');
      return;
    }
  
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('Could not get canvas context');
      return;
    }
  
    const scale = 4; 
    canvas.width = width * scale;
    canvas.height = height * scale;
    ctx.scale(scale, scale);
  
    ctx.clearRect(0, 0, width, height);
  
    const img = new Image();
  
    // Set crossOrigin attribute to allow cross-origin images
    img.crossOrigin = 'anonymous'; 
  
    img.onload = () => {
      // console.log('Image loaded successfully:', url);
      const radius = 8;
      ctx.beginPath();
      ctx.moveTo(radius, 0);
      ctx.lineTo(width - radius, 0);
      ctx.quadraticCurveTo(width, 0, width, radius);
      ctx.lineTo(width, height - radius);
      ctx.quadraticCurveTo(width, height, width - radius, height);
      ctx.lineTo(radius, height);
      ctx.quadraticCurveTo(0, height, 0, height - radius);
      ctx.lineTo(0, radius);
      ctx.quadraticCurveTo(0, 0, radius, 0);
      ctx.closePath();
  
      ctx.clip();
      ctx.drawImage(img, 0, 0, width, height);
      ctx.lineWidth = 6;
      ctx.strokeStyle = 'white';
      ctx.stroke();
    };

    img.onerror = () => {
      console.error('Error loading image:', url);
    };
  
    img.src = url;
  };
  

  const handleModify = async (e) => {
    e.preventDefault();

    // Use the first item if `imageUrl` is an array, otherwise use it directly
    const imageToSend = Array.isArray(imageUrl) ? imageUrl[0] : imageUrl;

    // Ensure image and prompt are present before making the request
    if (!imageToSend || !prompt) {
      console.error('Image and prompt are required');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
        // Make the POST request, sending the image URL and prompt as JSON
        const response = await axios.post(`${API_ENDPOINT}/api/generate`, {
            image: imageToSend, // Use the URL directly
            prompt: prompt
        });

        const [depthMap, outputImage] = response.data; // Adjust based on expected response format

        if (outputImage) {
            setImageUrl(outputImage); // Update the image in the canvas
            // console.log('Updated canvas with new image URL:', outputImage);
        } else {
            console.warn('No output image returned from API');
            setError('No output image returned from API');
        }
    } catch (error) {
        console.error('Error modifying image:', error);
        setError('Error modifying image');
    } finally {
        setIsLoading(false);
    }
};

  
  
  
  const handleEnhance = async () => {
    // Allow for both string and array types in imageUrl
    const imageToEnhance = Array.isArray(imageUrl) ? imageUrl[0] : imageUrl;
  
    if (!imageToEnhance) {
      return; // Return early if there is no valid image URL
    }
    setIsLoading(true);
    setEnhancing(true);
    setError(null);
  
    try {
      const response = await axios.post(`${API_ENDPOINT}/api/enhance`, {
        image: imageToEnhance, // Send the appropriate image URL
      });
  
      if (response.data && response.data.output_url) {
        // Update the canvas with the enhanced image
        setImageUrl([response.data.output_url]); // Store it as an array like the initial image
        setEnhancedImageUrl(response.data.output_url);
      } else {
        setError('No enhanced image returned from API');
      }
    } catch (err) {
      console.error('Error enhancing image:', err);
      setError('Error enhancing image');
    } finally {
      setIsLoading(false);
      setEnhancing(false);
    }
  };

  // Handle form submission to generate image
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsPromptVisible(false);
    setAreButtonsVisible(false);

    try {
      const response = await fetch(`${API_ENDPOINT}/api/replicate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ prompt, aspect_ratio: '16:9', type: currentType }) 
      });

      if (!response.ok) {
        throw new Error('Failed to generate image');
      }

      const data = await response.json();

      if (data && data.image_urls) {
        setImageUrl(data.image_urls);
      }
    } catch (error) {
      console.error('Error fetching image from API:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const createFluxPro11 = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsPromptVisible(false);
    setAreButtonsVisible(false);

    try {
      const response = await fetch(`${API_ENDPOINT}/api/replicate/generate-flux-1-1-pro`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ prompt, aspect_ratio: '16:9', type: currentType }) 
      });

      if (!response.ok) {
        throw new Error('Failed to generate image');
      }

      const data = await response.json();

      if (data && data.image_urls) {
        setImageUrl(data.image_urls);
      }
    } catch (error) {
      // console.error('Error fetching image from API:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const createFluxPro = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsPromptVisible(false);
    setAreButtonsVisible(false);

    try {
      const response = await fetch(`${API_ENDPOINT}/api/replicate/generate-flux-pro`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ prompt, aspect_ratio: '16:9', type: currentType }) 
      });

      if (!response.ok) {
        throw new Error('Failed to generate image');
      }

      const data = await response.json();

      if (data && data.image_urls) {
        setImageUrl(data.image_urls);
      }
    } catch (error) {
      // console.error('Error fetching image from API:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const aiModify = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsPromptVisible(false);
    setAreButtonsVisible(false);

    const canvas = canvasRef.current;
    if (!canvas) {
        // console.error('Canvas not found');
        setIsLoading(false);
        return;
    }

    // Convert the canvas content to a Blob
    canvas.toBlob(async (blob) => {
        if (!blob) {
            // console.error('Failed to convert canvas to Blob');
            setIsLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('image', blob, 'canvas-image.png'); // Append canvas Blob as file
        formData.append('prompt', prompt); // Include the prompt
        formData.append('aspect_ratio', '16:9');
        
        try {
            const response = await axios.post(`${API_ENDPOINT}/api/replicate/ai-modify`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            const data = response.data;

            // ('Full API response:', data);console.log

            if (data && data.image_url) {
                // Use the returned image URL
                setImageUrl(data.image_url);
                // console.log('Updated canvas with new image URL:', data.image_url);
            } else {
                // console.warn('No image URL found in API response');
                setError('No image URL found in API response');
            }
        } catch (error) {
            // console.error('Error fetching image from API:', error);
            setError('Error fetching image');
        } finally {
            setIsLoading(false);
        }
    });
};



  // Handle input changes in the prompt textarea
   const handleInputChange = (e) => {
    setPrompt(e.target.value);
  };

  // Handle saving the canvas as an image and uploading it
  const handleSavePhoto = async () => {
    setIsLoading(true);
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
  
    // Convert canvas to Blob
    canvas.toBlob(async (blob) => {
      if (!blob) {
        console.error('Failed to convert canvas to Blob');
        return;
      }

      const appData = JSON.parse(localStorage.getItem('appData'));
      const appState = JSON.parse(localStorage.getItem('appState'));
      const userId = appData.userId;
      const jobId = appData.jobs[0]._id;
      const selectedFloorId = appState.currentFloorID;
  

      const formData = new FormData();
      formData.append('jobId', jobId); // You can dynamically set these values as needed
      formData.append('createdBy', userId); 
      formData.append('roomId', selectedRoomId);
      formData.append('floorId', selectedFloorId);
      formData.append('containerName', 'uploads/album');
      formData.append('images', blob, 'canvas-image.png'); // Add canvas Blob as file
      formData.append('category', 'design');


      setUploading(true);
      try {
        const response = await axios.post(`${API_ENDPOINT}/api/upload-files`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (response.data) {
          // console.log('Canvas image uploaded successfully:', response.data);
        }
      } catch (error) {
        console.error('Error uploading canvas image:', error);
      } finally {
        setIsLoading(false);
        setUploading(false);
      }
    });
  };

  const handleCloseOverlay = () => {
    setIsPromptVisible(false);
    setAreButtonsVisible(false);
    setShowCanvas(false);
  };

  const toggleFormVisibility = () => {
    setIsPromptVisible(!isPromptVisible);
    setAreButtonsVisible(!areButtonsVisible);
  };

  return (
    <div style={{ position: 'relative', ...style }}>
      <div
        style={{
          position: 'absolute',
          top: '2px',
          left: '2px',
          right: '2px',
          height: '44px',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          borderRadius: '8px 8px 0px 0px',
          zIndex: 3
        }}
      ></div>

      <div
        style={{
          position: 'absolute',
          bottom: '6px',
          left: '2px',
          right: '2px',
          height: '44px',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          borderRadius: '0px 0px 8px 8px',
          zIndex: 3
        }}
      ></div>

      {/* Close button */}
      <CloseButton
          onClick={handleCloseOverlay}
          onMouseEnter={() => setIsCloseHovered(true)}
          onMouseLeave={() => setIsCloseHovered(false)}
          style={{
            position: 'absolute',
            top: '6px',
            right: '8px',
            cursor: 'pointer',
            zIndex: 5
          }}
          endIcon={<Cancel />}
        >
           
          
          Close
        </CloseButton>

        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: '13px', left: '8px', zIndex: 5 }}>
           {/* Modify Button */}
            <ModifyButton
              onClick={aiModify}
              style={{ marginRight: '2px', cursor: 'pointer' }}
              endIcon={<AutoAwesome />}
              disabled={!imageUrl} // Disable if imageUrl is not set
            >
              {enhancing ? 'Modifying...' : 'AI Modify'}
            </ModifyButton>


            {/* Icon between buttons */}
            <MoreVert style={{ marginRight: '0px', color: 'white' }} />
            {/* Save Photo Button */}
          <EnhanceButton
              onClick={createFluxPro}
              disabled={uploading}
              style={{ marginRight: '6px' }}
              endIcon={<AutoAwesome />}
            >
              {uploading ? 'Saving...' : 'Standard'}
            </EnhanceButton>
            
            <EnhanceButton
              onClick={createFluxPro11}
              disabled={uploading}
              endIcon={<AutoAwesome />}
            >
              {uploading ? 'Saving...' : 'High Quality'}
            </EnhanceButton>
      </div>
        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: '13px', right: '8px', zIndex: 5 }}>
           {/* Enhance Button */}
            <EnhanceButton
              onClick={handleEnhance}
              style={{
                marginRight: '2px',
                cursor: 'pointer',
              }}
              endIcon={<AutoAwesome />}
            >
              {enhancing ? 'Enhancing...' : 'Enhance Photo'}
            </EnhanceButton>

            {/* Icon between buttons */}
            <MoreVert style={{ marginRight: '2px', color: 'white' }} />

            {/* Save Photo Button */}
            <SaveButton
              onClick={handleSavePhoto}
              disabled={uploading}
              endIcon={<Save />}
            >
              {uploading ? 'Saving...' : 'Save Photo'}
            </SaveButton>
          </div>


      {/* Canvas to draw the generated image */}
      <canvas
        ref={canvasRef}
        style={{
          width: `${defaultWidth}px`, 
          height: `${defaultHeight}px`, 
          backgroundColor: 'rgb(0,0,0,0)', 
          cursor: 'pointer', 
        }}
        onClick={toggleFormVisibility} 
      />

      {/* Prompt input form */}
      {isPromptVisible && (
        <form
          onSubmit={handleFormSubmit}
          style={{
            position: 'absolute',
            bottom: 100,
            left: 50,
            right: 50,
            zIndex: 2,
            background: 'rgba(255, 255, 255, 0.9)',
            padding: '10px',
            borderRadius: '8px',
          }}
        >
          <textarea
            value={prompt}
            onChange={handleInputChange}
            placeholder="Enter your prompt..."
            style={{
              padding: '10px',
              width: '100%', 
              height: '12em', 
              boxSizing: 'border-box', 
              resize: 'none', 
            }}
          />
         
        </form>
      )}

      {/* Loading spinner */}
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', 
            zIndex: 3,
          }}
        >
          <div className="spinner" />
        </div>
      )}

      {/* Error message */}
      {error && (
        <div
          style={{
            position: 'absolute',
            top: '60%',
            left: '50%',
            transform: 'translate(-50%, -50%)', 
            zIndex: 4,
            color: 'red',
          }}
        >
          {error}
        </div>
      )}
      
    </div>
  );
};

export default CanvasWithPrompt;
