import React, { useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';

const Contract = ({ quoteData, schedule, contract, setContract }) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const handleGenerateContract = async () => {
    setIsGenerating(true);
    try {
      const response = await fetch(`${API_ENDPOINT}/api/generate-contract`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ quoteData, schedule }),
      });
      const data = await response.json();
      setContract(data.contract);
    } catch (error) {
      console.error('Error generating contract:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Contract Agreement
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateContract}
        disabled={isGenerating}
      >
        {isGenerating ? <CircularProgress size={24} /> : 'Generate Contract'}
      </Button>
      {contract && (
        <Box mt={3}>
          <Typography variant="body1" component="pre">
            {contract}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Contract;
