import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { PictureAsPdf, Email } from '@mui/icons-material';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './QuoteTable.css';

const QuoteTable = () => {
  const [companyDetails, setCompanyDetails] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const [quotes, setQuotes] = useState([]);
  const [totals, setTotals] = useState({});
  const [logoDataUrl, setLogoDataUrl] = useState(null);
  const [taxRate, setTaxRate] = useState(0);
  const [taxType, setTaxType] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState('');

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const formattedSortCode = companyDetails.bankingOptions?.sortCode
    ? companyDetails.bankingOptions.sortCode.replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3')
    : '';
  const maskedAccountNumber = companyDetails.bankingOptions?.accountNumber
    ? '****' + companyDetails.bankingOptions.accountNumber.slice(-4)
    : '';

  useEffect(() => {
    const appDataString = localStorage.getItem('appData');
    const appStateString = localStorage.getItem('appState');
    const invoiceCurrencyData = JSON.parse(localStorage.getItem('invoiceCurrency'));

    if (appDataString && appStateString && invoiceCurrencyData) {
      const appData = JSON.parse(appDataString);
      const appState = JSON.parse(appStateString);
      setCompanyDetails(appData.account);

      const selectedCurrencyCode = appData.account.selectedInvoiceCurrency;
      const selectedCurrency = invoiceCurrencyData.currencies.find(
        (currency) => currency.code === selectedCurrencyCode
      );
      const currencySymbol = selectedCurrency ? selectedCurrency.symbol : '';
      setCurrencySymbol(currencySymbol);

      const currentJobID = appState.currentJobID;
      const currentJob = appData.jobs.find((job) => job._id === currentJobID);
      if (!currentJob) {
        console.error('Current job not found');
        return;
      }

      const clientId = currentJob.ClientID._id;
      const client = appData.clients.find((client) => client._id === clientId);
      setClientDetails(client);

      const floors = currentJob.FloorPlanID.Floors;
      let allRooms = [];
      floors.forEach((floor) => {
        allRooms = allRooms.concat(floor.rooms);
      });

      let quotesArray = [];
      let totalWallArea = 0;
      let totalPaintingCost = 0;
      let totalPaintCost = 0;
      let totalPrepCost = 0;
      let totalSkirtingArchitraveCost = 0;
      let totalCost = 0;

      const roomHeight = currentJob.JobDetails.RoomHeight || 2.4;

      allRooms.forEach((room) => {
        const widthInMeters = room.width / 100;
        const lengthInMeters = room.length / 100;
        const wallArea = 2 * (widthInMeters + lengthInMeters) * roomHeight;

        const paintingCostPerSqM = 10;
        const paintingCost = wallArea * paintingCostPerSqM;

        const coveragePerLiter = 10;
        const paintRequired = wallArea / coveragePerLiter;

        const paintCostPerLiter = 5;
        const paintCost = paintRequired * paintCostPerLiter;

        const prepCostPerSqM = 2;
        const prepCost = wallArea * prepCostPerSqM;

        const perimeter = 2 * (widthInMeters + lengthInMeters);
        const skirtingArchitraveCostPerMeter = 1;
        const skirtingArchitraveCost = perimeter * skirtingArchitraveCostPerMeter;

        const totalRoomCost = paintingCost + paintCost + prepCost + skirtingArchitraveCost;

        totalWallArea += wallArea;
        totalPaintingCost += paintingCost;
        totalPaintCost += paintCost;
        totalPrepCost += prepCost;
        totalSkirtingArchitraveCost += skirtingArchitraveCost;
        totalCost += totalRoomCost;

        quotesArray.push({
          id: room.id,
          name: room.name,
          wallArea: wallArea.toFixed(2),
          paintingCost: paintingCost.toFixed(2),
          paintCost: paintCost.toFixed(2),
          prepCost: prepCost.toFixed(2),
          skirtingArchitraveCost: skirtingArchitraveCost.toFixed(2),
          totalCost: totalRoomCost.toFixed(2),
        });
      });

      setQuotes(quotesArray);
      setTotals({
        totalWallArea: totalWallArea.toFixed(2),
        totalPaintingCost: totalPaintingCost.toFixed(2),
        totalPaintCost: totalPaintCost.toFixed(2),
        totalPrepCost: totalPrepCost.toFixed(2),
        totalSkirtingArchitraveCost: totalSkirtingArchitraveCost.toFixed(2),
        totalCost: totalCost.toFixed(2),
      });

      const loadTaxCodes = () => {
        const taxData = localStorage.getItem('taxCodes');
        if (taxData) {
          try {
            const parsedData = JSON.parse(taxData);
            if (parsedData && Array.isArray(parsedData.taxRates)) {
              console.log("Tax rates loaded successfully:", parsedData.taxRates);
              return parsedData.taxRates; // Return the array of tax rates
            } else {
              console.error("Tax rates are not in the expected format.");
              return [];
            }
          } catch (error) {
            console.error("Failed to parse taxCodes from localStorage:", error);
            return [];
          }
        } else {
          console.error("No tax codes found in localStorage.");
          return [];
        }
      };

      const taxRates = loadTaxCodes();

      console.log("Loaded tax rates:", taxRates);
      console.log("Selected Tax Rate ID from appData:", appData.account.selectedTaxRate);

      const selectedTaxRateId = parseInt(appData.account.selectedTaxRate, 10);
      console.log("Parsed Selected Tax Rate ID (Number):", selectedTaxRateId);
      console.log("Tax Rates Length:", taxRates.length);

      if (selectedTaxRateId && taxRates.length > 0) {
        console.log("Step 1 - Looking for matching tax code");

        const selectedTaxCode = taxRates.find(
          (taxCode) => parseInt(taxCode.id, 10) === selectedTaxRateId
        );

        console.log("Found Selected Tax Code:", selectedTaxCode);

        if (selectedTaxCode) {
          let taxRateDecimal = parseFloat(selectedTaxCode.taxRate);

          if (isNaN(taxRateDecimal)) {
            console.error(`Tax rate is not a number for tax ID: ${selectedTaxRateId}`);
            return;
          }

          if (taxRateDecimal > 1) {
            taxRateDecimal = taxRateDecimal / 100;
          }

          console.log(`Setting tax rate: ${taxRateDecimal}, tax type: ${selectedTaxCode.taxType}`);
          setTaxRate(taxRateDecimal);
          setTaxType(selectedTaxCode.taxType);
        } else {
          console.error(`Selected tax code not found for tax ID: ${selectedTaxRateId}`);
        }
      }
    } else {
      console.error('appData or appState or invoiceCurrencyData is missing');
    }

    const logoUrl =
      companyDetails.logo ||
      'https://paintquoteprohd.blob.core.windows.net/uploads/logos/1729516228838-pqp_pro_logo_0002.jpg';

    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = logoUrl;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL('image/png');
      setLogoDataUrl(dataURL);
    };

    img.onerror = () => {
      console.error('Failed to load logo image.');
    };
  }, []);

  const totalCostNumber = parseFloat(totals.totalCost) || 0;
  const taxRateNumber = parseFloat(taxRate) || 0;

  const taxAmount = (totalCostNumber * taxRateNumber).toFixed(2);
  const totalWithTax = (totalCostNumber + parseFloat(taxAmount)).toFixed(2);

  const handleDownloadPDF = () => {
    const quoteElement = document.getElementById('quote-container');
    html2canvas(quoteElement, {
      useCORS: true,
      allowTaint: false,
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('quote.pdf');
    });
  };

  const handleEmailPDF = () => {
    const quoteElement = document.getElementById('quote-container');
    html2canvas(quoteElement, {
      useCORS: true,
      allowTaint: false,
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      const pdfBlob = pdf.output('blob');

      const formData = new FormData();
      formData.append('pdf', pdfBlob, 'quote.pdf');
      formData.append('clientEmail', clientDetails.clientEmail);
      formData.append('clientName', clientDetails.clientFullName);

      axios
        .post(`${API_ENDPOINT}/api/send-quote`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.success) {
            alert('Email sent successfully!');
          } else {
            alert(`Failed to send email: ${data.error}`);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('An error occurred while sending the email.');
        });
    });
  };

  return (
    <Box p={1}>
      <Box display="flex" justifyContent="flex-start" mb={1}>
        <IconButton onClick={handleDownloadPDF} sx={{ fontSize: 30 }}>
          <PictureAsPdf fontSize="inherit" />
        </IconButton>
        <IconButton onClick={handleEmailPDF} sx={{ fontSize: 30 }}>
          <Email fontSize="inherit" />
        </IconButton>
      </Box>

      <Box
        className="quote-container"
        sx={{
          transform: 'scale(1)',
          transformOrigin: 'top left',
          backgroundColor: 'white',
          padding: 1,
          borderRadius: 2,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          width: '100%',
        }}
      >
        <Box className="quote" id="quote-container">
          {/* Header */}
          <Box
            className="header"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Box className="logo" ml={1}>
                {logoDataUrl ? (
                  <img
                    src={logoDataUrl}
                    alt="Company Logo"
                    style={{ maxWidth: '250px', height: 'auto' }}
                  />
                ) : (
                  <Typography variant="h5" className="quote-title">
                    {companyDetails.companyName}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box className="quote-details" textAlign="left">
              <Typography className="quote-header">Quote Details</Typography>
              <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                {companyDetails.companyName}
              </Typography>
              {companyDetails.companyNumber && (
                <Typography variant="body2">
                  Company Number: {companyDetails.companyNumber}
                </Typography>
              )}
              {companyDetails.vatNumber && (
                <Typography variant="body2">
                  VAT Number: {companyDetails.vatNumber}
                </Typography>
              )}
              <Typography variant="body2">
                Date: {new Date().toLocaleDateString()}
              </Typography>
              <Typography variant="body2">Quote No: #001</Typography>
            </Box>
          </Box>

          {/* Client Details */}
          <Box className="quote-details" my={1} textAlign="left" sx={{ pl: 2 }}>
            <Typography variant="h7" className="quote-header">
              Client Details
            </Typography>
            <Typography variant="subtitle2">{clientDetails.clientFullName}</Typography>
            <Typography variant="subtitle2">{clientDetails.clientStreetAddress}</Typography>
            <Typography variant="subtitle2">{clientDetails.clientCountry}</Typography>
            <Typography variant="subtitle2">{clientDetails.clientPostalCode}</Typography>
          </Box>
          <Box my={1} textAlign="left" sx={{ pl: 2 }}>
            <Typography variant="subtitle1">
              <strong>Quote Summary</strong>
            </Typography>
          </Box>

          {/* Quote Table */}
          <Box sx={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflow: 'hidden' }}>
            <Box
              component="table"
              className="quote-table"
              width="100%"
              mb={4}
              sx={{
                borderCollapse: 'collapse',
                tableLayout: 'auto',
                width: '100%',
              }}
            >
              <thead>
                <tr style={{ backgroundColor: '#007BFF', color: '#ffffff' }}>
                  <th className="table-header" style={{ width: '15%' }}>Room Name</th>
                  <th className="table-header" style={{ width: '10%' }}>Area (m²)</th>
                  <th className="table-header" style={{ width: '15%' }}>Painting</th>
                  <th className="table-header" style={{ width: '15%' }}>Paint</th>
                  <th className="table-header" style={{ width: '15%' }}>Prep</th>
                  <th className="table-header" style={{ width: '15%' }}>Woodwork</th>
                  <th className="table-header" style={{ width: '15%' }}>Total Cost</th>
                </tr>
              </thead>
              <tbody>
                {quotes.map((quote, index) => (
                  <tr key={quote.id} style={{ backgroundColor: index % 2 === 0 ? '#ddd' : '#ffffff' }}>
                    <td className="table-cell" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <strong>{quote.name}</strong>
                    </td>
                    <td className="table-cell" style={{ textAlign: 'center' }}>{quote.wallArea}</td>
                    <td className="table-cell" style={{ textAlign: 'center' }}>{quote.paintingCost}</td>
                    <td className="table-cell" style={{ textAlign: 'center' }}>{quote.paintCost}</td>
                    <td className="table-cell" style={{ textAlign: 'center' }}>{quote.prepCost}</td>
                    <td className="table-cell" style={{ textAlign: 'center' }}>{quote.skirtingArchitraveCost}</td>
                    <td className="table-cell" style={{ textAlign: 'center' }}>{currencySymbol}{quote.totalCost}</td>
                  </tr>
                ))}
                {/* Totals */}
                <tr style={{ backgroundColor: '#e0f7fa', fontWeight: 'bold', fontSize: '1.1rem' }}>
                  <td className="table-cell"><strong>Total</strong></td>
                  <td className="table-cell" style={{ textAlign: 'center', fontSize: '1.1rem' }}>{totals.totalWallArea}</td>
                  <td className="table-cell" style={{ textAlign: 'center', fontSize: '1.1rem' }}>{totals.totalPaintingCost}</td>
                  <td className="table-cell" style={{ textAlign: 'center', fontSize: '1.1rem' }}>{totals.totalPaintCost}</td>
                  <td className="table-cell" style={{ textAlign: 'center', fontSize: '1.1rem' }}>{totals.totalPrepCost}</td>
                  <td className="table-cell" style={{ textAlign: 'center', fontSize: '1.1rem' }}>{totals.totalSkirtingArchitraveCost}</td>
                  <td className="table-cell" style={{ textAlign: 'center', fontSize: '1.1rem' }}>{currencySymbol}{totals.totalCost}</td>
                </tr>
                {/* Tax row */}
                <tr style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                  <td className="table-cell" colSpan={6} style={{ textAlign: 'right', fontSize: '1.2rem' }}>
                    {taxType} ({(taxRateNumber * 100).toFixed(2)}%)
                  </td>
                  <td className="table-cell" style={{ textAlign: 'right', fontSize: '1.2rem' }}>
                    {currencySymbol}{taxAmount}
                  </td>
                </tr>
                {/* Total with tax row */}
                <tr style={{ backgroundColor: '#e3f2fd', fontWeight: 'bold' }}>
                  <td className="table-cell" style={{ textAlign: 'right', fontSize: '1.3rem' }} colSpan={6}>
                    Total Inc {taxType}
                  </td>
                  <td className="table-cell" style={{ textAlign: 'right', fontSize: '1.3rem' }}>
                    {currencySymbol}{totalWithTax}
                  </td>
                </tr>
              </tbody>
            </Box>
          </Box>

          {/* Footer */}
          <Box className="footer" display="flex" justifyContent="space-between" textAlign="left" sx={{ pl: 2 }}>
            <Box className="contact">
              <Typography variant="subtitle2">
                <strong>CONTACT DETAILS</strong>
              </Typography>
              <Typography variant="subtitle2">
                {companyDetails.ownerFirstName} {companyDetails.ownerSurname}
              </Typography>
              <Typography variant="subtitle2">{companyDetails.email}</Typography>
              <Typography variant="subtitle2">{companyDetails.phoneNumber}</Typography>
            </Box>
            <Box className="payment-method">
              <Typography variant="subtitle2">
                <strong>PAYMENT METHOD</strong>
              </Typography>
              <Typography variant="subtitle2">
                Bank Name: {companyDetails.bankingOptions?.selectedBank}
              </Typography>
              <Typography variant="subtitle2">Sort Code: {formattedSortCode}</Typography>
              <Typography variant="subtitle2">Account Number: {maskedAccountNumber}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default QuoteTable;
