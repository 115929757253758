import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Box, InputAdornment, Button, Typography, Popover, IconButton } from '@mui/material';
import { Check, PhoneIphone, MailOutline, HelpOutline } from '@mui/icons-material';
import theme from '../../theme';

const ContractScope = ({
    contractState,
    handleContractInputChange,
    handleSubmit,
    updateSuccess,
    isUpdating,
}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState('');

    const handlePopoverOpen = (event, content) => {
        setAnchorEl(event.currentTarget);
        setPopoverContent(content);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverContent('');
    };

    const open = Boolean(anchorEl);

  return (
    <Box sx={theme.innerBox}>
      <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
      <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Contract Details
            </Typography>
            <IconButton 
                onClick={(e) => handlePopoverOpen(e, 'This is the description for General Liability Insurance. It covers potential liabilities and damages.')}
                size="small"
            >
                <HelpOutline fontSize="small" />
            </IconButton>
            </Box>
    <TextField
          label="Project Description"
          value={contractState.projectDescription}
          onChange={(e) => handleContractInputChange('projectDescription', e.target.value)}
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
        />
        <TextField
          label="Tasks"
          value={contractState.tasks.join(', ')}
          onChange={(e) => handleContractInputChange('tasks', e.target.value.split(',').map(task => task.trim()))}
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
        />
        <TextField
          label="Materials and Colors"
          value={contractState.materialsAndColors}
          onChange={(e) => handleContractInputChange('materialsAndColors', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
        />
        <TextField
          label="Extra Services"
          value={contractState.extraServices}
          onChange={(e) => handleContractInputChange('extraServices', e.target.value)}
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
        />
        <Button
          variant="contained"
          color={updateSuccess ? 'success' : 'primary'}
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
          disabled={isUpdating}
        >
           {isUpdating
            ? 'Updating Details...'
            : updateSuccess
            ? 'Details Updated'
            : 'Update Details'}
          {updateSuccess && <Check style={{ marginLeft: '4px' }} />}
        </Button>
      </Box>
      <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography>{popoverContent}</Typography>
          </Box>
        </Popover>
    </Box>
    </Box>
  );
};

export default ContractScope;