import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import generateSchedule from './openai';

const Schedule = ({ quoteData, schedule, setSchedule }) => {
  const [loading, setLoading] = useState(false);

  const handleGenerateSchedule = async () => {
    setLoading(true);
    try {
      const generatedSchedule = await generateSchedule(quoteData);
      setSchedule(generatedSchedule);
    } catch (error) {
      console.error('Error generating schedule:', error);
      setSchedule('Failed to generate schedule.');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!schedule) {
      handleGenerateSchedule();
    }
  }, [schedule]);

  return (
    <Box p={2}>
      <Typography variant="h4">Schedule</Typography>
      <Button variant="contained" color="primary" onClick={handleGenerateSchedule} disabled={loading}>
        {schedule ? 'Regenerate Schedule' : 'Generate Schedule'}
      </Button>
      {loading && <CircularProgress />}
      {schedule && (
        <Box mt={2}>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            {schedule}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Schedule;
