// constants.js

export const roomTypes = [
    'Bedroom', 'Kitchen', 'Living Room', 'Bathroom', 'Dining Room',
    'Home Office', 'Nursery', 'Laundry Room', 'Garage',
    'Hallway', 'Basement', 'Closet', 'Pantry', 'Sunroom', 'Entryway'
  ];
  

export const PIXELS_PER_METER = 50;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const HANDLE_SIZE = 10;
export const SNAP_SIZE = PIXELS_PER_METER / 10;