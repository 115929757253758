import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@mui/material';
import FloorPlan from './components/Floorplan/Floorplan';
import AI from './AI';
import QuoteTable from './QuoteTable';
import Schedule from './Schedule';
import Contract from './Contract';
import Invoicing from './Invoicing';
import Inspection from './Inspection';
import Mapping from './Mapping';
import Palette from './Palette';
import ColourOptions from './ColourOptions';
import RoomOptions from './RoomOptions';
import PaintOptions from './PaintOptions'
import JobOptions from './JobOptionsV2';
import AccountPanel from './AccountPanel';
import ContractorPanel from './ContractorPanel';
import ClientPanel from './ClientPanel';
import ContractPanel from './ContractPanel';
import SchedulePanel from './SchedulePanel';
import QuotePanel from './QuotePanel';
import InvoicePanel from './InvoicePanel';
import InspectionPanel from './InspectionPanel';
import MapPanel from './MapPanel';
import IconColumn from './IconColumn';
// import ThreeDScene from './ThreeDScene';
import theme from './theme';

import { PIXELS_PER_METER } from './components/Floorplan/Constants'; // Import the constant
import './App.css';

const backgroundImages = {
  floorplan: 'url(https://paintquoteprohd.blob.core.windows.net/uploads/backgrounds/floorplans/floorplan_0001.webp)',
  palette: 'url(https://paintquoteprohd.blob.core.windows.net/uploads/backgrounds/palette/palette_0002.webp)',
  designer: 'url(https://paintquoteprohd.blob.core.windows.net/uploads/backgrounds/design/design_0001.webp)',
  report: 'url(https://paintquoteprohd.blob.core.windows.net/uploads/backgrounds/reports/report_0002.webp)',
  quote: 'url(https://paintquoteprohd.blob.core.windows.net/uploads/backgrounds/quote/quote_0001.webp)',
  schedule: 'url(https://paintquoteprohd.blob.core.windows.net/uploads/backgrounds/schedule/schedule_0001.webp)',
  contract: 'url(https://paintquoteprohd.blob.core.windows.net/uploads/backgrounds/contract/contract_0001.webp)',
  invoicing: 'url(https://paintquoteprohd.blob.core.windows.net/uploads/backgrounds/invoices/invoices_0001.webp)',
  mapping: 'url(https://paintquoteprohd.blob.core.windows.net/uploads/backgrounds/invoices/invoices_0001.webp)',
};

const App = () => {
  // State for the collapsible panel
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  // Consolidated state for app variables
  const [appState, setAppState] = useState({
    userId: '',
    currentAccountID: '',
    currentContractorID: '',
    currentClientID: '',
    currentJobID: '',
    currentFloorplanID: '',
    currentFloorID: '',
    currentRoomID: '',
    currentPaletteID: '',
  });

  
  

     // Function to toggle the panel
     const togglePanel = () => {
      setIsPanelOpen((prev) => !prev);
    };

  // Function to update a specific ID
  const updateAppState = (key, value) => {
    setAppState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [activePanel, setActivePanel] = useState('accountPanel'); // State for active panel

 

  //Palette Options
  const [paintBrand, setPaintBrand] = useState('');
  const [paintHue, setPaintHue] = useState('');
  const [paintBrandRoom, setPaintBrandRoom] = useState('');
  const [paintHueRoom, setPaintHueRoom] = useState('');
  const [roomData, setRoomData] = useState({});

  const [colourAccuracy, setColourAccuracy] = useState(50);
  const [colourMatches, setColourMatches] = useState(10);

  // InspectionReport default values
  const [inspectionReport, setInspectionReport] = useState(null);
  const [inspectionReports, setInspectionReports] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const [selectedRoom, setSelectedRoom] = useState(null);

  const [style, setStyle] = useState([]);

  // Job Paint Options
  const [coverage, setCoverage] = useState(15); 
  const [metersSquared, setMetersSquared] = useState(15); 
  const [roomHeight, setRoomHeight] = useState(2.5); 
  const [wallCoats, setWallCoats] = useState(1);
  const [roomPrep, setRoomPrep] = useState('Low');
  const [color, setColor] = useState('gray');
  const [numFloors, setNumFloors] = useState('1');

  //Painting Room Options
  const [paintWalls, setPaintWalls] = useState(false);
  const [paintCeiling, setPaintCeiling] = useState(false);
  const [paintSkirtingArchitrave, setPaintSkirtingArchitrave] = useState(false);
  const [paintDoors, setPaintDoors] = useState(false);
  const [paintWindows, setPaintWindows] = useState(false);
  const [undercoatPrimer, setUndercoatPrimer] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);

  const [rooms, setRooms] = useState([]);
  const [newRoom, setNewRoom] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [selectedWall, setSelectedWall] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);

  const [schedule, setSchedule] = useState('');
  const [contract, setContract] = useState('');

  const [selectedImageUrl, setSelectedImageUrl] = useState(null); // Declare the state for selectedImageUrl


  // Account Details
  const [companyName, setCompanyName] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [logo, setLogo] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [whatsApp, setWhatsApp] = useState('');
  const [website, setWebsite] = useState('');
  const [socialMedia, setSocialMedia] = useState({});
  const [primaryContactName, setPrimaryContactName] = useState('');
  const [primaryContactPosition, setPrimaryContactPosition] = useState('');
  const [secondaryContactName, setSecondaryContactName] = useState('');
  const [secondaryContactPhone, setSecondaryContactPhone] = useState('');
  const [industryType, setIndustryType] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [preferredContactMethod, setPreferredContactMethod] = useState('');
  const [invoiceNotes, setInvoiceNotes] = useState('');
  const [invoiceFormatting, setInvoiceFormatting] = useState('');
  const [invoiceStartNum, setInvoiceStartNum] = useState('');
  const [invoicePrefix, setInvoicePrefix] = useState('');
  const [invoiceTemplate, setInvoiceTemplate] = useState('');
  const [invoiceDiscounts, setInvoiceDiscounts] = useState('0');
  const [customerReferencePO, setCustomerReferencePO] = useState('PO0001');
  const [invoiceCurrency, setInvoiceCurrency] = useState('');
  const [invoiceTaxRate, setInvoiceTaxRate] = useState('');
  const [latePaymentFees, setLatePaymentFees] = useState('');
  const [invoicePaymentInstructions, setInvoicePaymentInstructions] = useState('');
  const [paymentTerms, setPaymentTerms] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [pinterest, setPinterest] = useState('');
  const [creditLimit, setCreditLimit] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [tags, setTags] = useState([]);
  const [sortCode, setSortCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');

  // State for Client Options
  const [currentSelectedClient, setCurrentSelectedClient] = useState(null);

  const [clientFullName, setClientFullName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientStreetAddress, setClientStreetAddress] = useState('');
  const [clientCity, setClientCity] = useState('');
  const [clientState, setClientState] = useState('');
  const [clientPostalCode, setClientPostalCode] = useState('');
  const [clientCountry, setClientCountry] = useState('');
  const [clientPhoto, setClientPhoto] = useState('');

  // State for Contractor Options
  const [contractors, setContractors] = useState([]);
  const [contractorFullName, setContractorFullName] = useState('Contractor Name');
  const [contractorEmail, setContractorEmail] = useState('Contractor Name');
  const [contractorPhone, setContractorPhone] = useState('07565134228');
  const [contractorPhoto, setContractorPhoto] = useState('07565134228');
  const [contractorType, setContractorType] = useState('Interior Painter');

  // State for Schedule Options
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // State for Directions Options
  const [startPostcode, setStartPostcode] = useState(null);
  const [endPostcode, setEndPostcode] = useState(null);

  const [roomName, setRoomName] = useState('');
  const [roomLength, setRoomLength] = useState('');
  const [roomWidth, setRoomWidth] = useState('');
  const [paintColor, setPaintColor] = useState('');
  const [skirtingBoards, setSkirtingBoards] = useState(false);
  const [architrave, setArchitrave] = useState(false);
  const [windows, setWindows] = useState(false);
  const [doors, setDoors] = useState(false);
  const [ceiling, setCeiling] = useState(false);
  const [paintFinish, setPaintFinish] = useState('');

  const [invoicesState, setInvoicesState] = useState([
    { id: 1, percentage: 50, paid: false },
    { id: 2, percentage: 50, paid: false },
  ]);
  const [numInvoices, setNumInvoices] = useState(invoicesState.length); // State for number of invoices

  const [billingOptions, setBillingOptions] = useState('auto');
  const [invoiceDetail, setInvoiceDetail] = useState('medium');
  const [labourCost, setLabourCost] = useState('hide');
  const [materialCost, setMaterialCost] = useState('hide');
  
  const [latePaymentFee, setLatePaymentFee] = useState(2);
  const [customerReference, setCustomerReference] = useState('Payment Reference');
  const [paymentInstructions, setPaymentInstructions] = useState('Pay ASAP');
  const [paymentType, setPaymentType ] = useState('[true,false,true]');

  const [colorChartPath, setColorChartPath] = useState('');
  const [selectedColor, setSelectedColor] = useState(null);

  const [currentJobID, setCurrentJobID] = useState(null);
  const [currentFloorID, setCurrentFloorID] = useState(null);

  //ContractDetails

  // useEffect(() => {
  //   console.log('Reports updated:', inspectionReports);
  //   console.log('Active tab:', activeTab);
  // }, [inspectionReports, activeTab]);

  

  
  
  useEffect(() => {
    // Retrieve appData from localStorage
    const appDataString = localStorage.getItem('appData');
    if (appDataString) {
      const appData = JSON.parse(appDataString);

      // Get the logo URL from appData.account.logo
      const logoUrlFromAppData = appData.account.logo;
      const companyNameAppData = appData.account.companyName;
      

      // Update the state with the logo URL
      if (companyNameAppData) {
        setCompanyName(companyNameAppData);
      } else {
        // Set a default logo URL if none exists
        setCompanyName('Your Company Name');
      }

      // Update the state with the logo URL
      if (logoUrlFromAppData) {
        setLogo(logoUrlFromAppData);
      } else {
        // Set a default logo URL if none exists
        setLogo('https://example.com/default-logo.png');
      }
    }
  }, []);

  const handleSwatchClick = (color) => {
    setSelectedColor(color);
  };

  useEffect(() => {
    Object.values(backgroundImages).forEach((imageUrl) => {
      const img = new Image();
      img.src = imageUrl.replace('url(', '').replace(')', '');
    });
  }, []);

  useEffect(() => {
    setNumInvoices(invoicesState.length);
  }, [invoicesState]);

  useEffect(() => {
    setBackgroundImage(backgroundImages.floorplan);
  }, []);


  const [backgroundImage, setBackgroundImage] = useState(backgroundImages.floorplan);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
    
    const panels = [
      'roomOptions',
      'colourOptions',
      'designer',
      'inspectionPanel',
      'quotePanel',
      'schedulePanel',
      'contractPanel',
      'invoicePanel',
      'mapPanel',
    ];
  
    setActivePanel(panels[newIndex] || 'accountPanel');
  
    // Update background image based on the selected tab index
    const backgroundImageKey = [
      'floorplan',
      'palette',
      'designer',
      'report',
      'quote',
      'schedule',
      'contract',
      'invoicing',
      'mapping',
    ][newIndex] || 'floorplan';
    setBackgroundImage(backgroundImages[backgroundImageKey]);
  };
  

  const handleStyleChange = (event, newStyle) => {
    if (newStyle !== null) {
      setStyle(newStyle);
    }
  };

  const calculateQuote = (room) => {
    const widthInMeters = parseFloat(Math.abs(room.width) / PIXELS_PER_METER) || 0;
    const lengthInMeters = parseFloat(Math.abs(room.length) / PIXELS_PER_METER) || 0;
    const heightInMeters = parseFloat(roomHeight) || 0;
    const wallArea = 2 * (widthInMeters + lengthInMeters) * heightInMeters || 0; // Total wall area
    const wallPerimeter = 2 * (widthInMeters + lengthInMeters) || 0; // Total wall perimeter
    const floorArea = widthInMeters * lengthInMeters || 0; // Floor area

    const costPerHour = 30; // Assume a fixed cost per hour for the painter
    const paintPricePerLiter = 15;
    const prepCostPerMeter = 3;
    const skirtingArchitraveCostPerMeter = 3;

    const paintingHours = wallArea / metersSquared || 0; // Calculate the hours required to paint the wall area
    const paintingCost = paintingHours * costPerHour || 0;
    const paintRequired = wallArea / coverage || 0;
    const paintCost = paintRequired * paintPricePerLiter || 0;
    const prepCost = wallPerimeter * prepCostPerMeter || 0;
    const skirtingArchitraveCost = wallPerimeter * skirtingArchitraveCostPerMeter || 0;

    const totalCost = paintingCost + paintCost + prepCost + skirtingArchitraveCost || 0;

    return {
      ...room,
      widthInMeters: widthInMeters.toFixed(2),
      lengthInMeters: lengthInMeters.toFixed(2),
      floorArea: floorArea.toFixed(2),
      wallArea: wallArea.toFixed(2),
      wallPerimeter: wallPerimeter.toFixed(2),
      paintingHours: paintingHours.toFixed(2),
      paintingCost: paintingCost.toFixed(2),
      paintRequired: paintRequired.toFixed(2),
      paintCost: paintCost.toFixed(2),
      prepCost: prepCost.toFixed(2),
      skirtingArchitraveCost: skirtingArchitraveCost.toFixed(2),
      totalCost: totalCost.toFixed(2),
    };
  };

  const quotes = rooms.map(calculateQuote);
  const totalFloorArea = quotes.reduce((sum, quote) => sum + parseFloat(quote.floorArea), 0).toFixed(2);
  const totalWallArea = quotes.reduce((sum, quote) => sum + parseFloat(quote.wallArea), 0).toFixed(2);
  const totalWallPerimeter = quotes.reduce((sum, quote) => sum + parseFloat(quote.wallPerimeter), 0).toFixed(2);
  const totalPaintingHours = quotes.reduce((sum, quote) => sum + parseFloat(quote.paintingHours), 0).toFixed(2);
  const totalPaintingCost = quotes.reduce((sum, quote) => sum + parseFloat(quote.paintingCost), 0).toFixed(2);
  const totalPaintRequired = quotes.reduce((sum, quote) => sum + parseFloat(quote.paintRequired), 0).toFixed(2);
  const totalPaintCost = quotes.reduce((sum, quote) => sum + parseFloat(quote.paintCost), 0).toFixed(2);
  const totalPrepCost = quotes.reduce((sum, quote) => sum + parseFloat(quote.prepCost), 0).toFixed(2);
  const totalSkirtingArchitraveCost = quotes.reduce((sum, quote) => sum + parseFloat(quote.skirtingArchitraveCost), 0).toFixed(2);
  const totalCost = quotes.reduce((sum, quote) => sum + parseFloat(quote.totalCost), 0).toFixed(2);

  useEffect(() => {
    if (selectedRoomId) {
      const room = rooms.find((r) => r.id === selectedRoomId);
      if (room) {
        setRoomName(room.name);
        setRoomLength(Math.abs(room.length / PIXELS_PER_METER).toFixed(2));
        setRoomWidth(Math.abs(room.width / PIXELS_PER_METER).toFixed(2));
        setRoomHeight(roomHeight);
        setPaintBrand(room.paintBrand || '');
        setPaintHue(room.paintHue || '');
        setPaintColor(room.paintColor || '');
        setSkirtingBoards(room.skirtingBoards || false);
        setArchitrave(room.architrave || false);
        setWindows(room.windows || false);
        setDoors(room.doors || false);
        setCeiling(room.ceiling || false);
        setPaintFinish(room.paintFinish || '');
      }
    }
  }, [selectedRoomId, rooms, roomHeight]);

  const handleRoomChange = (updatedRoom) => {
    setRooms((prevRooms) =>
      prevRooms.map((room) => (room.id === updatedRoom.id ? updatedRoom : room))
    );
  };
  
  const handleIconClick = (panel, tab) => {
    setActivePanel(panel);

    // ALWAYS update tabIndex if a tab is provided
    if (tab !== undefined) {
      setTabIndex(tab);
    }

    // Update background image based on the clicked icon's tab index
    const backgroundImageKey = [
      'floorplan',
      'palette',
      'designer',
      'report',
      'quote',
      'schedule',
      'contract',
      'invoicing',
      'mapping',
    ][tab] || 'floorplan';
    setBackgroundImage(backgroundImages[backgroundImageKey]);
  };

  return (
    <div className="container" style={{ backgroundImage: backgroundImage, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      {/* <VariablePanel variables={appState} isOpen={isPanelOpen} togglePanel={togglePanel} /> */}
      <div className="background-container">
        <div className="column icon-column">
          <IconColumn activePanel={activePanel} handleIconClick={handleIconClick} />
        </div>
        <div className="column panel-column">
          {activePanel === 'accountPanel' && (
            <AccountPanel
              companyName={companyName}
              setCompanyName={setCompanyName}
              companyNumber={companyNumber}
              setCompanyNumber={setCompanyNumber}
              vatNumber={vatNumber}
              setVatNumber={setVatNumber}
              streetAddress={streetAddress}
              setStreetAddress={setStreetAddress}
              city={city}
              setCity={setCity}
              state={state}
              setState={setState}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              country={country}
              setCountry={setCountry}
              logo={logo}
              setLogo={setLogo}
              email={email}
              setEmail={setEmail}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              whatsApp={whatsApp}
              setWhatsApp={setWhatsApp}
              website={website}
              setWebsite={setWebsite}
              socialMedia={socialMedia}
              setSocialMedia={setSocialMedia}
              primaryContactName={primaryContactName}
              setPrimaryContactName={setPrimaryContactName}
              primaryContactPosition={primaryContactPosition}
              setPrimaryContactPosition={setPrimaryContactPosition}
              secondaryContactName={secondaryContactName}
              setSecondaryContactName={setSecondaryContactName}
              secondaryContactPhone={secondaryContactPhone}
              setSecondaryContactPhone={setSecondaryContactPhone}
              industryType={industryType}
              setIndustryType={setIndustryType}
              businessType={businessType}
              setBusinessType={setBusinessType}
              preferredContactMethod={preferredContactMethod}
              setPreferredContactMethod={setPreferredContactMethod}
              invoiceNotes={invoiceNotes}
              setInvoiceNotes={setInvoiceNotes}
              invoiceFormatting={invoiceFormatting}
              setInvoiceFormatting={setInvoiceFormatting}
              invoiceStartNum={invoiceStartNum}
              setInvoiceStartNum={setInvoiceStartNum}
              invoicePrefix={invoicePrefix}
              setInvoicePrefix={setInvoicePrefix}
              invoiceTemplate={invoiceTemplate}
              setInvoiceTemplate={setInvoicePrefix}
              invoiceCurrency={invoiceCurrency}
              setInvoiceCurrency={setInvoiceCurrency}
              invoiceTaxRate={invoiceTaxRate}
              setInvoiceTaxRate={setInvoiceTaxRate}
              invoiceDiscounts={invoiceDiscounts}
              setInvoiceDiscounts={setInvoiceDiscounts}
              paymentTerms={paymentTerms}
              setPaymentTerms={setPaymentTerms}
              customerReferencePO={customerReferencePO}
              setCustomerReferencePO={setCustomerReferencePO}
              invoicePaymentInstructions={invoicePaymentInstructions}
              setInvoicePaymentInstructions={setInvoicePaymentInstructions}
              latePaymentFees={latePaymentFees}
              setLatePaymentFees={setLatePaymentFees}
              facebook={facebook}
              setFacebook={setFacebook}
              instagram={instagram}
              setInstagram={setInstagram}
              twitter={twitter}
              setTwitter={setTwitter}
              pinterest={pinterest}
              setPinterest={setPinterest}
              creditLimit={creditLimit}
              setCreditLimit={setCreditLimit}
              isActive={isActive}
              setIsActive={setIsActive}
              sortCode={sortCode}
              setSortCode={setSortCode}
              accountNumber={accountNumber}
              setAccountNumber={setAccountNumber}
              tags={tags}
              setTags={setTags}
            />
          )}
          {activePanel === 'clientPanel' && (
            <ClientPanel
              clientFullName={clientFullName}
              setClientFullName={setClientFullName}
              clientEmail={clientEmail}
              setClientEmail={setClientEmail}
              clientPhone={clientPhone}
              setClientPhone={setClientPhone}
              clientStreetAddress={clientStreetAddress}
              setClientStreetAddress={setClientStreetAddress}
              clientCity={clientCity}
              setClientCity={setClientCity}
              clientState={clientState}
              setClientState={setClientState}
              clientPostalCode={clientPostalCode}
              setClientPostalCode={setClientPostalCode}
              clientCountry={clientCountry}
              setClientCountry={setClientCountry}
              clientPhoto={clientPhoto}
              setClientPhoto={setClientPhoto}
              setCurrentSelectedClient={setCurrentSelectedClient}
            />
          )}
          {activePanel === 'contractorPanel' && (
            <ContractorPanel
              contractorFullName={contractorFullName}
              setContractorFullName={setContractorFullName}
              contractorEmail={contractorEmail}
              setClientEmail={setContractorEmail}
              contractorPhone={contractorPhone}
              setClientPhone={setContractorPhone}
              contractorPhoto={contractorPhoto}
              setClientPhoto={setContractorPhoto}
              contractorType={contractorType}
              setContractorType={setContractorType}
              contractors={contractors}
              setContractors={setContractors}
            />
          )}
          {activePanel === 'paintOptions' && (
            <PaintOptions
              style={style}
              setStyle={setStyle}
              coverage={coverage}
              setCoverage={setCoverage}
              metersSquared={metersSquared}
              setMetersSquared={setMetersSquared}
              roomHeight={roomHeight}
              setRoomHeight={setRoomHeight}
              wallCoats={wallCoats}
              setWallCoats={setWallCoats}
              roomPrep={roomPrep}
              setRoomPrep={setRoomPrep}
              color={color}
              setColor={setColor}
              paintWalls={paintWalls}
              setPaintWalls={setPaintWalls}
              paintCeiling={paintCeiling}
              setPaintCeiling={setPaintCeiling}
              paintSkirtingArchitrave={paintSkirtingArchitrave}
              setPaintSkirtingArchitrave={setPaintSkirtingArchitrave}
              paintDoors={paintDoors}
              setPaintDoors={setPaintDoors}
              paintWindows={paintWindows}
              setPaintWindows={setPaintWindows}
              undercoatPrimer = {undercoatPrimer}
              setUndercoatPrimer = {setUndercoatPrimer}
            />
          )}
          {activePanel === 'colourOptions' && (
            <ColourOptions
              style={style}
              setStyle={setStyle}
              coverage={coverage}
              setCoverage={setCoverage}
              metersSquared={metersSquared}
              setMetersSquared={setMetersSquared}
              roomHeight={roomHeight}
              setRoomHeight={setRoomHeight}
              wallCoats={wallCoats}
              setWallCoats={setWallCoats}
              roomPrep={roomPrep}
              setRoomPrep={setRoomPrep}
              color={color}
              setColor={setColor}
              rooms={rooms}
              setRooms={setRooms}
              selectedRoomId={selectedRoomId}
              setSelectedRoomId={setSelectedRoomId}
              paintBrand={paintBrand}
              setPaintBrand={setPaintBrand}
              paintHue={paintHue}
              setPaintHue={setPaintHue}
              setSelectedColor={setSelectedColor}
              setColorChartPath={setColorChartPath}
              selectedWall={selectedWall}
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
            />
          )}
          {activePanel === 'inspectionPanel' && (
             <InspectionPanel
              companyName={companyName}
              setInspectionReport={setInspectionReports}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              selectedRoom={selectedRoom}
              setSelectedRoom={setSelectedRoom}
              clientFullName={clientFullName}
              clientEmail={clientEmail}
              clientStreetAddress={clientStreetAddress}
              clientCity={clientCity}
              clientState={clientState}
              clientPostalCode={clientPostalCode}
              clientCountry={clientCountry}
              logo={logo}
              setLogo={setLogo}
              numInvoices={1}
            />
          )}

          {activePanel === 'JobOptions' && (
            <JobOptions
              handleIconClick={handleIconClick}
              rooms={rooms}
              setRooms={setRooms}
              newRoom={newRoom}
              setNewRoom={setNewRoom}
              selectedRoomId={selectedRoomId}
              setSelectedRoomId={setSelectedRoomId}
              selectedColor={selectedColor}
              numFloors={numFloors}
              setNumFloors={setNumFloors}
            />
          )}
          {activePanel === 'roomOptions' && (
            <RoomOptions
              roomName={roomName}
              setRoomName={setRoomName}
              roomLength={roomLength}
              setRoomLength={setRoomLength}
              roomWidth={roomWidth}
              setRoomWidth={setRoomWidth}
              paintBrandRoom={paintBrandRoom}
              setPaintBrandRoom={setPaintBrandRoom}
              paintHueRoom={paintHueRoom}
              setPaintHueRoom={setPaintHueRoom}
              paintColor={paintColor}
              setPaintColor={setPaintColor}
              skirtingBoards={skirtingBoards}
              setSkirtingBoards={setSkirtingBoards}
              architrave={architrave}
              setArchitrave={setArchitrave}
              windows={windows}
              setWindows={setWindows}
              doors={doors}
              setDoors={setDoors}
              ceiling={ceiling}
              setCeiling={setCeiling}
              paintFinish={paintFinish}
              setPaintFinish={setPaintFinish}
              style={style}
              handleStyleChange={handleStyleChange}
              rooms={rooms}
              setRooms={setRooms}
              selectedRoomId={selectedRoomId}
              selectedColor={selectedColor}
              setSelectedImageUrl={setSelectedImageUrl}
            />
          )}

          {activePanel === 'schedulePanel' && (
            <SchedulePanel
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          )}
          {activePanel === 'quotePanel' && (
            <QuotePanel
              totalCost={totalCost}
              invoicesState={invoicesState}
              setInvoicesState={setInvoicesState}
              setNumInvoices={setNumInvoices}
            />
          )}
          {activePanel === 'invoicePanel' && (
            <InvoicePanel
              totalCost={totalCost}
              invoicesState={invoicesState}
              setInvoicesState={setInvoicesState}
              setNumInvoices={setNumInvoices}
              billingOptions={billingOptions}
              setBillingOptions={setBillingOptions}
              invoiceDetail={invoiceDetail}
              setInvoiceDetail={setInvoiceDetail}
              labourCost={labourCost}
              setLabourCost={setLabourCost}
              materialCost={materialCost}
              setMaterialCost={setMaterialCost}
              paymentTerms={paymentTerms}
              setPaymentTerms={setPaymentTerms}
              latePaymentFee={latePaymentFee}
              setLatePaymentFee={setLatePaymentFee}
              customerReference={customerReference}
              setCustomerReference={setCustomerReference}
              paymentInstructions={paymentInstructions}
              setPaymentInstructions={setPaymentInstructions}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
            />
          )}
          {activePanel === 'contractPanel' && (
            <ContractPanel />
          )}
          {activePanel === 'mapPanel' && (
            <MapPanel
              startPostcode={startPostcode}
              setStartPostcode={setStartPostcode}
              endPostcode={endPostcode}
              setEndPostcode={setEndPostcode}
            />
          )}
        </div>
      </div>
      <div className="column main-column">
        <div className="tabs-container">
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="floorplan and quote tabs" sx={theme.tabs.root}>
            <Tab
              label="Floorplan"
              sx={{
                ...theme.tabs.tab.base,
                ...(tabIndex === 0 && theme.tabs.tab.selected),
              }}
            />
            {/* <Tab label="3D view" sx={{ fontWeight: 'bold', fontSize: '1.1rem', fontFamily: '"Figtree", sans-serif'  }}/> */}
            <Tab
              label="Colour"
              sx={{
                ...theme.tabs.tab.base,
                ...(tabIndex === 1 && theme.tabs.tab.selected),
              }}
            />
            <Tab
              label="Designer"
              sx={{
                ...theme.tabs.tab.base,
                ...(tabIndex === 2 && theme.tabs.tab.selected),
              }}
            />
            <Tab
              label="Report"
              sx={{
                ...theme.tabs.tab.base,
                ...(tabIndex === 3 && theme.tabs.tab.selected),
              }}
            />
            <Tab
              label="Quote"
              sx={{
                ...theme.tabs.tab.base,
                ...(tabIndex === 4 && theme.tabs.tab.selected),
              }}
            />
            <Tab
              label="Schedule"
              sx={{
                ...theme.tabs.tab.base,
                ...(tabIndex === 5 && theme.tabs.tab.selected),
              }}
            />
            <Tab
              label="Contract"
              sx={{
                ...theme.tabs.tab.base,
                ...(tabIndex === 6 && theme.tabs.tab.selected),
              }}
            />
            <Tab
              label="Invoicing"
              sx={{
                ...theme.tabs.tab.base,
                ...(tabIndex === 7 && theme.tabs.tab.selected),
              }}
            />
            <Tab
              label="Directions"
              sx={{
                ...theme.tabs.tab.base,
                ...(tabIndex === 8 && theme.tabs.tab.selected),
              }}
            />
          </Tabs>
        </div>
        <div className="content-container">
          {tabIndex === 0 && (
            <FloorPlan
              currentJobID={currentJobID}
              currentFloorID={currentFloorID}
              setCurrentFloorID={setCurrentFloorID}
              rooms={rooms}
              setRooms={setRooms}
              newRoom={newRoom}
              setNewRoom={setNewRoom}
              selectedRoomId={selectedRoomId}
              setSelectedRoomId={setSelectedRoomId}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          )}
          {/* {tabIndex === 1 && (
              <ThreeDScene rooms={rooms || []} roomHeight={roomHeight} /> 
            )} */}
          {tabIndex === 1 && 
            <Palette colorChartPath={colorChartPath} 
            onSwatchClick={handleSwatchClick}
            colourAccuracy={colourAccuracy}
            setColourAccuracy={setColourAccuracy}
            colourMatches={colourMatches}
            setColourMatches={setColourMatches}
            />}
          {tabIndex === 2 && <AI />}
          {tabIndex === 3 && (
            <Inspection
              companyName={companyName}
              inspectionReports={inspectionReports}
              setInspectionReports={setInspectionReports}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setSelectedRoom={setSelectedRoom}
              clientFullName={clientFullName}
              clientEmail={clientEmail}
              clientStreetAddress={clientStreetAddress}
              clientCity={clientCity}
              clientState={clientState}
              clientPostalCode={clientPostalCode}
              clientCountry={clientCountry}
              logo={logo}
              setLogo={setLogo}
              numInvoices={1} // Or replace this with the correct prop if needed
            />
          )}
          {tabIndex === 4 && (
            <QuoteTable
              quotes={quotes}
              totals={{
                totalFloorArea,
                totalWallArea,
                totalWallPerimeter,
                totalPaintingHours,
                totalPaintingCost,
                totalPaintRequired,
                totalPaintCost,
                totalPrepCost,
                totalSkirtingArchitraveCost,
                totalCost,
              }}
            />
          )}
          {tabIndex === 5 && <Schedule quoteData={quotes} schedule={schedule} setSchedule={setSchedule} />}
          {tabIndex === 6 && <Contract quoteData={quotes} schedule={schedule} contract={contract} setContract={setContract} />}
          {tabIndex === 7 && (
            <Invoicing
              logo={logo}
              clientFullName={clientFullName}
              clientStreetAddress={clientStreetAddress}
              clientCity={clientCity}
              clientState={clientState}
              clientPostalCode={clientPostalCode}
              clientCountry={clientCountry}
              numInvoices={numInvoices}
            />
          )}
          {tabIndex === 8 && <Mapping />}
        </div>
      </div>
    </div>
  );
};

export default App;
