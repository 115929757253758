// // src/hooks/withAuth.js
// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { checkAuthStatus } from '../utils/auth'; // Adjust the path to point to utils/auth

// const withAuth = (Component) => {
//   return (props) => {
//     const isAuthenticated = checkAuthStatus();
//     return isAuthenticated ? <Component {...props} /> : <Navigate to="/login" />;
//   };
// };

// export default withAuth;

// src/hooks/withAuth.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { checkAuthStatus, checkUserIdCookie } from '../utils/auth';

const withAuth = (Component) => {
  return (props) => {
    const isAuthenticated = checkAuthStatus();
    const hasUserIdCookie = checkUserIdCookie();
    
    return isAuthenticated && hasUserIdCookie ? <Component {...props} /> : <Navigate to="/login" />;
  };
};

export default withAuth;
