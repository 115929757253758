// utils.js

export const drawImageOnCanvas = (canvasRef, imageUrl, maxWidth) => {
    if (!canvasRef.current || !imageUrl) return;
  
    const ctx = canvasRef.current.getContext('2d');
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const canvasWidth = maxWidth;
      const canvasHeight = canvasWidth / aspectRatio;
      canvasRef.current.width = canvasWidth;
      canvasRef.current.height = canvasHeight;
      ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
    };
  };

  
   // Helper function to convert base64 to Blob
 export  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  // utils.js

export const rgbToHex = (rgb) => {
    const rgbValues = rgb.match(/\d+/g);
    return rgbValues ? `#${((1 << 24) + (parseInt(rgbValues[0]) << 16) + (parseInt(rgbValues[1]) << 8) + parseInt(rgbValues[2])).toString(16).slice(1)}` : '#FFFFFF';
  };
  
  export const luminance = (r, g, b) => {
    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  };
  
  export const contrastRatio = (rgb1, rgb2) => {
    const lum1 = luminance(...rgb1);
    const lum2 = luminance(...rgb2);
    return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
  };
  
  export const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };
  
  export const getContrastColor = (hexColor) => {
    const rgbColor = hexToRgb(hexColor);
    const white = [255, 255, 255];
    const black = [0, 0, 0];
    return contrastRatio(rgbColor, white) < 3 ? 'black' : 'white';
  };
  