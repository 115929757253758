// JobOptionsV2.js
import React, { useState, useCallback, useMemo } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { ListAlt, ViewQuilt, DomainAdd } from '@mui/icons-material';
import { NavToggleButtonGroup, NavToggleButton } from './CustomToggleButton'
import theme from './theme';

import { useJobState } from './hooks/Jobs/useJobState';

import JobList from './components/Jobs/JobList';
import NewJobForm from './components/Jobs/NewJobForm';
import FloorUpload from './components/Jobs/FloorUpload';
import PaintOptions from './components/Jobs/PaintOptions';

const JobOptionsV2 = ({
  style, setStyle, coverage, setCoverage, metersSquared, setMetersSquared,
  roomHeight, setRoomHeight, wallCoats, setWallCoats, roomPrep, setRoomPrep,
  numFloors, setNumFloors, color, setColor  
}) => {
  const [selectedOption, setSelectedOption] = useState('jobsList');
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentFloorplanID, setCurrentFloorplanID] = useState(null);
  const [currentFloorID, setCurrentFloorID] = useState(null);
  const [floorNames, setFloorNames] = useState([]);

  const [currentJobID, setCurrentJobID] = useState(null);
  
  

  const userId = useMemo(() => document.cookie.split('; ').find(row => row.startsWith('userId=')).split('=')[1], []);

  const {
    appData,
    currentJob,
    selectJob,
    createNewJob,
    updateExistingJob,
    deleteExistingJob,
    resetCurrentJob,
    fetchJobs
  } = useJobState(userId);

  const handleJobClick = useCallback((job) => {
    console.log('handleJobClick called with job:', job);
  
    // Select the job in the state
    selectJob(job._id);
    setSelectedOption('jobForm');
    setIsUpdating(true);
  
    // Get current appState from local storage
    const appState = JSON.parse(localStorage.getItem('appState')) || {};
    const appData = JSON.parse(localStorage.getItem('appData')) || {};
  
    // Find the job details from appData using the job._id
    const jobDetails = appData.jobs.find((item) => item._id === job._id);
  
    if (jobDetails) {
      // Update the currentJobID and currentFloorplanID in appState
      appState.currentJobID = job._id;
      appState.currentFloorplanID = jobDetails.FloorPlanID?._id || null;
  
      // Update state variables
      setCurrentJobID(job._id);
  
      // Set currentFloorID to the first floor ID in the floor plan if available
      if (jobDetails.FloorPlanID && jobDetails.FloorPlanID.Floors && jobDetails.FloorPlanID.Floors.length > 0) {
        appState.currentFloorID = jobDetails.FloorPlanID.Floors[0]._id;
        setCurrentFloorID(jobDetails.FloorPlanID.Floors[0]._id);
      } else {
        appState.currentFloorID = null;
        setCurrentFloorID(null);
      }
  
      // Save the updated appState back to local storage
      localStorage.setItem('appState', JSON.stringify(appState));
    } else {
      console.error('Job details not found in appData for job ID:', job._id);
    }
  }, [selectJob]);
  
  
  

  const handleOptionChange = useCallback((event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
      setIsUpdating(false);
    }
  }, []);

  const handleCreateNewJob = useCallback(() => {
    setSelectedOption('jobForm');
    setIsUpdating(false);
    // resetCurrentJob();
    // setCurrentFloorplanID(null);
    // setCurrentFloorID(null);
    // setFloorNames([]);
  }, []);

  const onJobCreated = useCallback(async (jobData) => {
    try {
      const createdJob = await createNewJob(jobData); // call directly
  
      setSelectedOption('jobsList');
      
      if (createdJob.FloorPlanID) {
        setCurrentFloorplanID(createdJob.FloorPlanID._id);
        if (createdJob.FloorPlanID.Floors && createdJob.FloorPlanID.Floors.length > 0) {
          setCurrentFloorID(createdJob.FloorPlanID.Floors[0]._id); // Select the first floor by default
          setFloorNames(createdJob.FloorPlanID.Floors.map(floor => floor.Name)); // Set floor names
        }
      }
  
      fetchJobs(); // Refresh the job list
    } catch (error) {
      console.error('Error creating job:', error);
    }
  }, [createNewJob, fetchJobs]);
  

  const onJobUpdated = useCallback(async (jobId, jobData) => {
    console.log('onJobUpdated called with jobId:', jobId);
    try {
      const updatedJob = await updateExistingJob(jobId, jobData);
      console.log('Job updated, switching to jobsList');
      setSelectedOption('jobsList');
      if (updatedJob.FloorPlanID) {
        setCurrentFloorplanID(updatedJob.FloorPlanID._id);
        if (updatedJob.FloorPlanID.Floors && updatedJob.FloorPlanID.Floors.length > 0) {
          setCurrentFloorID(updatedJob.FloorPlanID.Floors[0]._id);
          setFloorNames(updatedJob.FloorPlanID.Floors.map(floor => floor.Name));
        }
      }
      await fetchJobs(userId);
      console.log('Jobs fetched after update');
    } catch (error) {
      console.error('Error updating job in JobOptionsV2:', error);
    }
  }, [updateExistingJob, fetchJobs, userId]);

  const handleDeleteJob = useCallback(async (jobId) => {
    try {
      await deleteExistingJob(jobId);
      setSelectedOption('jobsList');
      fetchJobs();
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  }, [deleteExistingJob, fetchJobs]);

  const renderJobOptions = () => {
    switch (selectedOption) {
      case 'jobsList':
        return <JobList jobs={appData.jobs} onJobClick={handleJobClick} onCreateNewJob={handleCreateNewJob} />;
      case 'jobForm':
        return (
          <NewJobForm
                jobData={currentJob}
                isUpdating={isUpdating}
                clients={appData.clients}
                onJobCreated={onJobCreated}  // Passed to NewJobForm
                onJobUpdated={onJobUpdated}  // Passed to NewJobForm
                onDeleteJob={handleDeleteJob} // Deleting jobs
                userId={userId} // User ID to associate with the job
                setCurrentFloorplanID={setCurrentFloorplanID} // Floor plan management
                setCurrentFloorID={setCurrentFloorID} // Current floor management
                setFloorNames={setFloorNames} // Floor names management
              />

        );
      case 'uploadFloorplans':
        return <FloorUpload numFloors={numFloors} setNumFloors={setNumFloors} />;
      case 'paint':
        return (
          <PaintOptions
            coverage={coverage} setCoverage={setCoverage}
            metersSquared={metersSquared} setMetersSquared={setMetersSquared}
            roomHeight={roomHeight} setRoomHeight={setRoomHeight}
            wallCoats={wallCoats} setWallCoats={setWallCoats}
            roomPrep={roomPrep} setRoomPrep={setRoomPrep}
            color={color} setColor={setColor}
          />
        );
      default:
        return null;
    }
  };

  if (!appData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
      <Box my={0}>
        <NavToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth>
          <NavToggleButton value="jobsList"><ListAlt /></NavToggleButton>
          <NavToggleButton value="jobForm"><ViewQuilt /></NavToggleButton>
          <NavToggleButton value="uploadFloorplans"><DomainAdd /></NavToggleButton>
        </NavToggleButtonGroup>
      </Box>
      {renderJobOptions()}
    </Box>
  );
}

export default JobOptionsV2;
