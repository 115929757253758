import React from 'react';
import {TextField, Box, InputAdornment, Button, Avatar,
} from '@mui/material';
import { Check,Public, Instagram, Twitter, Facebook, Pinterest } from '@mui/icons-material';
import theme from '../../theme';

const AccountSocial = ({
  accountState,
  handleInputChange,
  handleSocialMediaChange,
  handleSubmit,
  updateSuccess,
  isUpdating,
  handleImageUpload,
}) => {
  return (
    <Box sx={theme.customFormBox}>
      <Box sx={theme.customLogoBox} onClick={() => document.getElementById('company-logo-input').click()}>
        <Box sx={theme.customLogoGreyBox} />
        <Avatar
          sx={theme.customLogoAvatar}
          src={accountState.logo || '/images/avatars/painter.webp'}
          onClick={(e) => {
            e.stopPropagation();
            document.getElementById('company-logo-input').click();
          }}
        />
      </Box>

      <input
        id="company-logo-input"
        type="file"
        hidden
        onChange={handleImageUpload}
      />

      <Box sx={{ ...theme.customFormBox }}>
        <TextField
          label="Website"
          value={accountState.website}
          onChange={(e) => handleInputChange('website', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Public />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Instagram"
          value={accountState.socialMedia.instagram}
          onChange={(e) => handleSocialMediaChange('instagram', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Instagram />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Twitter"
          value={accountState.socialMedia.twitter}
          onChange={(e) => handleSocialMediaChange('twitter', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Twitter />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Facebook"
          value={accountState.socialMedia.facebook}
          onChange={(e) => handleSocialMediaChange('facebook', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Facebook />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Pinterest"
          value={accountState.socialMedia.pinterest}
          onChange={(e) => handleSocialMediaChange('pinterest', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Pinterest />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color={updateSuccess ? 'success' : 'primary'}
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
          disabled={isUpdating}
        >
          {isUpdating
            ? 'Updating...'
            : updateSuccess
            ? 'Account Updated'
            : 'Update Details'}
          {updateSuccess && <Check style={{ marginLeft: '4px' }} />}
        </Button>
      </Box>
    </Box>
  );
};

export default AccountSocial;
