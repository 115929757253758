import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { AddCardOutlined, AccountBalanceOutlined,LocalAtmOutlined, TuneOutlined, Archive, ManageHistory, Timeline, Settings, ViewSidebar, Reorder, HorizontalSplit, IndeterminateCheckBox, FormatAlignJustify } from '@mui/icons-material';
import { Stage, Layer, Text, Path } from 'react-konva';
import { 
  NavToggleButtonGroup, 
  NavToggleButton, 
  InPageToggleButtonGroup, 
  InPageToggleButton 
} from './CustomToggleButton'
import { ViewStream, TableRows, ViewDay, ViewWeek, ViewComfy } from '@mui/icons-material';
import CustomTooltip from './components/CustomTooltip';
import './InvoicePanel.css';
import theme from './theme';

const FIXED_HEIGHT = 550;
const PADDING = 30;

const splitOptions = {
  1: [
    { name: '100', splits: [100], icon: <IndeterminateCheckBox /> }
  ],
  2: [
    { name: '50-50', splits: [50, 50], icon: <ViewStream /> },
    { name: '60-40', splits: [60, 40], icon: <ViewWeek /> },
    { name: '70-30', splits: [70, 30], icon: <ViewComfy /> }
  ],
  3: [
    { name: '33-33-34', splits: [33, 33, 34], icon: <TableRows /> },
    { name: '25-25-50', splits: [25, 25, 50], icon: <HorizontalSplit /> },
    { name: '25-50-25', splits: [25, 50, 25], icon: <ViewDay /> }
  ],
  4: [
    { name: '25-25-25-25', splits: [25, 25, 25, 25], icon: <Reorder /> },
    { name: '40-20-20-20', splits: [40, 20, 20, 20], icon: <ViewSidebar /> }
  ],
  5: [
    { name: '20-20-20-20-20', splits: [20, 20, 20, 20, 20], icon: <FormatAlignJustify /> }
  ]
};


const InvoicePanel = ({ totalCost, 
  invoicesState, 
  setInvoicesState, 
  setNumInvoices,
  billingOptions, setBillingOptions,
  invoiceDetail, setInvoiceDetail,
  labourCost, setLabourCost,
  materialCost, setMaterialCost,
  paymentTerms, setPaymentTerms,
  latePaymentFee, setLatePaymentFee,
  customerReference, setCustomerReference,
  paymentInstructions, setPaymentInstructions,
  paymentType, setPaymentType }) => {
  const containerRef = useRef(null);
  const [numInvoices, setNumInvoicesState] = useState(invoicesState.length);
  const [selectedSplit, setSelectedSplit] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [activeOption, setActiveOption] = useState('breakdown');

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (numInvoices === 1) {
      setInvoicesState([{ id: 1, percentage: 100 }]);
      setSelectedSplit({ name: '100', splits: [100] });
    } else {
      const defaultSplit = splitOptions[numInvoices]?.[0];
      if (defaultSplit) {
        handleSplitSelection(defaultSplit);
      }
    }
  }, [numInvoices]);

  const handleSplitSelection = (splitOption) => {
    setSelectedSplit(splitOption);
    const newInvoices = splitOption.splits.map((percentage, index) => ({
      id: index + 1,
      percentage: percentage
    }));
    setInvoicesState(newInvoices);
  };

  const handleInvoiceClick = (index) => {
    setSelectedInvoice(index === selectedInvoice ? null : index);
  };

  const drawRoundedRect = (x, y, width, height, radius) => {
    return `
      M ${x + radius} ${y}
      L ${x + width - radius} ${y}
      Q ${x + width} ${y} ${x + width} ${y + radius}
      L ${x + width} ${y + height - radius}
      Q ${x + width} ${y + height} ${x + width - radius} ${y + height}
      L ${x + radius} ${y + height}
      Q ${x} ${y + height} ${x} ${y + height - radius}
      L ${x} ${y + radius}
      Q ${x} ${y} ${x + radius} ${y}
      Z
    `;
  };

  const InvoiceText = ({ x, y, text, index }) => (
    <Text
      x={x}
      y={y+15}
      text={text}
      fontSize={16}
      fill="black"
      align="center"
      width={containerWidth * 0.95 - 40}
      offsetX={(containerWidth * 0.95 - 40) / 2}
    />
  );

  console.log(billingOptions)

  return (
    <Box p={0} borderRadius="0px" sx={{ backgroundColor: 'rgba(255, 255, 255, 0)', backdropFilter: 'blur(5px)', WebkitBackdropFilter: 'blur(5px)' }}>
      <Box my={0}>
        <NavToggleButtonGroup
          value={activeOption}
          exclusive
          onChange={(e, val) => {
            if (val) setActiveOption(val);
          }}
          fullWidth
        >
          <CustomTooltip title="Invoice Settings"><NavToggleButton value="settings"><Settings /></NavToggleButton></CustomTooltip>
          <CustomTooltip title="Invoice Breakdown"><NavToggleButton value="breakdown"><TuneOutlined /></NavToggleButton></CustomTooltip>
          <CustomTooltip title="Invoice Status"><NavToggleButton value="status"><Timeline /></NavToggleButton></CustomTooltip>
          <CustomTooltip title="Invoice History"><NavToggleButton value="history"><ManageHistory /></NavToggleButton></CustomTooltip>
          <CustomTooltip title="Invoice Archive"><NavToggleButton value="archive"><Archive /></NavToggleButton></CustomTooltip>
        </NavToggleButtonGroup>
      </Box>

   
       {activeOption === 'settings' && (
        <Box sx={{...theme.innerBox, backgroundColor: 'rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(150px)', WebkitBackdropFilter: 'blur(150px)',}}>
        {/* <Box sx={{ mb: 0, p: 1, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.2)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backdropFilter: 'blur(15px)', WebkitBackdropFilter: 'blur(15px)', }}> */}
        <Box sx={{ mb: 3, p: 2, border: '2px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)', backdropFilter: 'blur(150px)', WebkitBackdropFilter: 'blur(150px)', }}>
        {/* Billing Options */}
        
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Typography  variant="subtitle1"sx={{fontWeight: '700',fontSize: '1.1rem',fontFamily: '"Figtree", sans-serif', paddingLeft: '8px'}}>Billing Options</Typography>
          </Box>
          <InPageToggleButtonGroup exclusive fullWidth value={billingOptions} onChange={(e, val) => setBillingOptions(val)}>
              
              <InPageToggleButton value="hourly" >Hourly</InPageToggleButton>
              <InPageToggleButton value="daily" >Day</InPageToggleButton>
              <InPageToggleButton value="auto" >Auto</InPageToggleButton>
          </InPageToggleButtonGroup>

        
      
        {/* Level of Detail */}
        <Box sx={{ mt: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography  variant="subtitle1"sx={{fontWeight: '700',fontSize: '1.1rem',fontFamily: '"Figtree", sans-serif', paddingLeft: '8px'}}>Level of Detail</Typography>
          </Box>
          <InPageToggleButtonGroup exclusive fullWidth value={invoiceDetail} onChange={(e, val) => setInvoiceDetail(val)}>
            <InPageToggleButton value="low">Low</InPageToggleButton>
            <InPageToggleButton value="medium">Medium</InPageToggleButton>
            <InPageToggleButton value="high">High</InPageToggleButton>
          </InPageToggleButtonGroup>
          </Box>
       
      
        {/* Show Contractor Labour Cost */}
        <Box sx={{ mt: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Typography  variant="subtitle1"sx={{fontWeight: '700',fontSize: '1.1rem',fontFamily: '"Figtree", sans-serif', paddingLeft: '8px'}}>Labour Costs</Typography>
          </Box>
          <InPageToggleButtonGroup exclusive fullWidth value={labourCost} onChange={(e, val) => setLabourCost(val)}>
            <InPageToggleButton value="hide">Hide</InPageToggleButton>
            <InPageToggleButton value="show">Show</InPageToggleButton>
          </InPageToggleButtonGroup>
        </Box>
      
        {/* Show Material Cost */}
        <Box sx={{ mt: 3, mb:1.5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography  variant="subtitle1" sx={{fontWeight: '700',fontSize: '1.1rem',fontFamily: '"Figtree", sans-serif', paddingLeft: '8px'}}>Material Costs</Typography>
          </Box>
          <InPageToggleButtonGroup exclusive fullWidth value={materialCost} onChange={(e, val) => setMaterialCost(val)}>
            <InPageToggleButton value="hide">Hide</InPageToggleButton>
            <InPageToggleButton value="show">Show</InPageToggleButton>
          </InPageToggleButtonGroup>
        </Box>
        </Box>

        {/* Payment Terms */}
        <Box sx={{ mt: 3 }}>
        <Box sx={{ mb: 3, p: 2, border: '2px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)', backdropFilter: 'blur(150px)', WebkitBackdropFilter: 'blur(150px)', }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Typography  variant="subtitle1"sx={{fontWeight: '700',fontSize: '1.1rem',fontFamily: '"Figtree", sans-serif', paddingLeft: '8px'}}>Payment Terms</Typography>
          </Box>
          
          <InPageToggleButtonGroup exclusive fullWidth value={paymentTerms} onChange={(e, val) => setPaymentTerms(val)}>
              <InPageToggleButton value="net30">Net 30</InPageToggleButton>
              <InPageToggleButton value="net60">Net 60</InPageToggleButton>
              <InPageToggleButton value="net90">Net 90</InPageToggleButton>
            </InPageToggleButtonGroup>
      
      
          <Box sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Typography  variant="subtitle1" sx={{fontWeight: '700',fontSize: '1.1rem',fontFamily: '"Figtree", sans-serif', paddingLeft: '8px'}}>Late Payment Fee (%)</Typography>
          </Box>
            <input type="number" placeholder="Enter late payment fee" style={{ width: '90%', padding: '12px', borderRadius: '8px', border: '1px solid #111' }} />
          </Box>
      
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" sx={{fontWeight: '700',fontSize: '1.1rem',fontFamily: '"Figtree", sans-serif', paddingLeft: '8px'}}>Discount (%)</Typography>
            <input type="number" placeholder="Enter discount" style={{ width: '90%', padding: '12px', borderRadius: '8px', border: '1px solid #111' }} />
          </Box>
        </Box>
      </Box>
        {/* Customer Reference */}

        <Box sx={{ mb: 3, p: 2, border: '2px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)', backdropFilter: 'blur(150px)', WebkitBackdropFilter: 'blur(150px)', }}>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{fontWeight: '700',fontSize: '1.1rem',fontFamily: '"Figtree", sans-serif', paddingLeft: '8px'}}>Customer Reference</Typography>
          <input type="text" placeholder="Enter customer reference" style={{ width: '90%', padding: '12px', borderRadius: '8px', border: '1px solid #111' }}  />
        </Box>
      
        {/* Payment Instructions */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{fontWeight: '700',fontSize: '1.1rem',fontFamily: '"Figtree", sans-serif', paddingLeft: '8px'}}>Payment Instructions</Typography>
          <textarea placeholder="Enter payment instructions" style={{ width: '90%', padding: '12px', borderRadius: '8px', border: '1px solid #111' }}  rows={3} />
        </Box>
      
        {/* Payment Type */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{fontWeight: '700',fontSize: '1.1rem',fontFamily: '"Figtree", sans-serif', paddingLeft: '8px'}}>Payment Type</Typography>
          <InPageToggleButtonGroup exclusive fullWidth>
              <InPageToggleButton value="bank_transfer">
                <AccountBalanceOutlined />
              </InPageToggleButton>
              <InPageToggleButton value="credit_card">
                <AddCardOutlined />
              </InPageToggleButton>
              <InPageToggleButton value="paypal">
                <LocalAtmOutlined />
              </InPageToggleButton>
            </InPageToggleButtonGroup>
        </Box>
      </Box>
       </Box>
      
)}

      {activeOption === 'breakdown' && (
        <Box sx={{...theme.innerBox, backgroundColor: 'rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(150px)',WebkitBackdropFilter: 'blur(150px)',}}>
          <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold', margin: '5px 5px', color: 'black' }}>
              Number of Invoices
            </Typography>
            <InPageToggleButtonGroup 
              value={numInvoices} 
              exclusive 
              onChange={(e, val) => {
                if (val !== null) {
                  setNumInvoicesState(val);
                }
              }} 
              fullWidth 
              sx={{ mb: 3 }}
            >
              {[1, 2, 3, 4, 5].map(num => (
                <InPageToggleButton key={num} value={num}>{num}</InPageToggleButton>
              ))}
            </InPageToggleButtonGroup>

            {numInvoices > 0 && (
              <>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', margin: '5px 5px', color: 'black' }}>
                  Invoice Breakdown
                </Typography>
                <InPageToggleButtonGroup 
                  exclusive 
                  fullWidth
                  sx={{ mb: 1 }}
                  value={selectedSplit?.name}
                  onChange={(e, val) => {
                    const split = splitOptions[numInvoices]?.find(s => s.name === val);
                    if (split) handleSplitSelection(split);
                  }}
                >
                  {splitOptions[numInvoices]?.map(split => (
                    <CustomTooltip key={split.name} title={split.name}>
                      <InPageToggleButton value={split.name}>
                        {split.icon}
                      </InPageToggleButton>
                    </CustomTooltip>
                  ))}
                </InPageToggleButtonGroup>
              </>
            )}
          </Box>
          </Box>
        )}

        {activeOption === 'status' && (
          <Box sx={{ mb: 0, p: 1, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
            <Typography variant="h6">Invoice Status</Typography>
            <Typography>Status information will be displayed here.</Typography>
          </Box>
        )}

        {activeOption === 'history' && (
          <Box sx={{ mb: 0, p: 1, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
            <Typography variant="h6">Invoice History</Typography>
            <Typography>History details will be displayed here.</Typography>
          </Box>
        )}

        {activeOption === 'archive' && (
          <Box sx={{ mb: 0, p: 1, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
            <Typography variant="h6">Invoice Archive</Typography>
            <Typography>Archived invoices will be displayed here.</Typography>
          </Box>
        )}
        
        {activeOption === 'breakdown' && (
          <div className="konva-container" ref={containerRef} style={{ height: FIXED_HEIGHT }}>
            <Stage width={containerWidth * 0.95} height={FIXED_HEIGHT}>
              <Layer y={0}>
                {invoicesState.map((invoice, index) => {
                  const topY = invoicesState
                    .slice(0, index)
                    .reduce((acc, inv) => acc + (inv.percentage / 100) * (FIXED_HEIGHT - 2 * PADDING), 0);
                  const height = (invoice.percentage / 100) * (FIXED_HEIGHT - 2 * PADDING);
                  const rectPath = drawRoundedRect(20, topY, containerWidth * 0.95 - 40, height, 10);

                  return (
                    <React.Fragment key={index}>
                      <Path
                        data={rectPath}
                        fill={selectedInvoice === index ? 'lightblue' : 'rgba(250,250,250, 0.8)'}
                        stroke={selectedInvoice === index ? 'blue' : 'grey'}
                        strokeWidth={1}
                      />
                      <InvoiceText 
                        x={20 + (containerWidth * 0.95 - 40) / 2} 
                        y={topY + height / 2 - 45} 
                        text={`Invoice ${invoice.id}`} 
                        index={index} 
                      />
                      <InvoiceText 
                        x={20 + (containerWidth * 0.95 - 40) / 2} 
                        y={topY + height / 2 - 20} 
                        text={`${invoice.percentage}%`} 
                        index={index} 
                      />
                      <InvoiceText 
                        x={20 + (containerWidth * 0.95 - 40) / 2} 
                        y={topY + height / 2 + 5} 
                        text={`£${((invoice.percentage / 100) * totalCost).toFixed(2)}`} 
                        index={index} 
                      />
                    </React.Fragment>
                  );
                })}
              </Layer>
            </Stage>
          </div>
        )}
      </Box>

  );
};

export default InvoicePanel;
