import React, { useEffect } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { Light, CropDin, SelectAll, CropFree, MeetingRoomOutlined, DoorFront,Window } from '@mui/icons-material';
import CustomSlider from './CustomSlider';
import IOSSwitch from './CustomSwitch';
import { 
  NavToggleButtonGroup, 
  NavToggleButton, 
  InPageToggleButtonGroup, 
  InPageToggleButton 
} from './CustomToggleButton'
import theme from './theme'; 

// Tooltip for toggle buttons
const CustomTooltip = (props) => (
  <Tooltip
    {...props}
    PopperProps={{
      modifiers: [{
        name: 'offset',
        options: { offset: [0, 8] },
      }],
    }}
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          border: '2px solid white',
          borderRadius: '8px',
          fontSize: '18px',
          padding: '8px',
        },
      },
    }}
  />
);

const CustomToggleButtonWithTooltip = ({ title, value, icon }) => (
  <CustomTooltip title={title}>
    <NavToggleButton value={value}>{icon}</NavToggleButton>
  </CustomTooltip>
);

const PaintOptions = ({
  style, setStyle, coverage, setCoverage, metersSquared, setMetersSquared,
  roomHeight, setRoomHeight, wallCoats, setWallCoats, roomPrep, setRoomPrep,
  undercoatPrimer, setUndercoatPrimer, 
  paintWalls, setPaintWalls,
  paintCeiling, setPaintCeiling,
  paintSkirtingArchitrave, setPaintSkirtingArchitrave,
  paintDoors, setPaintDoors,
  paintWindows, setPaintWindows,
  currentJobId 
}) => {


      // Fetch the current jobId from localStorage in appState
  const getCurrentJobId = () => {
    const appState = JSON.parse(localStorage.getItem('appState'));
    return appState?.currentJobID || null;
  };

  const updateJobDetailsInLocalStorage = () => {
    const appData = JSON.parse(localStorage.getItem('appData')) || { jobs: [] };
    const appState = JSON.parse(localStorage.getItem('appState')) || {};
    const currentJobId = appState.currentJobID;
  
    console.log("Current jobId from appState:", currentJobId);
    console.log("Current paint settings:", { paintWalls, paintCeiling, paintSkirtingArchitrave, paintDoors, paintWindows });
  
    if (!currentJobId) {
      console.warn("jobId is undefined, cannot update job details in localStorage");
      return;
    }
  
    const jobIndex = appData.jobs.findIndex(job => job._id === currentJobId);
  
    if (jobIndex === -1) {
      console.warn("Job with the specified ID not found in appData.jobs");
      return;
    }
  
    // Set paint options at the top level of the job
    appData.jobs[jobIndex] = {
      ...appData.jobs[jobIndex],
      paintWalls,
      paintCeiling,
      paintSkirtingArchitrave,
      paintDoors,
      paintWindows
    };
  
    console.log("Updated job data to store in localStorage:", appData.jobs[jobIndex]);
  
    // Update localStorage
    localStorage.setItem('appData', JSON.stringify(appData));
  };
  
  useEffect(() => {
    updateJobDetailsInLocalStorage();
  }, [paintWalls, paintCeiling, paintSkirtingArchitrave, paintDoors, paintWindows]);
  

  // Handle slider and toggle changes
  const handleChange = (setter) => (event, value) => setter(value);

  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
      {/* Toggle buttons for paint options */}
      <Box my={0}>
        <NavToggleButtonGroup
          value={[
            paintWalls && "paintWalls", 
            paintCeiling && "paintCeiling", 
            paintWindows && "paintWindows",
            paintDoors && "paintDoors", 
            paintSkirtingArchitrave && "paintSkirtingArchitrave"
            
            
          ].filter(Boolean)}
          onChange={(event, newValues) => {
            setPaintWalls(newValues.includes("paintWalls"));
            setPaintCeiling(newValues.includes("paintCeiling"));
            setPaintWindows(newValues.includes("paintWindows"));
            setPaintDoors(newValues.includes("paintDoors"));
            setPaintSkirtingArchitrave(newValues.includes("paintSkirtingArchitrave"));
          }}
          fullWidth
          multiple
        >
          <CustomToggleButtonWithTooltip title="Walls Only" value="paintWalls" icon={<CropDin />} />
          <CustomToggleButtonWithTooltip title=" + Ceiling" value="paintCeiling" icon={<Light />} />
          <CustomToggleButtonWithTooltip title=" + Windows" value="paintWindows" icon={<Window />} />
          <CustomToggleButtonWithTooltip title=" + Doors" value="paintDoors" icon={<DoorFront />} />
          <CustomToggleButtonWithTooltip title=" + Skirting/Architrave" value="paintSkirtingArchitrave" icon={<MeetingRoomOutlined />} />
         
          
        </NavToggleButtonGroup>
      </Box>

      {/* Other paint-related options */}
      <Box sx={theme.innerBox}>
        <Box sx={{
          mb: 3,
          border: '1px solid #fff',
          borderRadius: '8px',
          p: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)'
        }}>
          <Typography variant="subtitle1" sx={theme.customToggleButton}>
            Paint Coverage - {coverage} sqm²
          </Typography>
          <CustomSlider
            value={coverage}
            onChange={handleChange(setCoverage)}
            valueLabelDisplay="auto"
            min={10}
            max={30}
          />

          <Typography variant="subtitle1" sx={theme.customToggleButton}>
            Productivity - {metersSquared}m²/hour
          </Typography>
          <CustomSlider
            value={metersSquared}
            onChange={handleChange(setMetersSquared)}
            valueLabelDisplay="auto"
            min={10}
            max={30}
          />

          <Typography variant="subtitle1" sx={theme.customToggleButton}>
            Room Height - {roomHeight}m
          </Typography>
          <CustomSlider
            value={roomHeight}
            onChange={handleChange(setRoomHeight)}
            valueLabelDisplay="auto"
            min={2.0}
            max={12.0}
            step={0.1}
          />

          {/* Undercoat/Primer switch */}
          <Box my={2}>
            <Typography variant="subtitle1" sx={theme.customToggleButton}>
              Undercoat/Primer
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="body1">No</Typography>
              <IOSSwitch
                checked={undercoatPrimer}
                onChange={(event) => setUndercoatPrimer(event.target.checked)}
                color="primary"
              />
              <Typography variant="body1">Yes</Typography>
            </Box>
          </Box>

          {/* Wall Coats selection */}
          <Box my={2}>
            <Typography variant="subtitle1" sx={theme.customToggleButton}>Wall Coats</Typography>
            <InPageToggleButtonGroup
              value={wallCoats}
              exclusive
              onChange={handleChange(setWallCoats)}
              fullWidth
            >
              <InPageToggleButton value="1" sx={theme.customToggleButton}>One</InPageToggleButton>
              <InPageToggleButton value="2" sx={theme.customToggleButton}>Two</InPageToggleButton>
              <InPageToggleButton value="3" sx={theme.customToggleButton}>Three</InPageToggleButton>
            </InPageToggleButtonGroup>
          </Box>

          {/* Room Preparation selection */}
          <Box my={2}>
            <Typography variant="subtitle1" sx={theme.customToggleButton}>Room Prep</Typography>
            <InPageToggleButtonGroup
              value={roomPrep}
              exclusive
              onChange={handleChange(setRoomPrep)}
              fullWidth
            >
              <InPageToggleButton value="low" sx={theme.customToggleButton}>Low</InPageToggleButton>
              <InPageToggleButton value="medium" sx={theme.customToggleButton}>Med</InPageToggleButton>
              <InPageToggleButton value="high" sx={theme.customToggleButton}>High</InPageToggleButton>
            </InPageToggleButtonGroup>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaintOptions;
