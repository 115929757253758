import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import theme from '../../theme';
import ImageCanvas from '../ImageCanvas';
import { ImageContainer, PositionedActionButton } from '../Buttons';
import { uploadFloorplan, addRoom } from '../../utils/Floorplans/jobsAPI';
import { PIXELS_PER_METER } from '../Floorplan/Constants';

/**
 * Initializes the state for the FloorUpload component.
 * @param {number} numFloors - The number of floors to initialize state for.
 * @returns {Object} The initialized state object.
 */
const initializeState = (numFloors) => ({
  floorImages: Array(numFloors).fill(null),
  floorRooms: Array(numFloors).fill([]),
  loading: Array(numFloors).fill(false),
  errors: Array(numFloors).fill(null),
  numFloors,
});

/**
 * Fetches application data from local storage and retrieves floorId.
 * 
 * @returns {string|null} The current floorId or null if not found.
 */
const getFloorIdFromLocalStorage = () => {
  const storedAppData = JSON.parse(localStorage.getItem('appData'));
  const storedAppState = JSON.parse(localStorage.getItem('appState'));

  if (storedAppData && storedAppState) {
    const currentJob = storedAppData.jobs.find(job => job._id === storedAppState.currentJobID);
    if (currentJob && currentJob.FloorPlanID) {
      const selectedFloor = currentJob.FloorPlanID.Floors.find(floor => floor._id === storedAppState.currentFloorID);
      if (selectedFloor) {
        return selectedFloor._id; // Return the floorId
      }
    }
  }
  return null;
};

/**
 * FloorUpload component for uploading floor plans and generating rooms.
 * 
 * This component allows users to upload floor plans for multiple floors,
 * generate rooms based on the uploaded floor plans, and manage the state
 * of the uploaded images and generated rooms.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.jobId - The ID of the job associated with the floor plans.
 * @returns {JSX.Element} The rendered component.
 */
const FloorUpload = ({ jobId }) => {
  const [state, setState] = useState(initializeState(0));
  const [floorId, setFloorId] = useState(null);

  // Fetch floorId from localStorage on mount
  useEffect(() => {
    const id = getFloorIdFromLocalStorage();
    if (id) {
      setFloorId(id);
    }
  }, []);

  /**
   * Fetches application data from local storage and initializes the state.
   * This function retrieves the number of floors from the stored app data
   * and updates the component state accordingly.
   */
  const fetchAppData = () => {
    const storedAppData = localStorage.getItem('appData');
    if (storedAppData) {
      const appData = JSON.parse(storedAppData);
      const floors = appData.jobs?.[0]?.FloorPlanID?.Floors?.length || 0;
      setState(initializeState(floors));
    }
  };

  useEffect(fetchAppData, []);

  /**
   * Updates the component state for a specific index with new values.
   * 
   * @param {Object} updates - An object containing the state properties to update.
   * @param {number} index - The index of the state property to update.
   */
  const updateState = (updates, index) => {
    setState(prevState => ({
      ...prevState,
      ...Object.keys(updates).reduce((acc, key) => {
        acc[key] = prevState[key].map((item, i) => (i === index ? updates[key] : item));
        return acc;
      }, {})
    }));
  };

  /**
   * Handles the upload of a floor plan image.
   * 
   * @param {Object} event - The change event from the file input.
   * @param {number} floorIndex - The index of the floor being uploaded.
   */
  const handleUpload = useCallback((event, floorIndex) => {
    const file = event.target.files[0];
    if (file) {
      updateState({
        floorImages: file,
        floorRooms: [],
        errors: null
      }, floorIndex);
    }
  }, []);

  /**
   * Generates rooms based on the uploaded floor plan for a specific floor.
   * 
   * @param {number} floorIndex - The index of the floor for which to generate rooms.
   * @param {string} jobId - The ID of the job associated with the floor plan.
   */
  const handleGenerateRooms = useCallback(async (floorIndex, jobId) => {
    if (!state.floorImages[floorIndex] || !floorId) {
      return;
    }

    updateState({ loading: true }, floorIndex);

    const formData = new FormData();
    formData.append('floorplan', state.floorImages[floorIndex]);
    if (jobId) {
      formData.append('jobId', jobId);
    }

    try {
      const data = await uploadFloorplan(formData);

      if (!data.rooms || !Array.isArray(data.rooms.floorplan)) {
        throw new Error('Invalid response format');
      }

      const roomPromises = data.rooms.floorplan.map((roomData, i) => {
        const room = {
          id: `${floorId}-room-${i}`,  // Prepend floorId to room ID for uniqueness
          name: roomData.name,
          width: roomData.x * PIXELS_PER_METER,
          length: roomData.y * PIXELS_PER_METER,
          x: roomData['x-centre'],
          y: roomData['y-centre'],
          floor: floorIndex + 1,
          doors: roomData.Doors,
          windows: roomData.Windows,
          walls: {
            top: { color: 'rgb(255,255,255)' },
            bottom: { color: 'rgb(255,255,255)' },
            left: { color: 'rgb(255,255,255)' },
            right: { color: 'rgb(255,255,255)' },
          },
          ceilingColour:  { color: 'rgb(255,255,255)' }, 
          windowColour:  { color: 'rgb(255,255,255)' },
          doorColour:  { color: 'rgb(255,255,255)' }, 
          architraveColour:  { color: 'rgb(255,255,255)' }, 
          skirtingBoardColour:  { color: 'rgb(255,255,255)' },
          jobId,
          floorIndex,
        };
      
        return addRoom(room);
      });

      const fullRoomData = await Promise.all(roomPromises);

      updateState({ floorRooms: fullRoomData }, floorIndex);

    } catch (error) {
      console.error('Error processing floorplan:', error);
      updateState({ errors: 'Error processing floorplan' }, floorIndex);
    } finally {
      updateState({ loading: false }, floorIndex);
    }
  }, [state.floorImages, floorId]);

  return (
    <Box sx={theme.innerBox}>
      {state.floorImages.map((_, index) => (
        <Box key={index} sx={{ mb: 3, border: '1px solid #fff', borderRadius: '8px', p: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Floor {index + 1}
            </Typography>
            <ImageContainer>
              <ImageCanvas 
                image={state.floorImages[index]} 
                style={{ 
                  width: '100%', 
                  height: '150px',
                  objectFit: 'contain',
                  borderRadius: '8px',
                  outlineStyle: 'solid', 
                  outlineColor: 'rgba(0, 0, 0, 0.3)'
                }} 
              />
              <PositionedActionButton
                variant="contained"
                component={state.floorImages[index] ? 'button' : 'label'}
                onClick={state.floorImages[index] ? () => handleGenerateRooms(index, jobId) : null}
                disabled={state.loading[index]}
              >
                {state.loading[index] ? (
                  <CircularProgress size={24} />
                ) : state.floorImages[index] ? (
                  state.floorRooms[index]?.length > 0 ? <CheckIcon /> : 'Generate Rooms'
                ) : (
                  'Upload Floorplan'
                )}
                {!state.floorImages[index] && (
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={(e) => handleUpload(e, index)} 
                    required 
                    hidden 
                  />
                )}
              </PositionedActionButton>
            </ImageContainer>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default FloorUpload;
