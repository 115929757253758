import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Box, Typography, InputAdornment, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Select, MenuItem, FormControl, InputLabel, Slider } from '@mui/material';
import { Person, Mail, Phone, Work, Add, Check, AttachMoney, Timeline, RecentActors } from '@mui/icons-material';
import axios from 'axios';
import { NavToggleButtonGroup, NavToggleButton } from './CustomToggleButton'
import theme from './theme';

const DEFAULT_AVATAR = '/images/avatars/painter.webp';

const INITIAL_CONTRACTOR = {
  fullName: '',
  email: '',
  phone: '',
  specialty: '',
  photo: DEFAULT_AVATAR,
  hourlyRate: 20,
  experience: 0,
};

const ContractorPanel = () => {
  const [contractors, setContractors] = useState([]);
  const [newContractor, setNewContractor] = useState(INITIAL_CONTRACTOR);
  const [selectedContractorId, setSelectedContractorId] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState('list');
  const [newPhotoFile, setNewPhotoFile] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  // Function to reset the state and prepare for adding a new contractor
    const handleAddNewContractorClick = () => {
      setSelectedContractorId(null);
      setNewContractor(INITIAL_CONTRACTOR);
      setNewPhotoFile(null);
      setSelectedOption('add');
    };

  const contractorTypes = [
    'Interior Painter',
    'Exterior Painter',
    'Labourer',
    'Plasterer',
    'Wallpaper Installer',
    'Decorator/Interior Designer',
    'Color Consultant',
  ];

  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };

  const userId = getCookie('userId');

  useEffect(() => {
    if (userId) {
      fetchContractors();
    }
  }, [userId]);

  useEffect(() => {
    setDeleteConfirmation(false);
  }, [selectedContractorId]);

  const fetchContractors = useCallback(async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}/api/contractors`, { params: { userId } });
      setContractors(response.data);
    } catch (error) {
      console.error('Error fetching contractors:', error);
    }
  }, [userId]);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setNewContractor((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSliderChange = useCallback((name) => (event, newValue) => {
    setNewContractor((prev) => ({ ...prev, [name]: newValue }));
  }, []);

  const handleImageUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      setNewPhotoFile(file);
      setNewContractor((prev) => ({ ...prev, photo: URL.createObjectURL(file) }));
    }
  }, []);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();

    if (!newContractor.fullName) {
      alert('Contractor Full Name is required');
      return;
    }

    if (!userId) {
      alert('User ID is missing. Please log in again.');
      return;
    }

    try {
      setIsUpdating(true);
      let contractorData = { ...newContractor, userId };

      if (newPhotoFile) {
        const formData = new FormData();
        formData.append('contractorPhoto', newPhotoFile);
        formData.append('userId', userId);
        if (selectedContractorId) {
          formData.append('contractorId', selectedContractorId);
        }

        const photoResponse = await axios.post(`${API_ENDPOINT}/api/upload-contractor-photo`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        contractorData.photo = photoResponse.data.photoUrl;
      }

      const response = selectedContractorId
        ? await axios.patch(`${API_ENDPOINT}/api/contractors/${selectedContractorId}`, contractorData)
        : await axios.post(`${API_ENDPOINT}/api/contractors`, contractorData);

      await fetchContractors();

      setIsUpdating(false);
      setUpdateSuccess(true);
      setNewPhotoFile(null);
      setTimeout(() => {
        setUpdateSuccess(false);
        setSelectedOption('list');
      }, 3000);
    } catch (error) {
      setIsUpdating(false);
      console.error('Error adding/updating contractor:', error);
      alert(`Error: ${error.response?.data?.message || error.message}`);
    }
  }, [newContractor, newPhotoFile, selectedContractorId, userId, fetchContractors]);

  const handleDeleteContractor = useCallback(async () => {
    if (!selectedContractorId) return;

    try {
      await axios.delete(`${API_ENDPOINT}/api/contractors/${selectedContractorId}`);
      await fetchContractors();

      setNewContractor(INITIAL_CONTRACTOR);
      setSelectedContractorId(null);
      setSelectedOption('list');
      setDeleteConfirmation(false);
    } catch (error) {
      console.error('Error deleting contractor:', error);
      alert(`Error: ${error.response?.data?.message || error.message}`);
    }
  }, [selectedContractorId, fetchContractors]);

  const handleDeleteClick = useCallback(() => {
    if (deleteConfirmation) {
      handleDeleteContractor();
    } else {
      setDeleteConfirmation(true);
    }
  }, [deleteConfirmation, handleDeleteContractor]);

  const handleOptionChange = useCallback((event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
      if (newOption === 'add') {
        setSelectedContractorId(null);
        setNewContractor(INITIAL_CONTRACTOR);
        setNewPhotoFile(null);
      }
    }
  }, []);

  const handleContractorClick = useCallback((contractor) => {
    setSelectedContractorId(contractor._id);
    setNewContractor({
      fullName: contractor.fullName,
      email: contractor.email,
      phone: contractor.phone,
      specialty: contractor.specialty,
      photo: contractor.photo,
      hourlyRate: contractor.hourlyRate,
      experience: contractor.experience,
    });
    setNewPhotoFile(null);
    setSelectedOption('add');
  }, []);

  const renderAddContractor = () => (
    <Box sx={theme.customFormBox}>
      <Box
        sx={theme.customLogoBox}
        onClick={() => document.getElementById('contractor-photo-input').click()}
      >
        <Box sx={theme.customLogoGreyBox} />
        <Avatar
          sx={theme.customLogoAvatar}
          src={newContractor.photo || DEFAULT_AVATAR}
          onClick={(e) => {
            e.stopPropagation();
            document.getElementById('contractor-photo-input').click();
          }}
        />
      </Box>

      <input
        id="contractor-photo-input"
        type="file"
        hidden
        onChange={handleImageUpload}
      /> 

      <Box sx={theme.customFormBox}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', fontFamily: '"Figtree", sans-serif' }} gutterBottom>
          Contractor Details
        </Typography>
        <TextField
          label="Full Name"
          name="fullName"
          value={newContractor.fullName}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '.MuiOutlinedInput-root': {
              padding: '8px', 
              fontSize: '1.25rem', 
              minHeight: '50px', 
              borderRadius: '12px', 
              '&.Mui-focused': {
                backgroundColor: 'white', 
                fontWeight: 'bold', 
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1565c0', 
              },
            },
            '.MuiInputLabel-root': {
              fontSize: '1rem', 
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Email Address"
          name="email"
          value={newContractor.email}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '.MuiOutlinedInput-root': {
              padding: '8px', 
              fontSize: '1.25rem', 
              minHeight: '50px', 
              borderRadius: '12px', 
              '&.Mui-focused': {
                backgroundColor: 'white', 
                fontWeight: 'bold', 
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1565c0', 
              },
            },
            '.MuiInputLabel-root': {
              fontSize: '1rem', 
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Mail />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Phone Number"
          name="phone"
          value={newContractor.phone}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '.MuiOutlinedInput-root': {
              padding: '8px', 
              fontSize: '1.25rem', 
              minHeight: '50px', 
              borderRadius: '12px', 
              '&.Mui-focused': {
                backgroundColor: 'white', 
                fontWeight: 'bold', 
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1565c0', 
              },
            },
            '.MuiInputLabel-root': {
              fontSize: '1rem', 
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Phone />
              </InputAdornment>
            ),
          }}
        />
        <FormControl fullWidth variant="outlined"
          sx={{
            ...theme.inputStyle,
            '.MuiOutlinedInput-root': {
              padding: '8px', 
              fontSize: '1.25rem', 
              minHeight: '50px', 
              borderRadius: '12px', 
              '&.Mui-focused': {
                backgroundColor: 'white', 
                fontWeight: 'bold', 
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1565c0', 
              },
            },
            '.MuiInputLabel-root': {
              fontSize: '1rem', 
            }
          }}>
          <InputLabel id="specialty-label">Job</InputLabel>
          <Select
            labelId="specialty-label"
            id="specialty"
            name="specialty"
            value={newContractor.specialty}
            onChange={handleInputChange}
            label="Specialty"
            startAdornment={
              <InputAdornment position="start">
                <Work />
              </InputAdornment>
            }
          >
            {contractorTypes.map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ ...theme.inputStyle, mt: 2 }}>
          <Typography gutterBottom>
            Hourly Rate (${newContractor.hourlyRate})
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AttachMoney sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <Slider
              value={newContractor.hourlyRate}
              onChange={handleSliderChange('hourlyRate')}
              aria-labelledby="hourly-rate-slider"
              valueLabelDisplay="auto"
              step={5}
              marks
              min={10}
              max={150}
            />
          </Box>
        </Box>

        <Box sx={{ ...theme.inputStyle, mt: 2 }}>
          <Typography gutterBottom>
            Experience ({newContractor.experience} years)
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Timeline sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <Slider
              value={newContractor.experience}
              onChange={handleSliderChange('experience')}
              aria-labelledby="experience-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={40}
            />
          </Box>
        </Box>

        <Button
          variant="contained"
          color={updateSuccess ? 'success' : 'primary'}
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
          disabled={isUpdating}
        >
          {isUpdating ? 'Saving...' : updateSuccess ? 'Contractor Saved' : selectedContractorId ? 'Update Contractor' : 'Add Contractor'}
          {updateSuccess && <Check style={{ marginLeft: '8px' }} />}
        </Button>
        {selectedContractorId && (
          <Button
            variant="contained"
            color={deleteConfirmation ? "warning" : "error"}
            onClick={handleDeleteClick}
            fullWidth
            sx={{ mt: 2 }}
          >
            {deleteConfirmation ? "ARE YOU SURE?" : "Delete Contractor"}
          </Button>
        )}
      </Box>
    </Box>
  );

  const renderContractorList = () => (
    <Box sx={theme.innerBox}>
      <Box sx={{ mb: 2, border: '1px solid #fff', borderRadius: '8px', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '1.4rem', fontFamily: '"Figtree", sans-serif', paddingLeft: '8px' }}>Contractors</Typography>
        <List>
          {Array.isArray(contractors) && contractors.length > 0 ? (
            contractors.map((contractor) => (
              <React.Fragment key={contractor._id}>
                <Box sx={{ mb: 2, border: '2px solid #fff', borderRadius: '8px', p: 0, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                  <ListItem
                    onClick={() => handleContractorClick(contractor)}
                    sx={theme.listItem}
                  >
                    <ListItemAvatar sx={{ minWidth: 'auto', marginRight: 1 }}>
                      <Avatar
                        src={contractor.photo || DEFAULT_AVATAR}
                        sx={{
                          width: 48,
                          height: 48,
                          backgroundColor: '#f0f0f0',
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<span style={theme.listItemTextName}>{contractor.fullName}</span>}
                      secondary={<span style={theme.listItemText}>{`${contractor.specialty} | ${contractor.hourlyRate}/hr | ${contractor.experience} years exp.`}</span>}
                    />
                  </ListItem>
                </Box>
              </React.Fragment>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No contractors added yet." />
            </ListItem>
          )}
        </List>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddNewContractorClick} 
          fullWidth
          sx={{ mt: 1 }}
        >
          Add New Contractor
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
      <Box my={0}>
        <NavToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth>
          <NavToggleButton value="list"><RecentActors /></NavToggleButton>
          <NavToggleButton value="add"><Add /></NavToggleButton>
        </NavToggleButtonGroup>
      </Box>
      {selectedOption === 'add' ? renderAddContractor() : renderContractorList()}
    </Box>
  );
};

export default ContractorPanel;
