import React from 'react';
import { Button, Box } from '@mui/material';

export const UploadButton = ({ children, ...props }) => (
  <Button
    sx={{
      margin: '10px 0',
      backgroundColor: '#007bff',
      color: 'white',
      '&:hover': {
        backgroundColor: '#28a745',
      },
    }}
    {...props}
  >
    {children}
  </Button>
);

export const ActionButton = ({ children, ...props }) => (
  <Button
    sx={{
      margin: '10px 0',
      backgroundColor: '#007bff',
      fontSize: '12px',
      fontWeight: 'bold',
      color: 'white',
      '&:hover': {
        backgroundColor: '#28a745',
      },
    }}
    {...props}
  >
    {children}
  </Button>
);

export const CloseButton = ({ children, ...props }) => (
  <Button
      sx={{
        margin: '0',
        backgroundColor: 'red', // Original base color
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'white',
        padding: '6px 16px',
        borderRadius: '8px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)', // Subtle shadow for depth
        transition: 'all 0.3s ease-in-out', // Smooth transitions
        '&:hover': {
          backgroundColor: 'pink', // Original hover color
          transform: 'scale(1.05)', // Slight zoom on hover
          boxShadow: '0 4px 10px rgba(255, 105, 135, 0.4)', // Glow-like shadow on hover
        },
        '&:active': {
          transform: 'scale(1)', // Reset size when clicked
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)', // Adjusted shadow when active
        },
        '&:focus': {
          outline: 'none',
          boxShadow: '0 0 0 3px rgba(255, 105, 135, 0.5)', // Focus ring for accessibility
        },
      }}
       // Spread user-defined styles
      {...props}
    >
      {children}
    </Button>
);

export const SaveButton = ({ children, ...props }) => (
  <Button
    sx={{
      margin: '0',
      backgroundColor: '#4CAF50', // Green base color for Save action
      fontSize: '13px',
      fontWeight: 'bold',
      color: 'white',
      padding: '4px 16px',
      borderRadius: '8px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)', // Subtle shadow for depth
      transition: 'all 0.3s ease-in-out', // Smooth transitions
      '&:hover': {
        backgroundColor: '#45a049', // Darker green for hover effect
        transform: 'scale(1.05)', // Slight zoom on hover
        boxShadow: '0 4px 10px rgba(69, 160, 73, 0.4)', // Enhanced shadow for hover
      },
      '&:active': {
        transform: 'scale(1)', // Reset size when clicked
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)', // Adjusted shadow when active
      },
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 3px rgba(76, 175, 80, 0.5)', // Focus ring for accessibility
      },
    }}
    {...props}
  >
    {children}
  </Button>
);

export const EnhanceButton = ({ children, ...props }) => (
  <Button
    sx={{
      margin: '0',
      backgroundColor: '#4CAF50', // Green base color for Save action
      fontSize: '13px',
      fontWeight: 'bold',
      color: 'white',
      padding: '4px 16px',
      borderRadius: '8px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)', // Subtle shadow for depth
      transition: 'all 0.3s ease-in-out', // Smooth transitions
      '&:hover': {
        backgroundColor: '#4B0082', // Darker green for hover effect
        transform: 'scale(1.05)', // Slight zoom on hover
        boxShadow: '0 4px 10px rgba(69, 160, 73, 0.4)', // Enhanced shadow for hover
      },
      '&:active': {
        transform: 'scale(1)', // Reset size when clicked
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)', // Adjusted shadow when active
      },
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 3px rgba(76, 175, 80, 0.5)', // Focus ring for accessibility
      },
    }}
    {...props}
  >
    {children}
  </Button>
);

export const ModifyButton = ({ children, ...props }) => (
  <Button
    sx={{
      margin: '0',
      backgroundColor: '#4CAF50', // Green base color for Save action
      fontSize: '13px',
      fontWeight: 'bold',
      color: 'white',
      padding: '4px 16px',
      borderRadius: '8px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)', // Subtle shadow for depth
      transition: 'all 0.3s ease-in-out', // Smooth transitions
      '&:hover': {
        backgroundColor: '#4B0082', // Darker green for hover effect
        transform: 'scale(1.05)', // Slight zoom on hover
        boxShadow: '0 4px 10px rgba(69, 160, 73, 0.4)', // Enhanced shadow for hover
      },
      '&:active': {
        transform: 'scale(1)', // Reset size when clicked
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)', // Adjusted shadow when active
      },
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 3px rgba(76, 175, 80, 0.5)', // Focus ring for accessibility
      },
    }}
    {...props}
  >
    {children}
  </Button>
);


export const DownloadButton = ({ children, ...props }) => (
  <Button
    sx={{
      margin: '10px 0',
      backgroundColor: '#28a745',
      color: 'white',
      '&:hover': {
        backgroundColor: '#218838',
      },
    }}
    {...props}
  >
    {children}
  </Button>
);

export const ImageContainer = ({ children, ...props }) => (
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      textAlign: 'center',
    }}
    {...props}
  >
    {children}
  </Box>
);

export const Canvas = React.forwardRef((props, ref) => (
  <canvas
    ref={ref}
    style={{
      width: '95%',
      borderRadius: '8px',
    }}
    {...props}
  />
));

export const PositionedActionButton = ({ children, ...props }) => (
  <ActionButton
    sx={{
      position: 'absolute',
      bottom: '15px',
      right: '40px',
    }}
    {...props}
  >
    {children}
  </ActionButton>
);