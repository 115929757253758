import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppInsightsErrorBoundary onError={() => console.error('Error caught by Application Insights')} appInsights={reactPlugin}>
      <App />
    </AppInsightsErrorBoundary>
  
);


