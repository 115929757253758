import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Box, Button, Typography, IconButton, Popover } from '@mui/material';
import { Check, HelpOutline } from '@mui/icons-material';
import theme from '../../theme';

const ContractTimeline = ({
    contractState,
    handleContractInputChange,
    handleSubmit,
    updateSuccess,
    isUpdating,
}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState('');

    const handlePopoverOpen = (event, content) => {
        setAnchorEl(event.currentTarget);
        setPopoverContent(content);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverContent('');
    };

    const open = Boolean(anchorEl);

  return (
    <Box sx={theme.innerBox}>
      <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
      <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Contract Schedule
            </Typography>
            <IconButton 
                onClick={(e) => handlePopoverOpen(e, 'This is the description for General Liability Insurance. It covers potential liabilities and damages.')}
                size="small"
            >
                <HelpOutline fontSize="small" />
            </IconButton>
            </Box>
        <TextField
          label="Start Date"
          type="date"
          value={contractState.startDate ? contractState.startDate.toISOString().split('T')[0] : ''}
          onChange={(e) => handleContractInputChange('startDate', new Date(e.target.value))}
          fullWidth
          variant="outlined"
          sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="End Date"
          type="date"
          value={contractState.endDate ? contractState.endDate.toISOString().split('T')[0] : ''}
          onChange={(e) => handleContractInputChange('endDate', new Date(e.target.value))}
          fullWidth
          variant="outlined"
          sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
          InputLabelProps={{
            shrink: true,
          }}
        /></Box>
        <Box sx={{ mb: 0, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Milestone Details
            </Typography>
            <IconButton 
                onClick={(e) => handlePopoverOpen(e, 'This is the description for General Liability Insurance. It covers potential liabilities and damages.')}
                size="small"
            >
                <HelpOutline fontSize="small" />
            </IconButton>
            </Box>
        {contractState.milestones.map((milestone, index) => (
          <Box key={index} sx={{ mt: 0 }}>
            <TextField
              label={`Milestone Description ${index + 1}`}
              value={milestone.description}
              onChange={(e) => handleContractInputChange('milestones', contractState.milestones.map((m, i) => i === index ? { ...m, description: e.target.value } : m), true)}
              fullWidth
              variant="outlined"
              sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
            />
            <TextField
              label={`Milestone Date ${index + 1}`}
              type="date"
              value={milestone.date ? milestone.date.toISOString().split('T')[0] : ''}
              onChange={(e) => handleContractInputChange('milestones', contractState.milestones.map((m, i) => i === index ? { ...m, date: new Date(e.target.value) } : m), true)}
              fullWidth
              variant="outlined"
              sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        ))}
        <Button
          variant="contained"
          color={updateSuccess ? 'success' : 'primary'}
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
          disabled={isUpdating}
        >
          {isUpdating
            ? 'Updating Details...'
            : updateSuccess
            ? 'Details Updated'
            : 'Update Details'}
        {updateSuccess && <Check style={{ marginLeft: '4px' }} />}
        </Button>
      </Box>
    </Box>
   <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography>{popoverContent}</Typography>
          </Box>
        </Popover>
        </Box>
  );
};

export default ContractTimeline;