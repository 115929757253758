import React from 'react';
import { Box } from '@mui/material';
import CustomTooltip from './components/CustomTooltip'; 
import {
  HomeOutlined,
  PersonOutlined,
  PeopleOutlined,
  CalendarTodayOutlined,
  AppsOutlined,
  FormatPaintOutlined,
  ColorLensOutlined,
  FormatShapesOutlined,
  MonetizationOnOutlined,
  MapOutlined,
  ContentPasteSearchOutlined,
  GavelOutlined,
} from '@mui/icons-material';

const IconColumn = ({ activePanel, handleIconClick }) => {
  const commonStyles = {
    borderRadius: '0px',
    padding: '0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const iconDict = {
    accountPanel: { active: <HomeOutlined />, inactive: <HomeOutlined /> },
    clientPanel: { active: <PersonOutlined />, inactive: <PersonOutlined /> },
    contractorPanel: { active: <PeopleOutlined />, inactive: <PeopleOutlined /> },
    inspectionPanel: { active: <ContentPasteSearchOutlined />, inactive: <ContentPasteSearchOutlined /> },
    schedulePanel: { active: <CalendarTodayOutlined />, inactive: <CalendarTodayOutlined /> },
    JobOptions: { active: <AppsOutlined />, inactive: <AppsOutlined /> },
    paintOptions: { active: <FormatPaintOutlined />, inactive: <FormatPaintOutlined /> },
    colourOptions: { active: <ColorLensOutlined />, inactive: <ColorLensOutlined /> },
    roomOptions: { active: <FormatShapesOutlined />, inactive: <FormatShapesOutlined /> },
    invoicePanel: { active: <MonetizationOnOutlined />, inactive: <MonetizationOnOutlined /> },
    contractPanel: { active: <GavelOutlined />, inactive: <GavelOutlined /> },
    mapPanel: { active: <MapOutlined />, inactive: <MapOutlined /> },
  };

  const icons = [
    { title: 'Account Details', panel: 'accountPanel', index: 0 },
    { title: 'Client Details', panel: 'clientPanel', index: 0 },
    { title: 'Contractors', panel: 'contractorPanel', index: 0 },
    { title: 'Job Options', panel: 'JobOptions', index: 0 },
    { title: 'Inspection', panel: 'inspectionPanel', index: 3 },
    { title: 'Paint Options', panel: 'paintOptions', index: 0 }, 
    { title: 'Room Options', panel: 'roomOptions', index: 0 }, 
    { title: 'Colour Options', panel: 'colourOptions', index: 0 }, 
    { title: 'Schedule', panel: 'schedulePanel', index: 5 }, 
    { title: 'Invoice Options', panel: 'invoicePanel', index: 7 },
    { title: 'Contract Options', panel: 'contractPanel', index: 6 },
    { title: 'Directions', panel: 'mapPanel', index: 8 },
  ];

  return (
    <div className="column icon-column">
      <Box p={2}>
        {icons.map((icon) => (
          <CustomTooltip title={icon.title} placement="right" key={icon.panel}>
            <Box
              className="icon"
              onClick={() => handleIconClick(icon.panel, icon.index)}
              sx={{
                ...commonStyles,
                borderLeft: activePanel === icon.panel ? '4px solid blue' : 'none',
                color: activePanel === icon.panel ? 'blue' : 'inherit',
                '& svg': {
                  fontSize: activePanel === icon.panel ? '32px' : '24px', // Active icon larger
                },
              }}
            >
              {activePanel === icon.panel ? iconDict[icon.panel].active : iconDict[icon.panel].inactive}
            </Box>
          </CustomTooltip>
        ))}
      </Box>
    </div>
  );
};

export default IconColumn;
