import React from 'react';
import {
  Box,
  Typography,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { 
  NavToggleButtonGroup, 
  NavToggleButton, 
  InPageToggleButtonGroup, 
  InPageToggleButton 
} from '../../CustomToggleButton'
import theme from '../../theme';

const PaintOptions = ({
  coverage,
  setCoverage,
  metersSquared,
  setMetersSquared,
  roomHeight,
  setRoomHeight,
  wallCoats,
  setWallCoats,
  roomPrep,
  setRoomPrep,
}) => {
  return (
    <Box sx={theme.innerBox}>
    <Box sx={theme.innerBox}>
      <Typography variant="h6" gutterBottom>
        Paint Options
      </Typography>

      <Typography sx={theme.customToggleButton}>Paint Coverage (m²/liter)</Typography>
      <Slider
        value={coverage}
        onChange={(e, val) => setCoverage(val)}
        valueLabelDisplay="auto"
        min={10}
        max={20}
        step={1}
      />

      <Typography sx={theme.customToggleButton}>Productivity (m²/hour)</Typography>
      <Slider
        value={metersSquared}
        onChange={(e, val) => setMetersSquared(val)}
        valueLabelDisplay="auto"
        min={10}
        max={50}
        step={1}
      />

      <Typography sx={theme.customToggleButton}>Room Height (m)</Typography>
      <Slider
        value={roomHeight}
        onChange={(e, val) => setRoomHeight(val)}
        valueLabelDisplay="auto"
        min={2.5}
        max={7.0}
        step={0.1}
      />

      <Box my={2}>
        <Typography sx={theme.customToggleButton}>Wall Coats</Typography>
        <InPageToggleButtonGroup
          value={wallCoats}
          exclusive
          onChange={(e, val) => setWallCoats(val)}
          fullWidth
        >
          <InPageToggleButton value="1" sx={theme.customToggleButton}>One</InPageToggleButton>
          <InPageToggleButton value="2" sx={theme.customToggleButton}>Two</InPageToggleButton>
          <InPageToggleButton value="3" sx={theme.customToggleButton}>Three</InPageToggleButton>
        </InPageToggleButtonGroup>
      </Box>

      <Box my={2}>
        <Typography sx={theme.customToggleButton}>Room Prep</Typography>
        <InPageToggleButtonGroup
          value={roomPrep}
          exclusive
          onChange={(e, val) => setRoomPrep(val)}
          fullWidth
        >
          <InPageToggleButton value="Low" sx={theme.customToggleButton}>Low</InPageToggleButton>
          <InPageToggleButton value="Medium" sx={theme.customToggleButton}>Medium</InPageToggleButton>
          <InPageToggleButton value="High" sx={theme.customToggleButton}>High</InPageToggleButton>
        </InPageToggleButtonGroup>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          // Handle the submission of paint options if needed
          console.log('Paint options submitted:', {
            coverage,
            metersSquared,
            roomHeight,
            wallCoats,
            roomPrep,
          });
        }}
        fullWidth
        sx={{ mt: 2 }}
      >
        Save Paint Options
      </Button>
    </Box>
    </Box>
  );
};

export default PaintOptions;