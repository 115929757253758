// useJobState.js
import { useState, useCallback, useEffect } from 'react';
import { fetchJobs, fetchClients, createJob, updateJob, deleteJob } from '../../utils/Floorplans/jobsAPI';


export const useJobState = (userId) => {
  const [appData, setAppData] = useState(() => {
    const storedData = localStorage.getItem('appData');
    return storedData ? JSON.parse(storedData) : {
      account: {},
      clients: [],
      contractors: [],
      jobs: []
    };
  });

  const [appState, setAppState] = useState(() => {
    const storedAppState = localStorage.getItem('appState');
    return storedAppState ? JSON.parse(storedAppState) : {
      currentJobID: null,
      currentFloorplanID: null,
      currentFloorID: null,
    };
  });

  const [currentJob, setCurrentJob] = useState(null);

  const updateStateAndStorage = useCallback((newJobs) => {
    console.log('Updating state and localStorage with new jobs:', newJobs);
    setAppData(prevAppData => {
      const updatedAppData = {
        ...prevAppData,
        jobs: newJobs
      };
      localStorage.setItem('appData', JSON.stringify(updatedAppData));
      return updatedAppData;
    });
  }, []);

  const updateAppStateAndStorage = useCallback((newAppState) => {
    setAppState((prevAppState) => {
      const updatedAppState = {
        ...prevAppState,     // Retain existing values (like currentAccountID, currentClientID, etc.)
        ...newAppState       // Only overwrite the fields provided in newAppState (like currentJobID, etc.)
      };
      localStorage.setItem('appState', JSON.stringify(updatedAppState));  // Save to localStorage
      return updatedAppState;  // Return the updated state
    });
  }, []);
  

  useEffect(() => {
    const loadJobsAndClients = async () => {
      try {
        // toast.info('Fetching jobs and clients...');
        const [fetchedJobs, fetchedClients] = await Promise.all([
          fetchJobs(userId),
          fetchClients(userId)
        ]);
        setAppData(prevAppData => ({
          ...prevAppData,
          jobs: fetchedJobs,
          clients: fetchedClients
        }));
        // toast.success('Jobs and clients loaded successfully');
      } catch (error) {
        // toast.error(`Error fetching jobs and clients: ${error.message}`);
        console.error('Error fetching jobs and clients:', error);
      }
    };
    loadJobsAndClients();
  }, [userId]);

  const selectJob = useCallback((jobId) => {
    const job = appData.jobs.find(j => j._id === jobId);
    setCurrentJob(job || null);
  }, [appData.jobs]);

  const createNewJob = useCallback(async (jobData) => {
    try {
      // toast.info('Creating a new job...');
      
      const newJob = await createJob(jobData); // API call to create job
      updateStateAndStorage([...appData.jobs, newJob]); // Update appData and localStorage

      // Update appState with new job details
      const newAppState = {
        currentJobID: newJob._id,
        currentFloorplanID: newJob.FloorPlanID?._id || null,
        currentFloorID: newJob.FloorPlanID?.Floors?.[0]?._id || null,
      };
      updateAppStateAndStorage(newAppState); // Update appState and localStorage

      // toast.success('New job created successfully');
      return newJob;
    } catch (error) {
      // toast.error(`Error creating job: ${error.message}`);
      console.error('Error creating job:', error);
      throw error;
    }
  }, [appData.jobs, updateStateAndStorage, updateAppStateAndStorage]);

  const updateExistingJob = useCallback(
    async (jobId, jobData) => {
      console.log('updateExistingJob called with jobId:', jobId);
      try {
        const updatedJob = await updateJob(jobId, jobData);
        console.log('Job updated successfully:', updatedJob);
  
        await fetchJobs(); // Refresh the job list after updating
  
        // Update appState with updated job details
        updateAppStateAndStorage((prevAppState) => ({
          ...prevAppState,
          currentJobID: updatedJob._id,
          currentFloorplanID: updatedJob.FloorPlanID
            ? updatedJob.FloorPlanID._id
            : null,
          currentFloorID:
            updatedJob.FloorPlanID &&
            updatedJob.FloorPlanID.Floors &&
            updatedJob.FloorPlanID.Floors.length > 0
              ? updatedJob.FloorPlanID.Floors[0]._id
              : null,
        }));
  
        return updatedJob;
      } catch (error) {
        console.error('Error updating job in useJobState:', error);
        throw error;
      }
    },
    [fetchJobs, updateAppStateAndStorage]
  );
  

  const deleteExistingJob = useCallback(async (jobId) => {
    // toast.info(`Deleting job with ID: ${jobId}`);
    try {
      await deleteJob(jobId);
      const updatedJobs = appData.jobs.filter(job => job._id !== jobId);
      updateStateAndStorage(updatedJobs);

      // Reset appState for job-related fields after deletion
      const newAppState = {
        currentJobID: null,
        currentFloorplanID: null,
        currentFloorID: null,
      };
      updateAppStateAndStorage(newAppState);

      // toast.success('Job deleted successfully');
    } catch (error) {
      // toast.error(`Error deleting job: ${error.message}`);
      console.error('Error deleting job:', error);
      throw error;
    }
  }, [appData.jobs, updateStateAndStorage, updateAppStateAndStorage]);

  const resetCurrentJob = useCallback(() => {
    setCurrentJob(null);
    updateAppStateAndStorage({
      currentJobID: null,
      currentFloorplanID: null,
      currentFloorID: null,
    });
  }, [updateAppStateAndStorage]);

  return {
    appData,
    appState, // Return appState so components can access it
    currentJob,
    selectJob,
    createNewJob,
    updateExistingJob,
    deleteExistingJob,
    resetCurrentJob,
    fetchJobs
  };
};
