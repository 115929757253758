import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, TextField, Typography, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { UploadButton, DownloadButton, ImageContainer, Canvas, PositionedActionButton } from './components/Buttons';

const setupCanvasContext = (canvasRef) => {
  const ctx = canvasRef.current.getContext('2d');
  ctx.fillStyle = 'rgba(255, 255, 255, 0.70)';
  ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
};

const drawImageOnCanvas = (canvasRef, imageUrl) => {
  const ctx = canvasRef.current.getContext('2d');
  const img = new Image();
  img.src = imageUrl;
  img.onload = () => {
    const aspectRatio = img.width / img.height;
    const canvasWidth = canvasRef.current.clientWidth;
    const canvasHeight = canvasWidth / aspectRatio;
    canvasRef.current.width = canvasWidth;
    canvasRef.current.height = canvasHeight;
    ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
  };
};

const AIImageGeneration = () => {
  const [image, setImage] = useState(null);
  const [prompt, setPrompt] = useState("A photo of a room, 4k photo, highly detailed");
  const [depthMapUrl, setDepthMapUrl] = useState(null);
  const [outputImageUrl, setOutputImageUrl] = useState(null);
  const [enhancedImageUrl, setEnhancedImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [enhancing, setEnhancing] = useState(false);
  const [error, setError] = useState(null);

  const inputCanvasRef = useRef(null);
  const outputCanvasRef = useRef(null);
  const enhancedCanvasRef = useRef(null);

  useEffect(() => {
    setupCanvasContext(inputCanvasRef);
    setupCanvasContext(outputCanvasRef);
    setupCanvasContext(enhancedCanvasRef);
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setOutputImageUrl(null);
    setEnhancedImageUrl(null);
    setDepthMapUrl(null);
    setError(null);
  };

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const handleGenerate = async (e) => {
    e.preventDefault();
    if (!image || !prompt) {
      return;
    }

    const formData = new FormData();
    formData.append('image', image);
    formData.append('prompt', prompt);

    setGenerating(true);
    setError(null);

    try {
      const response = await axios.post(`${API_ENDPOINT}/api/generate`, formData);
      const [depthMap, outputImage] = response.data;
      setDepthMapUrl(depthMap);
      setOutputImageUrl(outputImage);
    } catch (err) {
      console.error('Error generating image:', err);
      setError('Error generating image');
    } finally {
      setGenerating(false);
      setLoading(false);
    }
  };

  const handleEnhance = async () => {
    if (!outputImageUrl) {
      return;
    }

    setEnhancing(true);
    setError(null);

    try {
      const response = await axios.post(`${API_ENDPOINT}/api/enhance`, {
        image: outputImageUrl,
      });
      setEnhancedImageUrl(response.data.output_url);
    } catch (err) {
      console.error('Error enhancing image:', err);
      setError('Error enhancing image');
    } finally {
      setEnhancing(false);
    }
  };

  useEffect(() => {
    if (outputImageUrl) {
      drawImageOnCanvas(outputCanvasRef, outputImageUrl);
    }
  }, [outputImageUrl]);

  useEffect(() => {
    if (enhancedImageUrl) {
      drawImageOnCanvas(enhancedCanvasRef, enhancedImageUrl);
    }
  }, [enhancedImageUrl]);

  useEffect(() => {
    if (image) {
      drawImageOnCanvas(inputCanvasRef, URL.createObjectURL(image));
    }
  }, [image]);

  return (
    <Box p={3} border="0px solid #ccc" borderRadius="8px" style={{
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)',
      width: '100%'
    }}>
      <Typography variant="h6" gutterBottom>
        Design your own space
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <UploadButton variant="contained" component="label">
          Upload Image
          <input type="file" accept="image/*" onChange={handleImageChange} required hidden />
        </UploadButton>
        <TextField
          label="Prompt"
          value={prompt}
          onChange={handlePromptChange}
          margin="normal"
          style={{ width: '75%', marginTop: '10px' }}
        />
      </Box>
      {error && <Typography color="error">{error}</Typography>}
      <Box mt={2}>
        <Box display="flex" justifyContent="space-around" mt={2} style={{ backgroundColor: 'rgba(255, 255, 255, 0)', padding: '10px 10px', RoundedCorner: '10px'}}>
          <ImageContainer>
            <Canvas ref={inputCanvasRef} />
            <PositionedActionButton variant="contained" onClick={handleGenerate} disabled={generating || loading}>
              {generating ? <CircularProgress size={24} /> : (outputImageUrl ? <CheckIcon /> : 'Generate')}
            </PositionedActionButton>
          </ImageContainer>
          <ImageContainer>
            <Canvas ref={outputCanvasRef} />
            {outputImageUrl && (
              <PositionedActionButton variant="contained" onClick={handleEnhance} disabled={enhancing}>
                {enhancing ? <CircularProgress size={24} /> : (enhancedImageUrl ? <CheckIcon /> : 'Enhance')}
              </PositionedActionButton>
            )}
          </ImageContainer>
          <ImageContainer>
            <Canvas ref={enhancedCanvasRef} />
          </ImageContainer>
        </Box>
        <Box display="flex" justifyContent="space-around" mt={2}>
          <Box textAlign="center" style={{ width: '30%' }}>
            {outputImageUrl && (
              <a href={outputImageUrl} download="generated_image.png" style={{ display: 'block', marginTop: '10px' }}>
                <DownloadButton variant="contained">
                  Download Generated Image
                </DownloadButton>
              </a>
            )}
          </Box>
          <Box textAlign="center" style={{ width: '30%' }}>
            {enhancedImageUrl && (
              <a href={enhancedImageUrl} download="enhanced_image.png" style={{ display: 'block', marginTop: '10px' }}>
                <DownloadButton variant="contained">
                  Download Enhanced Image
                </DownloadButton>
              </a>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AIImageGeneration;
