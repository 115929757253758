// src/utils/toastUtils.js
import { toast } from 'react-toastify';
import { config } from './config'; 

export const showToastError = (message) => {
  if (config.showToasts) {
    toast.error(message);
  }
};

export const showToastSuccess = (message) => {
  if (config.showToasts) {
    toast.success(message);
  }
};

export const logWithToast = (message, type = 'info') => {
  // Capture the stack trace
  const stack = new Error().stack;
  const stackLines = stack.split('\n');
  const callerLine = stackLines[2] || ''; // Get the line where logWithToast was called

  // Extract filename and line number
  const match = callerLine.match(/at\s+(.*):(\d+):(\d+)/);
  const location = match ? `${match[1]}:${match[2]}` : 'unknown location';

  // Append location to the message
  const fullMessage = `${message} (${location})`;

  // Ensure the type is valid for console methods
  const validTypes = ['log', 'warn', 'error', 'info'];
  const consoleType = validTypes.includes(type) ? type : 'log';

  console[consoleType](fullMessage); // Use the validated console type

  const toastOptions = {
    closeOnClick: true,
    draggable: true,
    pauseOnHover: true,
    autoClose: true, // Require manual dismissal
  };

  if (config.showToasts) {
    switch (type) {
      case 'error':
        toast.error(fullMessage, toastOptions);
        break;
      case 'warn':
        toast.warn(fullMessage, toastOptions);
        break;
      case 'success':
        toast.success(fullMessage, toastOptions);
        break;
      default:
        toast.info(fullMessage, toastOptions);
    }
  }
};