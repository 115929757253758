import React, { useState } from 'react';
import {TextField, Box, Typography, InputAdornment, Button, Avatar} from '@mui/material';
import {MailOutline, PhoneIphone, Business, ConfirmationNumber, Gavel, Home, LocationCity, LocationOn, Public, Place, Check, ContactPage} from '@mui/icons-material';
import theme from '../../theme';
import { AddressAutofill } from '@mapbox/search-js-react';

const DEFAULT_LOGO = '/images/avatars/painter.webp';
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;


const AccountInfo = ({
  accountState,
  handleInputChange,
  handleImageUpload,
  handleSubmit,
  updateSuccess,
  isUpdating,
  newLogoFile,
  setNewLogoFile,
}) => {
  const [addressError, setAddressError] = useState(null);

  const handleAddressChange = (field, value) => {
    handleInputChange(field, value);
  };

  return (
    <Box sx={theme.customFormBox}>
      <Box sx={theme.customLogoBox} onClick={() => document.getElementById('company-logo-input').click()}>
        <Box sx={theme.customLogoGreyBox} />
        <Avatar
          sx={theme.customLogoAvatar}
          src={accountState.logo || DEFAULT_LOGO}
          onClick={(e) => {
            e.stopPropagation();
            document.getElementById('company-logo-input').click();
          }}
        />
      </Box>

      <input
        id="company-logo-input"
        type="file"
        hidden
        onChange={handleImageUpload}
      />

      <Box sx={{ ...theme.customFormBox }}>
      <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            fontFamily: '"Figtree", sans-serif',
          }}
          gutterBottom
        >
          Personal Details
        </Typography>
      <TextField
          label="First Name"
          value={accountState.ownerFirstName}
          onChange={(e) => handleInputChange('ownerFirstName', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ContactPage />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Surname"
          value={accountState.ownerSurname}
          onChange={(e) => handleInputChange('ownerSurname', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ContactPage />
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="subtitle1" sx={{fontWeight: 'bold', fontSize: '1.2rem', fontFamily: '"Figtree", sans-serif', }} gutterBottom >
          Contact Details
        </Typography>
      <TextField
          label="Phone Number"
          value={accountState.phoneNumber}
          onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{...theme.inputStyle,'& .MuiOutlinedInput-root': { border: 'none' },}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIphone />
              </InputAdornment>
            ),
          }}
        />
       <TextField
          label="Email Address"
          value={accountState.email}
          onChange={(e) => handleInputChange('email', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
              ...theme.inputStyle,
              '& .MuiOutlinedInput-root': { border: 'none' },
              '& .MuiInputBase-input': { fontSize: '0.775rem' } // Adjust font size here
          }}
          InputProps={{
              startAdornment: (
                  <InputAdornment position="start">
                      <MailOutline />
                  </InputAdornment>
              ),
          }}
      />

        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            fontFamily: '"Figtree", sans-serif',
          }}
          gutterBottom
        >
          Company Details
        </Typography>
        
        <TextField
          label="Company Name"
          value={accountState.companyName}
          onChange={(e) => handleInputChange('companyName', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Business />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Company Number"
          value={accountState.companyNumber}
          onChange={(e) => handleInputChange('companyNumber', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ConfirmationNumber />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="VAT Number"
          value={accountState.vatNumber}
          onChange={(e) => handleInputChange('vatNumber', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Gavel />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box sx={{ ...theme.customFormBox }}>
        <Typography variant="subtitle1" gutterBottom
          sx={{fontWeight: 'bold',fontSize: '1.2rem',fontFamily: '"Figtree", sans-serif',}}>
          Address Details
        </Typography>

        {/* Address Autofill Component for Street Address */}
        <AddressAutofill
              accessToken={MAPBOX_ACCESS_TOKEN}
              onRetrieve={(event) => {
                const feature = event.features?.[0]; 

                if (feature) {
                  const {properties} = feature; // Safely access 'properties'
                  const context = feature.context || []; // Safely default to an empty array if 'context' is undefined

                  // Safely extract relevant data from the properties and context
                  const addressNumber = properties.address_number || '';
                  const street = properties.street || feature.text_en || ''; // Use 'text_en' as fallback for the street
                  const postcode = properties.postcode || context.find((c) => c.id.includes('postcode'))?.text || '';
                  const place = properties.place || context.find((c) => c.id.includes('place'))?.text || '';
                  const district = properties.district || context.find((c) => c.id.includes('district'))?.text || '';
                  const region = properties.region || context.find((c) => c.id.includes('region'))?.text || '';
                  const country = properties.country || context.find((c) => c.id.includes('country'))?.text || '';

                  // Update the form fields using handleInputChange
                  handleInputChange('streetAddress', `${addressNumber} ${street}`.trim());
                  handleInputChange('city', place);
                  handleInputChange('state', district);
                  handleInputChange('postalCode', postcode);
                  handleInputChange('country', country);
                } else {
                  console.warn('No valid feature found in the response.');
                }
              }}
            >
              <TextField
                label="Street Address"
                value={accountState.streetAddress}
                onChange={(e) => handleAddressChange('streetAddress', e.target.value)}
                fullWidth
                variant="outlined"
                sx={{
                  ...theme.inputStyle,
                  '& .MuiOutlinedInput-root': { border: 'none' },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Home />
                    </InputAdornment>
                  ),
                }}
              />
            </AddressAutofill>




        <TextField
          label="City"
          value={accountState.city}
          onChange={(e) => handleAddressChange('city', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationCity />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="State/Province"
          value={accountState.state}
          onChange={(e) => handleAddressChange('state', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationOn />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Postal/ZIP Code"
          value={accountState.postalCode}
          onChange={(e) => handleAddressChange('postalCode', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Place />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Country"
          value={accountState.country}
          onChange={(e) => handleAddressChange('country', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Public />
              </InputAdornment>
            ),
          }}
        />

        {addressError && (
          <Typography color="error" sx={{ mt: 1 }}>
            {addressError}
          </Typography>
        )}

        <Button
          variant="contained"
          color={updateSuccess ? 'success' : 'primary'}
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
          disabled={isUpdating}
        >
          {isUpdating
            ? 'Updating...'
            : updateSuccess
            ? 'Account Updated'
            : 'Update Details'}
          {updateSuccess && <Check style={{ marginLeft: '4px' }} />}
        </Button>
      </Box>
    </Box>
  );
};

export default AccountInfo;
