// ColourOptions.js
import React, { useState, useEffect, useMemo } from 'react';
import CustomTooltip from './components/CustomTooltip';
import { NavToggleButtonGroup, NavToggleButton} from './CustomToggleButton';
import { Box,FormControl,InputLabel,Select,MenuItem} from '@mui/material';
import { CropDin,SelectAll,CropFree,CallToAction,DoorFront} from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import theme from './theme';


// Define the color mapping for hues
const hueColors = {Blue: '#0000FF','Cool Neutral': '#A9A9A9',Gold: '#FFD700',Green: '#008000',
  Lime: '#00FF00',Orange: '#FFA500',
  Purple: '#800080',
  Red: '#FF0000',
  Teal: '#008080',
  'Warm Neutral': '#D2B48C',
  White: '#FFFFFF',
  Yellow: '#FFFF00',
  Grey: '#333333',
  Pink: '#FFC0CB',
  Cream: '#FF00FF',
  Metallic: '#000000',
  Neutral: '#225566',
};

// Utility function to get grayscale value for sorting
const getGrayscaleValue = (hexColor) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  return 0.299 * r + 0.587 * g + 0.114 * b;
};

// Styled components
const SwatchBox = styled(Box)(({ selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '3px',
  borderRadius: '3px',
  backgroundColor: '#fff',
  margin: '3px 3px',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.2s, box-shadow 0.2s, border 0.2s',
  transform: selected ? 'scale(0.9)' : 'none',
  border: selected ? '2px solid black' : 'none',
  '&:hover': {
    transform: 'scale(1.1)',
    border: '1px solid black',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.4)',
    cursor: 'pointer',
  },
}));

const SwatchColorBox = styled(Box)({
  width: '55px',
  height: '55px',
  borderRadius: '3px',
  padding: '3px',
  marginBottom: '10px',
  position: 'relative',
});


const ColourOptions = ({
  paintBrand,
  setPaintBrand,
  setPaintHue,
  setSelectedColor,
  saveSwatchWithRoom, 
}) => {
  const [paintBrands, setPaintBrands] = useState([]);
  const [paintHues, setPaintHues] = useState([]);
  const [savedPalettes, setSavedPalettes] = useState([]);
  const [selectedPalette, setSelectedPalette] = useState('');
  const [swatchData, setSwatchData] = useState([]);
  const [selectedSwatch, setSelectedSwatch] = useState(null);

  // Fetch and cache data to optimize performance
  const fetchAndCacheData = async (url, key) => {
    if (window.dataCache && window.dataCache[key]) {
      return window.dataCache[key];
    }

    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      if (!window.dataCache) window.dataCache = {};
      window.dataCache[key] = parsedData;
      return parsedData;
    }

    const response = await fetch(url);
    const data = await response.json();

    localStorage.setItem(key, JSON.stringify(data));
    if (!window.dataCache) window.dataCache = {};
    window.dataCache[key] = data;

    return data;
  };

  // Fetch saved palettes from local storage
  const fetchSavedPalettes = () => {
    const savedPalettesData = JSON.parse(
      localStorage.getItem('savedPalettes') || '[]'
    );
    setSavedPalettes(savedPalettesData);

    if (savedPalettesData.length > 0 && !selectedPalette) {
      const firstPalette = savedPalettesData[0];
      setSelectedPalette(firstPalette.name);
      setSwatchData(firstPalette.swatchData);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      const brandData = await fetchAndCacheData(
        '/json/paintBrands.json',
        'paintBrands'
      );
      setPaintBrands(brandData.paintBrands);

      const hueData = await fetchAndCacheData(
        '/json/paintHues.json',
        'paintHues'
      );
      setPaintHues(hueData.paintHues);

      fetchSavedPalettes();
    };

    fetchData();

    // Update palettes when local storage changes
    window.addEventListener('storage', fetchSavedPalettes);
    return () => {
      window.removeEventListener('storage', fetchSavedPalettes);
    };
  }, []);

  // Set default palette if none is selected
  useEffect(() => {
    if (savedPalettes.length > 0 && !selectedPalette) {
      const firstPalette = savedPalettes[0];
      setSelectedPalette(firstPalette.name);
      setSwatchData(firstPalette.swatchData);
    }
  }, [savedPalettes]);

  // Compute available hues based on selected paint brand
  const availableHues = useMemo(() => {
    if (paintBrand) {
      const selectedBrand = paintBrands.find(
        (brand) => brand.name === paintBrand
      );
      if (selectedBrand) {
        const hues = paintHues.filter((hue) =>
          selectedBrand.hueIDs.includes(hue.hueID)
        );
        return hues.sort(
          (a, b) =>
            getGrayscaleValue(hueColors[a.name]) -
            getGrayscaleValue(hueColors[b.name])
        );
      }
    }
    return [];
  }, [paintBrand, paintBrands, paintHues]);

  // Handle changes in paint brand selection
  const handlePaintBrandChange = (event) => {
    const selectedBrandName = event.target.value;
    setPaintBrand(selectedBrandName);
    setPaintHue('');
  };

  // Handle changes in paint hue selection
  const handlePaintHueChange = (event) => {
    setPaintHue(event.target.value);
  };

  // Handle changes in palette selection
  const handlePaletteChange = (event) => {
    const paletteName = event.target.value;
    setSelectedPalette(paletteName);

    const palette = savedPalettes.find((p) => p.name === paletteName);
    if (palette) {
      setSwatchData(palette.swatchData);
    }
  };

  // Handle swatch click to save data with the room
  const handleSwatchClick = (swatch) => {
    setSelectedColor(swatch.rgb);
    setSelectedSwatch(swatch.rgb);

    // Save the swatch data with the room
    if (saveSwatchWithRoom) {
      saveSwatchWithRoom(swatch);
    }
  };

  // SwatchItem component to display individual swatches



const SwatchItem = React.memo(({ swatch, onClick, isSelected }) => (
  <Tooltip
    title={
      <>
        <Typography variant="body1" fontWeight="bold" display="block">
          {swatch.brand}
        </Typography>
        <Typography variant="body1" display="block">
          {swatch.name}
        </Typography>
      </>
    }
    arrow
  >
    <SwatchBox onClick={onClick} selected={isSelected}>
      <SwatchColorBox bgcolor={swatch.rgb}>
        {/* Display color preview only, with details in tooltip */}
      </SwatchColorBox>
    </SwatchBox>
  </Tooltip>
));


  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
      {/* Navigation Toggle Buttons */}
      <Box my={0}>
        <NavToggleButtonGroup value="" fullWidth multiple>
          <CustomTooltip title="Walls Only" value="paintWalls"><NavToggleButton value="paintWalls"><CropDin /></NavToggleButton></CustomTooltip>
          <CustomTooltip title=" + Ceiling" value="paintCeiling"><NavToggleButton value="paintCeiling"><SelectAll /></NavToggleButton></CustomTooltip>
          <CustomTooltip title=" + Skirting/Architrave" value="paintSkirtingArchitrave" ><NavToggleButton value="paintSkirtingArchitrave"><CropFree /></NavToggleButton></CustomTooltip>
          <CustomTooltip title=" + Doors" value="paintDoors"><NavToggleButton value="paintDoors"><DoorFront /></NavToggleButton></CustomTooltip>
          <CustomTooltip title=" + Windows" value="paintWindows"><NavToggleButton value="paintWindows"><CallToAction /></NavToggleButton></CustomTooltip></NavToggleButtonGroup>
      </Box>

      <Box sx={theme.innerBox}>
        <Box sx={{mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',}}>
          {/* Paint Brand Selection */}
          <FormControl fullWidth variant="outlined" sx={theme.selectFormControl}>
            <InputLabel style={{ fontSize: '18px', fontWeight: 'bold' }}>
              Paint Brand
            </InputLabel>
            <Select
              value={paintBrand || ''}
              onChange={handlePaintBrandChange}
              label="Paint Brand"
              sx={{
                fontSize: '18px',
                fontWeight: 'bold',
                '& .MuiSelect-select': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {paintBrands.map((brand) => (
                <MenuItem key={brand.brandID} value={brand.name}>
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Display Available Hues */}
          {paintBrand && (
            <Box display="flex" flexWrap="wrap" mt={2}>
              {availableHues.map((hue) => (
                <Box
                  key={hue.hueID}
                  onClick={() =>
                    handleSwatchClick({
                      rgb: hueColors[hue.name],
                      brand: paintBrand,
                      colourCategory: hue.name,
                      name: hue.name,
                    })
                  }
                  width={50}
                  height={50}
                  bgcolor={hueColors[hue.name]}
                  borderRadius="4px"
                  m={1}
                  style={{ cursor: 'pointer' }}
                />
              ))}
            </Box>
          )}

          {/* Palette Selection */}
          <FormControl
            fullWidth
            margin="normal"
            sx={theme.selectFormControl}
          >
            <InputLabel style={{ fontSize: '18px', fontWeight: 'bold' }}>
              Select Palette
            </InputLabel>
            <Select
              value={selectedPalette}
              onChange={handlePaletteChange}
              label="Select Palette"
              sx={{
                fontSize: '18px',
                fontWeight: 'bold',
                '& .MuiSelect-select': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {savedPalettes.map((palette, index) => (
                <MenuItem key={index} value={palette.name}>
                  {palette.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Display Swatches from Selected Palette */}
          <Box
            p={1}
            border="0px solid white"
            borderRadius="8px"
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.4)' }}
          >
            <Box display="flex" justifyContent="left" mb={2}>
              <img
                src="/images/logos/logo_1.webp"
                alt="Logo"
                style={{ maxHeight: '100px' }}
              />
            </Box>
            {swatchData.length > 0 && (
              <Box
                display="grid"
                gridTemplateColumns="repeat(3, 1fr)"
                gap={0}
                mt={2}
              >
                {swatchData
                  .sort((a, b) => {
                    if (a.colourCategory === b.colourCategory) {
                      // Sort by luminance if in the same category
                      const getLuminance = (rgb) => {
                        const rgbArray = rgb.match(/\d+/g).map(Number);
                        return (
                          0.2126 * (rgbArray[0] / 255) +
                          0.7152 * (rgbArray[1] / 255) +
                          0.0722 * (rgbArray[2] / 255)
                        );
                      };
                      return getLuminance(b.rgb) - getLuminance(a.rgb);
                    }
                    // Sort by colourCategory
                    return a.colourCategory.localeCompare(b.colourCategory);
                  })
                  .map((swatch, index) => (
                    <SwatchItem
                      key={index}
                      swatch={swatch}
                      onClick={() => handleSwatchClick(swatch)}
                      isSelected={selectedSwatch === swatch.rgb}
                    />
                  ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ColourOptions;
