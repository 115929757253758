//useFetchAndCacheData.js

import { useState, useEffect } from 'react';

const useFetchAndCacheData = (url, key) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
           if (window.dataCache && window.dataCache[key]) {
            setData(window.dataCache[key]);
            setLoading(false);
            return;
          }
  
          const cachedData = localStorage.getItem(key);
          if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            if (!window.dataCache) window.dataCache = {};
            window.dataCache[key] = parsedData;
            setData(parsedData);
            setLoading(false);
            return;
          }
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Failed to fetch data from ${url}`);
          }
          const fetchedData = await response.json();
  
          localStorage.setItem(key, JSON.stringify(fetchedData));
          if (!window.dataCache) window.dataCache = {};
          window.dataCache[key] = fetchedData;
          setData(fetchedData);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [url, key]);
  
    return { data, loading, error };
  };
  
export default useFetchAndCacheData;
