// buildRoomPrompt.js
import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function buildRoomPrompt(roomData) {
  const { name, width, length, walls, doorColour, windowColour, ceilingColour, architraveColour, skirtingBoardColour } = roomData;

  const describeColor = (rgbString) => {
    const rgbValues = rgbString.match(/\d+/g).map(Number);
    return `rgb(${rgbValues.join(', ')})`;
  };

  let prompt = `Generate a super wide-angle magazine style cover photo from a 45-degree angle of a ${name.toLowerCase()} in a house. The room dimensions are ${width}cm wide and ${length}cm long. The walls have the following colors: `;

  if (walls) {
    if (walls.top) prompt += `The main front wall is painted in ${describeColor(walls.top.color)}. `;
    if (walls.left) prompt += `The left wall is painted in ${describeColor(walls.left.color)}. `;
    if (walls.right) prompt += `The right wall is painted in ${describeColor(walls.right.color)}. `;
  }

  if (ceilingColour && ceilingColour.color) prompt += `The ceiling is painted in ${describeColor(ceilingColour.color)}. `;
  if (doorColour && doorColour.color) prompt += `The doors are painted in ${describeColor(doorColour.color)}. `;
  if (windowColour && windowColour.color) prompt += `The windows are painted in ${describeColor(windowColour.color)}. `;
  if (architraveColour && architraveColour.color) prompt += `The architrave is painted in ${describeColor(architraveColour.color)}. `;
  if (skirtingBoardColour && skirtingBoardColour.color) prompt += `The skirting boards are painted in ${describeColor(skirtingBoardColour.color)}. `;

  prompt += `The room is well lit and captures the colors vividly.`;

  // Send the prompt to the API and replace the prompt with the response
  try {
    const response = await axios.post(`${API_ENDPOINT}/api/ai-colour`, { colourData: prompt }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log('Prompt generated successfully:', response.data);
    return response.data.prompt; // Use the actual prompt from the API response
  } catch (err) {
    console.error('Error generating prompt:', err);
    throw new Error('Error generating prompt');
  }
}
