import axios from 'axios';
import { logWithToast } from '../logUtils';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// Fetch jobs for a user
export const fetchJobs = async (userId) => {
  try {
    const response = await axios.get(`${API_ENDPOINT}/api/jobs`, {
      params: { userId }
    });
    return response.data ?? [];
  } catch (error) {
    logWithToast(`Error fetching jobs: ${error.message}`, 'error');
    return [];
  }
}


// Fetch clients for a user
export const fetchClients = async (userId) => {
  try {
    const response = await axios.get(`${API_ENDPOINT}/api/clients`, {
      params: { userId }
    });
    return response.data;
  } catch (error) {
    logWithToast(`Error fetching clients: ${error.message}`, 'error');
    return [];
  }
};

// Create a new job
export const createJob = async (jobData) => {
  try {
    const response = await axios.post(`${API_ENDPOINT}/api/jobs`, jobData);
    const newJob = response.data;

    const appData = JSON.parse(localStorage.getItem('appData'));
    appData.jobs.push(newJob);
    localStorage.setItem('appData', JSON.stringify(appData));

    return newJob;
  } catch (error) {
    logWithToast(`Error creating job: ${error.message}`, 'error');
    throw error; // Re-throw the error if you want the calling function to handle it
  }
};

// Update an existing job

const updateLocalStorage = (jobId, updatedJob) => {
  const appData = JSON.parse(localStorage.getItem('appData'));
  const jobIndex = appData.jobs.findIndex(job => job._id === jobId);
  if (jobIndex !== -1) {
    appData.jobs[jobIndex] = updatedJob;
    localStorage.setItem('appData', JSON.stringify(appData));
    logWithToast('Local storage updated', 'info');
  } else {
    logWithToast('Job not found in local storage', 'warn');
  }
};

export const updateJob = async (jobId, jobData) => {
  if (!jobId) {
    throw new Error('Job ID is required for updating a job');
  }

  logWithToast(`Updating job with ID: ${jobId}\nJob data: ${JSON.stringify(jobData)}`, 'info');

  try {
    // Ensure you're sending the full job structure, not just partial data
    const response = await axios.put(`${API_ENDPOINT}/api/jobs/${jobId}`, jobData);
    const updatedJob = response.data;

    logWithToast(`Job updated successfully: ${JSON.stringify(updatedJob)}\nUpdate job response: ${JSON.stringify(response.data)}`, 'success');

    // Merge the updated data with the existing data
    updateLocalStorage(jobId, updatedJob);

    return updatedJob;
  } catch (error) {
    logWithToast(`Error in updateJob: ${error.message}`, 'error');
    throw error;
  }
};



// Delete a specific job
export const deleteJob = async (jobId) => {
  try {
    const response = await axios.delete(`${API_ENDPOINT}/api/jobs/${jobId}`);

    const appData = JSON.parse(localStorage.getItem('appData'));
    appData.jobs = appData.jobs.filter(job => job._id !== jobId);
    localStorage.setItem('appData', JSON.stringify(appData));

    return response.data;
  } catch (error) {
    // handleError(error);
  }
};

// Upload floorplan
export const uploadFloorplan = async (formData) => {
  try {
    const response = await axios.post(`${API_ENDPOINT}/api/upload-floorplan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    // handleError(error);
  }
};

// Add a room to a floor
export const addRoom = async (roomData) => {
  try {
    const response = await axios.post(`${API_ENDPOINT}/api/rooms`, roomData);
    return response.data;
  } catch (error) {
    // handleError(error);
  }
};
