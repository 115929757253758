// CustomSwitch.js
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 60, // Switch width
    height: 34, // Switch height
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(27px)', // Adjust this value to match the width of the switch
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 1,
          '&:hover': {
            backgroundColor: '#2ECA45',
          },
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        '&:hover': {
          color: theme.palette.grey[600],
        },
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        '&:hover': {
          opacity: 0.3,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 29, // Thumb width
      height: 29, // Thumb height
    },
    '& .MuiSwitch-track': {
      borderRadius: 32 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      '&:hover': {
        backgroundColor: '#39393D',
      },
    },
  }));
  
  export default IOSSwitch;
