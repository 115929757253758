import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { SocialDistance, Handyman, Copyright, EditCalendar, LockOpen } from '@mui/icons-material';
import { Box } from '@mui/material';
import CustomTooltip from './components/CustomTooltip';
import theme from './theme'; 
import { NavToggleButtonGroup, NavToggleButton, InPageToggleButtonGroup, InPageToggleButton } from './CustomToggleButton'
import ContractProject from './components/Contracts/ContractProject';
import ContractScope from './components/Contracts/ContractScope';
import ContractTimeline from './components/Contracts/ContractTimeline';
import ContractAccess from './components/Contracts/ContractAccess';
import ContractLegal from './components/Contracts/ContractLegal';

const ContractorPanel = () => {
    const [contractState, setContractState] = useState({
        jobName: '',
        contractDate: new Date(),
        contractorDetails: { name: '', company: '', phone: '', email: '' },
        clientDetails: { name: '', address: '' },
        additionalContactPerson: '',
        rolesAndResponsibilities: '',
        projectDescription: '',
        tasks: [],
        materialsAndColors: '',
        extraServices: '',
        startDate: null,
        endDate: null,
        milestones: [{ description: '', date: null }],
        propertyAccessStartTime: '',
        propertyAccessEndTime: '',
        accessInstructions: '',
        alarmCodes: [''],
        clientPreparation: '',
        utilitiesAccess: '',
        proofOfInsurance: false,
        policyExpiration: null,
        insuranceProviderInfo: '',
        generalLiabilityCoverageScope: '',
        generalLiabilityCoverageLimit: 5000000,
        generalLiabilityExclusions: '',
        workersCompCoverageScope: '',
        workersCompVerification: false,
        propertyDamageCoverageScope: '',
        highValueItemProtection: false,
        personalInjuryCoverageScope: '',
        injuryClaimsProtection: false,
        professionalLiabilityCoverageScope: '',
        professionalLiabilityLimitations: '',
        completedOperationsCoverageScope: '',
        completedOperationsDuration: 0,
        environmentalLiabilityCoverageScope: '',
        environmentalRelevance: false,
        updateSuccess: false,
    });

    const [selectedOption, setSelectedOption] = useState('overview');
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        const storedAppData = localStorage.getItem('appData');
        if (storedAppData) {
            const { contract = {} } = JSON.parse(storedAppData);
            setContractState((prevState) => ({ ...prevState, ...contract }));
        }
    }, []);

    useEffect(() => {
        const storedAppData = localStorage.getItem('appData');
        const storedAppState = localStorage.getItem('appState');
        
        if (storedAppData && storedAppState) {
            const { account = {}, jobs = [] } = JSON.parse(storedAppData); // Use "account" and "jobs"
            const { currentJobID } = JSON.parse(storedAppState);
            
            const selectedJob = jobs.find(job => job._id === currentJobID);
            
            setContractState((prevState) => ({
                ...prevState,
                contactname: `${account.ownerFirstName || ''} ${account.ownerSurname || ''}`.trim(),
                contractDate: prevState.contractDate || new Date(),
                contractorDetails: {
                    name: account.primaryContactName || '',
                    company: account.companyName || '',
                    phone: account.phoneNumber || '',
                    email: account.email || '',
                },
                clientDetails: {
                    name: selectedJob?.ClientID?.clientFullName || '',
                    address: `${selectedJob?.jobStreetAddressOne || ''}, ${selectedJob?.jobCity || ''}, ${selectedJob?.jobState || ''}, ${selectedJob?.jobPostalCode || ''}, ${selectedJob?.jobCountry || ''}`
                },
                jobName: selectedJob?.JobDetails?.JobName || '',
                startDate: selectedJob?.JobDetails?.StartDate || null,
                endDate: selectedJob?.JobDetails?.EndDate || null,
                // Add more job details as needed
                additionalContactPerson: account.secondaryContactName || '',
                rolesAndResponsibilities: account.primaryContactPosition || '',
            }));
            
            console.log('Contract state updated with job details:', selectedJob); // Verify the job data
        } else {
            console.warn('No appData or appState found in localStorage');
        }
    }, []);
    

    const updateContractState = useCallback((field, value) => {
        setContractState((prevState) => {
            const keys = field.split('.');
            if (keys.length > 1) {
                return {
                    ...prevState,
                    [keys[0]]: { ...prevState[keys[0]], [keys[1]]: value },
                };
            }
            return { ...prevState, [field]: value };
        });
    }, []);

    const handleOptionChange = (event, newOption) => {
        if (newOption !== null) {
            setSelectedOption(newOption);
        }
    };

    const handleContractInputChange = (field, value) => {
        updateContractState(field, value);
    };

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        const userId = document.cookie.split('; ').find(row => row.startsWith('userId=')).split('=')[1];

        if (!contractState.contactName || !userId) {
            alert('Contact Name and User ID are required');
            return;
        }

        try {
            setIsUpdating(true);
            const updatedContractData = { ...contractState };

            const response = await axios.post(`${API_ENDPOINT}/api/contract`, { userId, ...updatedContractData });

            const storedAppData = JSON.parse(localStorage.getItem('appData')) || {};
            storedAppData.contract = response.data; 
            localStorage.setItem('appData', JSON.stringify(storedAppData)); 

            setIsUpdating(false);
            setUpdateSuccess(true);
            setTimeout(() => setUpdateSuccess(false), 3000);
        } catch (error) {
            console.error('Error updating contract:', error);
            setIsUpdating(false);
        }
    }, [contractState]);

    const renderOptionInputs = useCallback(() => {
        const commonProps = { contractState, handleContractInputChange, handleSubmit, updateSuccess, isUpdating };

        switch (selectedOption) {
            case 'overview':
                return <ContractProject {...commonProps} />;
            case 'scope':
                return <ContractScope {...commonProps} />;
            case 'timeline':
                return <ContractTimeline {...commonProps} />;
            case 'access':
                return <ContractAccess {...commonProps} />;
            case 'legal':
                return <ContractLegal {...commonProps} />;
            default:
                return null;
        }
    }, [selectedOption, contractState, handleContractInputChange, handleSubmit, updateSuccess, isUpdating]);

    return (
        <Box p={0}>
            <Box my={0}>
                <NavToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth>
                    <CustomTooltip title="Contract - Overview"><NavToggleButton value="overview"><SocialDistance /></NavToggleButton></CustomTooltip>
                    <CustomTooltip title="Scope"><NavToggleButton value="scope"><Handyman /></NavToggleButton></CustomTooltip>
                    <CustomTooltip title="Timeline"><NavToggleButton value="timeline"><EditCalendar /></NavToggleButton></CustomTooltip>
                    <CustomTooltip title="Property Access"><NavToggleButton value="access"><LockOpen /></NavToggleButton></CustomTooltip>
                    <CustomTooltip title="Legal"><NavToggleButton value="legal"><Copyright /></NavToggleButton></CustomTooltip>
                </NavToggleButtonGroup>
            </Box>
            {renderOptionInputs()}
        </Box>
    );
};

export default ContractorPanel;
