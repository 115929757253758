import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, InputAdornment, Button, Avatar, List, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import { Person, Mail, Phone, Home, LocationCity, LocationOn, Place, Public, Add, Check, RecentActors } from '@mui/icons-material';
import { AddressAutofill } from '@mapbox/search-js-react';
import axios from 'axios';
import { NavToggleButtonGroup, NavToggleButton, InPageToggleButtonGroup, InPageToggleButton } from './CustomToggleButton'

import theme from './theme';

const ClientPanel = () => {
  const INITIAL_CLIENT_STATE = {
    clientFullName: '',
    clientEmail: '',
    clientPhone: '',
    clientStreetAddress: '',
    clientCity: '',
    clientState: '',
    clientPostalCode: '',
    clientCountry: '',
    clientPhoto: '/images/avatars/painter.webp',
    preferredContactMethod: '',
    notes: '',
    isActive: true,
    tags: [],
  };

  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newClient, setNewClient] = useState(INITIAL_CLIENT_STATE);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState('list');
  const [newPhotoFile, setNewPhotoFile] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
  const DEFAULT_AVATAR = '/images/avatars/painter.webp';

  const handleAddNewClientClick = () => {
    setSelectedClientId(null);
    setNewClient(INITIAL_CLIENT_STATE);
    setNewPhotoFile(null);
    setSelectedOption('add');
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const userId = getCookie('userId');

  useEffect(() => {
    if (userId) {
      fetchClients();
    }
    setDeleteConfirmation(false);
  }, [userId, selectedClientId]);

  const fetchClients = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_ENDPOINT}/api/clients`, {
        params: { userId }
      });
      setClients(response.data);
      updateLocalStorage(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateLocalStorage = (clientsData) => {
    const appData = JSON.parse(localStorage.getItem('appData')) || {};
    appData.clients = clientsData;
    localStorage.setItem('appData', JSON.stringify(appData));
  };

  const handleInputChange = (field, value) => {
    setNewClient((prev) => ({ ...prev, [field]: value }));
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewPhotoFile(file);
      setNewClient((prev) => ({ ...prev, clientPhoto: URL.createObjectURL(file) }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!newClient.clientFullName) {
      alert('Client Full Name is required');
      return;
    }

    if (!userId) {
      alert('User ID is missing. Please log in again.');
      return;
    }

    try {
      setIsUpdating(true);
      let clientData = { ...newClient, userId };

      if (newPhotoFile) {
        const formData = new FormData();
        formData.append('clientPhoto', newPhotoFile);
        formData.append('userId', userId);

        const photoResponse = await axios.post(`${API_ENDPOINT}/api/upload-client-photo`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        clientData.clientPhoto = photoResponse.data.photoUrl;
      }

      if (selectedClientId) {
        await axios.patch(`${API_ENDPOINT}/api/clients/${selectedClientId}`, clientData);
      } else {
        await axios.post(`${API_ENDPOINT}/api/clients`, clientData);
      }

      await fetchClients();
      setIsUpdating(false);
      setUpdateSuccess(true);
      setNewPhotoFile(null);
      setTimeout(() => {
        setUpdateSuccess(false);
        setSelectedOption('list');
      }, 3000);
    } catch (error) {
      setIsUpdating(false);
      console.error('Error adding/updating client:', error);
      alert(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleDeleteClick = () => {
    if (deleteConfirmation) {
      handleDeleteClient();
    } else {
      setDeleteConfirmation(true);
    }
  };

  const handleDeleteClient = async () => {
    if (!selectedClientId) return;

    try {
      await axios.delete(`${API_ENDPOINT}/api/clients/${selectedClientId}`);
      await fetchClients();

      setNewClient(INITIAL_CLIENT_STATE);
      setSelectedClientId(null);
      setSelectedOption('list');
      setDeleteConfirmation(false);
    } catch (error) {
      console.error('Error deleting client:', error);
      alert(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
      if (newOption === 'add') {
        setSelectedClientId(null);
        setNewClient(INITIAL_CLIENT_STATE);
        setNewPhotoFile(null);
      }
    }
  };

  const handleClientClick = (client) => {
    setSelectedClientId(client._id);
    setNewClient({
      clientFullName: client.clientFullName,
      clientEmail: client.clientEmail,
      clientPhone: client.clientPhone,
      clientStreetAddress: client.clientStreetAddress,
      clientCity: client.clientCity,
      clientState: client.clientState,
      clientPostalCode: client.clientPostalCode,
      clientCountry: client.clientCountry,
      clientPhoto: client.clientPhoto,
      preferredContactMethod: client.preferredContactMethod,
      notes: client.notes,
      isActive: client.isActive,
      tags: client.tags,
    });
    setNewPhotoFile(null);
    setSelectedOption('add');
  };

  const renderAddClient = () => (
    <Box sx={{...theme.customFormBox, backgroundColor: 'rgb(255,255,255,0.6)'}}>
      <Box sx={theme.customLogoBox}>
        <Box sx={theme.customLogoGreyBox} />
        <Avatar
          sx={theme.customLogoAvatar}
          src={newClient.clientPhoto || DEFAULT_AVATAR}
          onClick={(e) => {
            e.stopPropagation();
            document.getElementById('client-photo-input').click();
          }}
        />
      </Box>
  
      <input id="client-photo-input" type="file" hidden onChange={handleImageUpload} />
  
      <Box sx={{...theme.customFormBox, backgroundColor: 'rgb(255,255,255,0.0)', backdropFilter: 'blur(15px)', WebkitBackdropFilter: 'blur(15px)'}}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', fontFamily: '"Figtree", sans-serif' }} gutterBottom>
          Client Details
        </Typography>
  
        <TextField
          label="Full Name"
          name="clientFullName"
          value={newClient.clientFullName}
          onChange={(e) => handleInputChange('clientFullName', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '.MuiOutlinedInput-root': {
              padding: '8px', 
              fontSize: '1.25rem', 
              minHeight: '50px', 
              borderRadius: '12px', 
              '&.Mui-focused': {
                backgroundColor: 'white', 
                fontWeight: 'bold', 
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1565c0', 
              },
            },
            '.MuiInputLabel-root': {
              fontSize: '1rem', 
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            ),
          }}
        />

  
        <TextField
          label="Email Address"
          name="clientEmail"
          value={newClient.clientEmail}
          onChange={(e) => handleInputChange('clientEmail', e.target.value)}
          fullWidth
          sx={{
            ...theme.inputStyle,
            '.MuiOutlinedInput-root': {
              padding: '8px', 
              fontSize: '1.25rem', 
              minHeight: '50px', 
              borderRadius: '12px', 
              '&.Mui-focused': {
                backgroundColor: 'white', 
                fontWeight: 'bold', 
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1565c0', 
              },
            },
            '.MuiInputLabel-root': {
              fontSize: '1rem', 
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Mail />
              </InputAdornment>
            ),
          }}
        />
  
        <TextField
          label="Phone Number"
          name="clientPhone"
          value={newClient.clientPhone}
          onChange={(e) => handleInputChange('clientPhone', e.target.value)}
          fullWidth
          sx={{
            ...theme.inputStyle,
            '.MuiOutlinedInput-root': {
              padding: '8px', 
              fontSize: '1.25rem', 
              minHeight: '50px', 
              borderRadius: '12px', 
              '&.Mui-focused': {
                backgroundColor: 'white', 
                fontWeight: 'bold', 
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1565c0', 
              },
            },
            '.MuiInputLabel-root': {
              fontSize: '1rem', 
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Phone />
              </InputAdornment>
            ),
          }}
        />
      </Box>
  
      <Box sx={theme.customFormBox}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', fontFamily: '"Figtree", sans-serif' }} gutterBottom>
          Address Details
        </Typography>
  
        {/* AddressAutofill wrapping the Street Address */}
        <AddressAutofill
          accessToken={MAPBOX_ACCESS_TOKEN}
          onRetrieve={(event) => {
            const feature = event.features?.[0];
            if (feature) {
              const {properties} = feature;
              const context = feature.context || [];
  
              const addressNumber = properties.address_number || '';
              const street = properties.street || feature.text_en || '';
              const postcode = properties.postcode || context.find((c) => c.id.includes('postcode'))?.text || '';
              const place = properties.place || context.find((c) => c.id.includes('place'))?.text || '';
              const district = properties.district || context.find((c) => c.id.includes('district'))?.text || '';
              const region = properties.region || context.find((c) => c.id.includes('region'))?.text || '';
              const country = properties.country || context.find((c) => c.id.includes('country'))?.text || '';
  
              handleInputChange('clientStreetAddress', `${addressNumber} ${street}`.trim());
              handleInputChange('clientCity', place);
              handleInputChange('clientState', region);
              handleInputChange('clientPostalCode', postcode);
              handleInputChange('clientCountry', country);
            } else {
              console.warn('No valid feature found in the response.');
            }
          }}
        >
          <TextField
            label="Street Address"
            name="clientStreetAddress"
            value={newClient.clientStreetAddress}
            onChange={(e) => handleInputChange('clientStreetAddress', e.target.value)}
            fullWidth
            variant="outlined"
            sx={{
              ...theme.inputStyle,
              '.MuiOutlinedInput-root': {
                padding: '8px', 
                fontSize: '1.25rem', 
                minHeight: '50px', 
                borderRadius: '12px', 
                '&.Mui-focused': {
                  backgroundColor: 'white', 
                  fontWeight: 'bold', 
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#1565c0', 
                },
              },
              '.MuiInputLabel-root': {
                fontSize: '1rem', 
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Home />
                </InputAdornment>
              ),
            }}
          />
        </AddressAutofill>
  
        <TextField
            label="City"
            name="clientCity"
            value={newClient.clientCity}
            onChange={(e) => handleInputChange('clientCity', e.target.value)}
            fullWidth
            variant="outlined"
            sx={{
              ...theme.inputStyle,
              '.MuiOutlinedInput-root': {
                padding: '8px', 
                fontSize: '1.25rem', 
                minHeight: '50px', 
                borderRadius: '12px', 
                '&.Mui-focused': {
                  backgroundColor: 'white', 
                  fontWeight: 'bold', 
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#1565c0', 
                },
              },
              '.MuiInputLabel-root': {
                fontSize: '1rem', 
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCity />
                </InputAdornment>
              ),
            }}
          />


  
        <TextField
          label="State/Province"
          name="clientState"
          value={newClient.clientState}
          onChange={(e) => handleInputChange('clientState', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '.MuiOutlinedInput-root': {
              padding: '8px', 
              fontSize: '1.25rem', 
              minHeight: '50px', 
              borderRadius: '12px', 
              '&.Mui-focused': {
                backgroundColor: 'white', 
                fontWeight: 'bold', 
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1565c0', 
              },
            },
            '.MuiInputLabel-root': {
              fontSize: '1rem', 
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationOn />
              </InputAdornment>
            ),
          }}
        />
  
        <TextField
          label="Postal/ZIP Code"
          name="clientPostalCode"
          value={newClient.clientPostalCode}
          onChange={(e) => handleInputChange('clientPostalCode', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '.MuiOutlinedInput-root': {
              padding: '8px', 
              fontSize: '1.25rem', 
              minHeight: '50px', 
              borderRadius: '12px', 
              '&.Mui-focused': {
                backgroundColor: 'white', 
                fontWeight: 'bold', 
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1565c0', 
              },
            },
            '.MuiInputLabel-root': {
              fontSize: '1rem', 
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Place />
              </InputAdornment>
            ),
          }}
        />
  
        <TextField
          label="Country"
          name="clientCountry"
          value={newClient.clientCountry}
          onChange={(e) => handleInputChange('clientCountry', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '.MuiOutlinedInput-root': {
              padding: '8px', 
              fontSize: '1.25rem', 
              minHeight: '50px', 
              borderRadius: '12px', 
              '&.Mui-focused': {
                backgroundColor: 'white', 
                fontWeight: 'bold', 
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1565c0', 
              },
            },
            '.MuiInputLabel-root': {
              fontSize: '1rem', 
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Public />
              </InputAdornment>
            ),
          }}
        />
  
        <Button
          variant="contained"
          color={updateSuccess ? 'success' : 'primary'}
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
          disabled={isUpdating}
        >
          {isUpdating ? 'Saving...' : updateSuccess ? 'Client Saved' : selectedClientId ? 'Update Client' : 'Add Client'}
          {updateSuccess && <Check style={{ marginLeft: '8px' }} />}
        </Button>
  
        {selectedClientId && (
          <Button
            variant="contained"
            color={deleteConfirmation ? 'warning' : 'error'}
            onClick={handleDeleteClick}
            fullWidth
            sx={{ mt: 2 }}
          >
            {deleteConfirmation ? 'ARE YOU SURE?' : 'Delete Client'}
          </Button>
        )}
      </Box>
    </Box>
  );

  const renderClientList = () => (
    <Box sx={theme.innerBox}>
      <Box sx={{ mb: 2, border: '1px solid #fff', borderRadius: '8px', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '1.4rem', fontFamily: '"Figtree", sans-serif', paddingLeft: '8px' }}>
          Client List
        </Typography>
        <List>
          {isLoading ? (
            <ListItem>
              <ListItemText primary="Loading clients..." />
            </ListItem>
          ) : Array.isArray(clients) && clients.length > 0 ? (
            clients.map((client, index) => (
              <React.Fragment key={client._id}>
                <Box sx={{ mb: 2, border: '2px solid #fff', borderRadius: '8px', p: 0, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                  <ListItem onClick={() => handleClientClick(client)} sx={theme.listItem}>
                    <ListItemAvatar sx={{ minWidth: 'auto', marginRight: 1 }}>
                      <Avatar
                        src={client.clientPhoto || DEFAULT_AVATAR}
                        sx={{
                          width: 48,
                          height: 48,
                          backgroundColor: '#f0f0f0',
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<span style={theme.listItemTextName}>{client.clientFullName}</span>}
                      secondary={<span style={theme.listItemText}>{`${client.clientEmail} ${client.clientPhone}`}</span>}
                    />
                  </ListItem>
                </Box>
              </React.Fragment>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No clients added yet." />
            </ListItem>
          )}
        </List>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddNewClientClick} 
          fullWidth
          sx={{ mt: 1 }}
        >
          Add New Client
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
      <Box my={0}>
        <NavToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth>
          <NavToggleButton value="list">
            <RecentActors />
          </NavToggleButton>
          <NavToggleButton value="add">
            <Add />
          </NavToggleButton>
        </NavToggleButtonGroup>
      </Box>
      {selectedOption === 'add' ? renderAddClient() : renderClientList()}
    </Box>
  );
};

export default ClientPanel;
