import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Box, Button, Checkbox, FormControlLabel, Typography, Divider, IconButton, Popover } from '@mui/material';
import { Check, HelpOutline } from '@mui/icons-material';
import theme from '../../theme';
import CustomSlider from '../../CustomSlider';

const ContractLegal = ({
    contractState,
    handleContractInputChange,
    handleSubmit,
    updateSuccess,
    isUpdating,
}) => {

    const [forceRender, setForceRender] = useState(false);

    const handleSliderChange = (value) => {
        handleContractInputChange('generalLiabilityCoverageLimit', value);
        setForceRender(!forceRender); // Toggle to force re-render
    };

    
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState('');

    const handlePopoverOpen = (event, content) => {
        setAnchorEl(event.currentTarget);
        setPopoverContent(content);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverContent('');
    };

    const open = Boolean(anchorEl);

  return (
    <Box sx={theme.innerBox}>
      <Box sx={{ mb: 3, p: 1, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
      <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
       
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Proof of Insurance
            </Typography>
            <IconButton 
                onClick={(e) => handlePopoverOpen(e, 'This is the description for General Liability Insurance. It covers potential liabilities and damages.')}
                size="small"
            >
                <HelpOutline fontSize="small" />
            </IconButton>
            </Box>
       <FormControlLabel
         control={<Checkbox checked={contractState.proofOfInsurance} onChange={(e) => handleContractInputChange('proofOfInsurance', e.target.checked)} />}
         label="Valid (COI) Available"
         sx={{ mb: 3 }}
       />
       <TextField
            label="Policy Expiration"
            value={contractState.policyExpiration}
            onChange={(e) => handleContractInputChange('policyExpiration', e.target.value)}
            fullWidth
            variant="outlined"
            type="date"
            InputLabelProps={{
            shrink: true,
            }}
            sx={theme.inputStyle}
       />
       <TextField
            label="Insurance Provider Information"
            value={contractState.insuranceProviderInfo}
            onChange={(e) => handleContractInputChange('insuranceProviderInfo', e.target.value)}
            fullWidth
            variant="outlined"
            multiline
            rows={3}
            sx={theme.inputStyle}
       />
    </Box>
    <Divider sx={{ my: 2 }} /> 
      <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
      
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                General Liability
            </Typography>
            <IconButton 
                onClick={(e) => handlePopoverOpen(e, 'This is the description for General Liability Insurance. It covers potential liabilities and damages.')}
                size="small"
            >
                <HelpOutline fontSize="small" />
            </IconButton>
            </Box>
        <TextField
          label="Coverage Scope"
          value={contractState.generalLiabilityCoverageScope}
          onChange={(e) => handleContractInputChange('generalLiabilityCoverageScope', e.target.value)}
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}

        />
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Coverage - {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(contractState.generalLiabilityCoverageLimit)}

        </Typography>
        <CustomSlider
            value={contractState.generalLiabilityCoverageLimit ?? 0} 
            onChange={(e, value) => handleSliderChange(value)}
            min={1000000}
            max={10000000}
            step={50000}
            valueLabelDisplay="auto"
            sx={{ mb: 2 }}
        />

        <TextField
          label="Exclusions"
          value={contractState.generalLiabilityExclusions}
          onChange={(e) => handleContractInputChange('generalLiabilityExclusions', e.target.value)}
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
        /></Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Workers Compensation</Typography>
                <TextField
                label="Coverage Scope"
                value={contractState.workersCompCoverageScope}
                onChange={(e) => handleContractInputChange('workersCompCoverageScope', e.target.value)}
                fullWidth
                variant="outlined"
                multiline
                rows={3}
                sx={theme.inputStyle}
                />
                <FormControlLabel
                control={<Checkbox checked={contractState.workersCompVerification} onChange={(e) => handleContractInputChange('workersCompVerification', e.target.checked)} />}
                label="Verification Required"
                sx={{ mb: 0 }}
                />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
         
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Property Damage</Typography>
                <TextField
                label="Coverage Scope"
                value={contractState.propertyDamageCoverageScope}
                onChange={(e) => handleContractInputChange('propertyDamageCoverageScope', e.target.value)}
                fullWidth
                variant="outlined"
                multiline
                rows={3}
                sx={theme.inputStyle}
                />
                <FormControlLabel
                control={<Checkbox checked={contractState.highValueItemProtection} onChange={(e) => handleContractInputChange('highValueItemProtection', e.target.checked)} />}
                label="High-Value Item Protection"
                sx={{ mb: 3 }}
                />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
        
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Personal Injury</Typography>
        <TextField
          label="Coverage Scope"
          value={contractState.personalInjuryCoverageScope}
          onChange={(e) => handleContractInputChange('personalInjuryCoverageScope', e.target.value)}
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          sx={theme.inputStyle}
        />
        <FormControlLabel
          control={<Checkbox checked={contractState.injuryClaimsProtection} onChange={(e) => handleContractInputChange('injuryClaimsProtection', e.target.checked)} />}
          label="Injury Claims Protection"
          sx={{ mb: 3 }}
        />

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Professional Liability</Typography>
        <TextField
          label="Coverage Scope"
          value={contractState.professionalLiabilityCoverageScope}
          onChange={(e) => handleContractInputChange('professionalLiabilityCoverageScope', e.target.value)}
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          sx={theme.inputStyle}
        />
        <TextField
          label="Limitations"
          value={contractState.professionalLiabilityLimitations}
          onChange={(e) => handleContractInputChange('professionalLiabilityLimitations', e.target.value)}
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          sx={theme.inputStyle}
        />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
       
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Completed Coverage</Typography>
        <TextField
          label="Coverage Scope"
          value={contractState.completedOperationsCoverageScope}
          onChange={(e) => handleContractInputChange('completedOperationsCoverageScope', e.target.value)}
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          sx={theme.inputStyle}
        />
         <Typography gutterBottom variant="subtitle1" sx={{ fontWeight: 'bold' }}>Coverage - {contractState.completedOperationsDuration} years</Typography>
        <CustomSlider
            value={contractState.completedOperationsDuration}
            onChange={(e, value) => handleContractInputChange('completedOperationsDuration', value)}
            min={1}
            max={5}
            step={1}
            valueLabelDisplay="auto"
            sx={{ mb: 3 }}
      />
         
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Environmental Liability</Typography>
        <TextField
          label="Coverage Scope"
          value={contractState.environmentalLiabilityCoverageScope}
          onChange={(e) => handleContractInputChange('environmentalLiabilityCoverageScope', e.target.value)}
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          sx={theme.inputStyle}
        />
        <FormControlLabel
          control={<Checkbox checked={contractState.environmentalRelevance} onChange={(e) => handleContractInputChange('environmentalRelevance', e.target.checked)} />}
          label="Hazardous Materials"
          sx={{ mb: 0 }}
        />
        </Box>
        <Button
         variant="contained"
         color={updateSuccess ? 'success' : 'primary'}
         onClick={handleSubmit}
         fullWidth
         sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
         disabled={isUpdating}
       >
         {isUpdating
           ? 'Generating Contract...'
           : updateSuccess
           ? 'Contract Updated'
           : 'Update Details'}
         {updateSuccess && <Check style={{ marginLeft: '4px' }} />}
       </Button>
      </Box>

      {/* Popover for description */}
      <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography>{popoverContent}</Typography>
          </Box>
        </Popover>

    </Box>
  );
};

export default ContractLegal;