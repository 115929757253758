import React from 'react';
import { Box, Typography, List, ListItem, Button, Avatar, Chip } from '@mui/material';
import theme from '../../theme';
import { useMemo } from 'react';

/**
 * JobList component displays a list of jobs with client details and provides an option to create a new job.
 * 
 * This component takes in a list of jobs and renders them in a structured format. Each job displays its name,
 * associated client information, and a button to create a new job. It handles cases where no jobs are available
 * and ensures that the job data is valid before rendering.
 * 
 * @param {Object} props - The component props.
 * @param {Array} props.jobs - An array of job objects to display.
 * @param {Function} props.onJobClick - Callback function to handle job selection.
 * @param {Function} props.onCreateNewJob - Callback function to handle the creation of a new job.
 * @returns {JSX.Element} The rendered component.
 */
const JobList = ({ jobs, onJobClick, onCreateNewJob }) => {
  const validJobs = useMemo(() => Array.isArray(jobs) ? jobs : [], [jobs]);

  /**
   * Retrieves job details from a job object.
   * 
   * @param {Object} job - The job object to extract details from.
   * @returns {Object} An object containing the job name, client name, and client photo.
   */
  const getJobDetails = (job) => ({
    jobName: job?.JobDetails?.JobName || 'Unnamed Job',
    jobAddressOne: job?.jobStreetAddressOne || 'Address One',
    jobCity: job?.jobCity || 'Oxford',
    clientName: job?.ClientID?.clientFullName || 'Unknown Client',
    clientPhoto: job?.ClientID?.clientPhoto || '/images/avatars/painter.webp',
  });
  console.log(validJobs)
  return (
    <Box sx={theme.innerBox}>
       <Box sx={{ mb: 2, border: '1px solid #fff', borderRadius: '8px', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '1.4rem', fontFamily: '"Figtree", sans-serif', paddingLeft: '8px' }}>
          Jobs List
        </Typography>
        
        {validJobs.length > 0 ? (
          <List>
            {validJobs.map((job) => {
              const { jobName, clientName, clientPhoto, jobAddressOne, jobCity } = getJobDetails(job);

              return (
                <Box sx={{ mb: 2, border: '2px solid #fff', borderRadius: '8px', p: 0, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                <ListItem 
                  key={job._id} 
                  onClick={() => onJobClick?.(job)}
                  sx={{
                    borderRadius: '8px',
                    mb: 0,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                  }}
                >
                  <Typography 
                    variant="h6" 
                    sx={{
                      fontWeight: 'bold',
                      color: theme.palette.primary.main,
                      mb: 0
                    }}
                  >
                    {jobName}
                  </Typography>
                  <Typography variant="subtitled2" sx={{ ml: 1,  mb: 1}}>{jobAddressOne}, {jobCity}</Typography>
                 <Chip size="large" avatar={<Avatar src={clientPhoto} sx={{ width: 56, height: 56 }}/>} label={clientName} variant="outlined" sx={{ mb: 1, ml: 1 }}/>
                </ListItem>
                </Box>
              );
            })}
          </List>
        ) : (
          <Typography>No jobs found. Create a new job to get started.</Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={onCreateNewJob}
          fullWidth
          sx={{ mt: 2 }}
        >
          Create New Job
        </Button>
      </Box>
    </Box>
  );
}

export default JobList;
