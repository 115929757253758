import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const instrumentationKey = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY;

if (!instrumentationKey) {
   throw new Error("Please provide instrumentation key");
}

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };
