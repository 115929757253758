import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { ImageContainer, Canvas, PositionedActionButton } from './components/Buttons';
import CustomSlider from './CustomSlider';
import {rgbToHex, getContrastColor, b64toBlob } from './utils/utils.js';
import { roomTypes } from './constants/constants.js'; 
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import theme from './theme'; // Import the theme

// Generate random color for each box in the loading animation
const randomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r}, ${g}, ${b})`;
};

// Create a style object for the grid container
const gridBoxStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(3, 1fr)',
  width: '35%',
  height: '35%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 10,
  gap: '3px', // Small gap between the grid items
};

// Keyframe animation for color transition
const fadeInOutAnimation = `
  @keyframes fadeInOut {
    0%, 100% { opacity: 0.5; background-color: ${randomColor()}; }
    50% { opacity: 1; background-color: ${randomColor()}; }
  }
`;

// Dynamically create the box style with animation
const boxStyle = {
  width: '100%',
  height: '100%',
  animation: `fadeInOut 2s infinite`,
  backgroundColor: randomColor(),
};

const Palette = ({ colorChartPath, 
    onSwatchClick, 
    colourAccuracy,
    setColourAccuracy,
    colourMatches,
    setColourMatches 
  }) => {
        const [image, setImage] = useState(null);
        const [uploading, setUploading] = useState(false);
        const [generating, setGenerating] = useState(false);
        const [error, setError] = useState(null);
        const [updatedImageUrl, setUpdatedImageUrl] = useState('');
        const [swatchData, setSwatchData] = useState(null);
        const [roomType, setRoomType] = useState('');
        const [paletteName, setPaletteName] = useState('');

      

        const handleChange = setter => (event, value) => setter(value);

        const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

        const inputCanvasRef = useRef(null);
        const outputCanvasRef = useRef(null);
        const fileInputRef = useRef(null);

  useEffect(() => {
    const savedImage = localStorage.getItem('image');
    const savedUpdatedImageUrl = localStorage.getItem('updatedImageUrl');
    const savedSwatchData = localStorage.getItem('swatchData');

    if (savedImage) {
      const blob = b64toBlob(savedImage);
      setImage(blob);
    }
    if (savedUpdatedImageUrl) {
      setUpdatedImageUrl(savedUpdatedImageUrl);
    }
    if (savedSwatchData) {
      setSwatchData(JSON.parse(savedSwatchData));
    }
  }, []);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        sessionStorage.setItem('image', reader.result.split(',')[1]);
      };
      reader.readAsDataURL(image);
    }
    if (updatedImageUrl) {
      localStorage.setItem('updatedImageUrl', updatedImageUrl);
    }
    if (swatchData) {
      localStorage.setItem('swatchData', JSON.stringify(swatchData));
    }
  }, [image, updatedImageUrl, swatchData]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      localStorage.removeItem('image');
      localStorage.removeItem('updatedImageUrl');
      localStorage.removeItem('swatchData');

      setImage(file);
      setUpdatedImageUrl('');
      setSwatchData(null);
      setError(null);

      if (inputCanvasRef.current) {
        const inputCtx = inputCanvasRef.current.getContext('2d');
        inputCtx.clearRect(0, 0, inputCanvasRef.current.width, inputCanvasRef.current.height);
      }
      if (outputCanvasRef.current) {
        const outputCtx = outputCanvasRef.current.getContext('2d');
        outputCtx.clearRect(0, 0, outputCanvasRef.current.width, outputCanvasRef.current.height);
      }
    }
  };

  const handleUpload = () => {
    fileInputRef.current.click();
  };

  const handleGenerate = async () => {
    if (!image) return;
    
    setGenerating(true);
    setError(null);
    
    const formData = new FormData();
    formData.append('file', image);
    formData.append('colourAccuracy', Number(colourAccuracy));
    formData.append('colourMatches', Number(colourMatches)); 
  
    try {
      const response = await axios.post(`${API_ENDPOINT}/api/generate-colour-swatch`, formData);
      if (response.status === 200) {
        const { data } = response;
  
        const newPaletteName = paletteName.trim() || `palette_${new Date().getTime()}`;
        const appState = JSON.parse(localStorage.getItem('appState') || '{}');
        const userID = appState.userId;
        const jobID = appState.currentJobID;
  
        const newPalette = {
          userID: userID,
          jobID: jobID,
          name: newPaletteName,
          swatchData: data.swatchData
        };
  
        // Save to localStorage
        const existingPalettes = JSON.parse(localStorage.getItem('savedPalettes') || '[]');
        const updatedPalettes = [...existingPalettes, newPalette];
        localStorage.setItem('savedPalettes', JSON.stringify(updatedPalettes));
  
        // Save to database
        await axios.post(`${API_ENDPOINT}/api/palettes`, newPalette);
  
        setUpdatedImageUrl(data.updatedImageUrl);
        setSwatchData(data.swatchData);
      } else {
        console.error('Failed to generate the palette');
        setError('Failed to generate the palette');
      }
    } catch (error) {
      console.error('Error generating palette:', error);
      setError('Error generating palette');
    } finally {
      setGenerating(false);
    }
  };
  
  

  useEffect(() => {
    if (image) {
      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = () => {
        const aspectRatio = img.width / img.height;

        // Dynamically set canvas dimensions based on the image's aspect ratio
        const canvas = inputCanvasRef.current;
        const ctx = canvas.getContext('2d');
        const canvasWidth = 480; // fixed width
        const canvasHeight = canvasWidth / aspectRatio;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
    }
  }, [image]);

  useEffect(() => {
    if (updatedImageUrl) {
      const img = new Image();
      img.src = updatedImageUrl;
      img.onload = () => {
        const aspectRatio = img.width / img.height;

        // Dynamically set output canvas dimensions based on aspect ratio
        const canvas = outputCanvasRef.current;
        const ctx = canvas.getContext('2d');
        const canvasWidth = 300; // fixed width
        const canvasHeight = canvasWidth / aspectRatio;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
    }
  }, [updatedImageUrl]);

  return (
    <Box p={3} sx={{
      backgroundColor: 'rgba(255, 255, 255, 0.0)',
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)',
      width: '100%',
      borderRadius: '8px'
    }}>
      {/* First Row: Heading */}
      <Typography variant="h5" gutterBottom>
        Create your custom palette
      </Typography>
    
      {/* Second Row: Room type and palette name */}
      
    
      <Box sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          width: '90%',
          padding: '10px',
          borderRadius: '8px',
          mb: 1, 
          display: 'grid',   
          gridTemplateColumns: '1fr 1fr',   
          columnGap: '30px', 
        }}>
  <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2} >

  <FormControl variant="outlined" sx={theme.selectFormControl}>
      <InputLabel style={{ fontSize: '18px', fontWeight: 'bold' }}>Room Type</InputLabel>
          <Select
            labelId="room-type-label"
            value={roomType}
            onChange={(e) => setRoomType(e.target.value)}
            label="Room Type"
            sx={{ fontSize: '18px',
              fontWeight: 'bold',
              '& .MuiSelect-select': {
                backgroundColor: 'transparent',
              }
            }}
          >
            {roomTypes.map((room) => (
              <MenuItem key={room} value={room}>
                {room}
              </MenuItem>
            ))}
          </Select>
    </FormControl>


        <TextField 
          label="Palette Name"
          value={paletteName}
          onChange={(e) => setPaletteName(e.target.value)}
          variant="outlined"
          sx={theme.selectFormControl}
        />
      </Box>

  {/* Description Row taking up both columns */}
  <Box sx={{ padding: '10px', gridColumn: 'span 2' }}>
    <Typography variant="h6" align="left" mb={1} sx={theme.customToggleButton}>
      Description
    </Typography>
  </Box>

  {/* First column: Slider with label aligned left */}
  <Box sx={{ padding: '10px' }}>
    <Typography variant="h6" align="left" mb={2} sx={theme.customToggleButton}>
      Colour Accuracy - {colourAccuracy}%
    </Typography>
    
    <CustomSlider
      value={colourAccuracy}
      valueLabelDisplay="auto"
      onChange={handleChange(setColourAccuracy)}
      min={10}
      max={100}
      step={5}
    />
  </Box>

  {/* Second column: Slider with label aligned center */}
  <Box sx={{ padding: '10px' }}>
    <Typography variant="h6" align="left" mb={2} sx={theme.customToggleButton}>
      Colour Matches - #{colourMatches}
    </Typography>
    <CustomSlider
      value={colourMatches}
      onChange={handleChange(setColourMatches)}
      valueLabelDisplay="auto"
      min={10}
      max={30}
      step={1}
    />
  </Box>
</Box>

  {/* Third Row: Canvas elements for uploading the photo */}
      <Box display="flex" justifyContent="flex-start" mt={2} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)',  width: '88%', padding: '20px', borderRadius: '8px', position: 'relative' }}>

        <ImageContainer>
          <Canvas ref={inputCanvasRef} style={{ backgroundColor: 'rgba(125, 125, 125, 0.3)', width: '460px', height: '270px', position: 'relative', borderRadius: '8px', border: '2px dashed grey'  }} />
          
          {generating && (
            <>
              <style>{fadeInOutAnimation}</style>
              <Box sx={gridBoxStyle}>
                {[...Array(9)].map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      ...boxStyle,
                      animationDelay: `${Math.random()}s`, // Randomize animation delay
                    }}
                  />
                ))}
              </Box>
            </>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          <PositionedActionButton 
            variant="contained" 
            onClick={image ? handleGenerate : handleUpload}
            disabled={generating}
          >
            {generating ? 'Generating...' : (image ? 'Generate Palette' : 'Upload Photo')}
          </PositionedActionButton>
        </ImageContainer>
    
        <ImageContainer>
          <Canvas ref={outputCanvasRef} style={{ backgroundColor: 'rgba(255, 255, 255, 0.0)', position: 'relative', borderRadius: '10px' }} />
        </ImageContainer>
      </Box>
    
      {/* Fourth Row: Return palette data */}
      {swatchData && (
  <Box
    mt={4}
    sx={{
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)',
      width: '90%',
      borderRadius: '8px',
      padding: '16px 8px',
    }}
  >
    <Box
      display="grid"
      gridTemplateColumns="repeat(5, 1fr)"
      gridTemplateRows="repeat(2, auto)"
      gap={2}
    >
      {swatchData
        .sort((a, b) => {
          if (a.colourCategory === b.colourCategory) {
            // Sort by luminance (brightness) if they belong to the same category
            const getLuminance = (rgb) => {
              const rgbArray = rgb.match(/\d+/g).map(Number);
              return (
                0.2126 * (rgbArray[0] / 255) +
                0.7152 * (rgbArray[1] / 255) +
                0.0722 * (rgbArray[2] / 255)
              );
            };
            return getLuminance(b.rgb) - getLuminance(a.rgb);
          }
          // Sort by color category first
          return a.colourCategory.localeCompare(b.colourCategory);
        })
        .slice(0, 30)
        .map((color, index) => {
          const rgbValues = color.rgb.match(/\d+/g);
          const rgbString = rgbValues
            ? `rgb(${rgbValues.join(', ')})`
            : 'rgb(255, 255, 255)';
          const hexValue = rgbToHex(color.rgb);
          const contrastColor = getContrastColor(hexValue);
          return (
            <Box
              key={index}
              onClick={() => onSwatchClick(color.rgb)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '10px',
                backgroundColor: '#fff',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box
                sx={{
                  width: '135px',
                  height: '150px',
                  borderRadius: '8px',
                  backgroundColor: rgbString,
                  position: 'relative',
                }}
              >
                <Typography
                  sx={{
                    position: 'absolute',
                    bottom: '5px',
                    left: '10px',
                    color: contrastColor,
                    fontSize: '14px',
                  }}
                >
                  {hexValue}
                </Typography>
              </Box>
              <Typography
                sx={{
                  marginTop: '5px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#222',
                }}
              >
                {color.name}
              </Typography>
            </Box>
          );
        })}
    </Box>
  </Box>
)}

    </Box>
    
    
  );
};

export default Palette;
