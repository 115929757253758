// CustomToggleButton.js
import { styled } from '@mui/system';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

// Navigation Button Group with original style
export const NavToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    margin: theme.spacing(0.0),
    border: 1,
    '&.Mui-selected': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#1152f93',
        color: '#ffffff',
      },
    },
    '&:not(:first-of-type)': {
      borderRadius: '0px,0px,0px.0px',
    },
    '&:first-of-type': {
      borderRadius: '32px,0px,0px.0px',
    },
  },
}));

// Navigation Toggle Button with original style
export const NavToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: '#1565c0',
  fontWeight: '800',
  '&:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.8)',
    color: '#ffffff',
  },
  '&.Mui-selected': {
    color: '#ffffff',
    fontWeight: 'bold',
  },
}));

// In-Page Button Group with new style (border, shadow, gap, etc.)
export const InPageToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  border: '1px solid #ccc',
  borderRadius: '8px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
  padding: '2px',
  display: 'flex',
  gap: theme.spacing(0.0), // No gap between each button

  '& .MuiToggleButtonGroup-grouped': {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    margin: 0,
    transition: 'transform 0.1s ease-out, box-shadow 0.1s ease-out',
    
    // Default border radius for middle buttons
    borderRadius: 0,

    // First button - left rounded corners
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },

    // Last button - right rounded corners
    '&:last-of-type': {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    
    '&.Mui-selected': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      transform: 'scale(0.85)', // Slightly smaller when selected
      boxShadow: 'none', // No shadow on selected
      '&:hover': {
        backgroundColor: '#1152f9',
      },
    },
  },
}));

// In-Page Toggle Button with new style
export const InPageToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: '#1565c0',
  fontWeight: '800',
  
  '&:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.8)',
    color: '#fff', // Set text color to black on hover
    border: '4px',
  },
  '&.Mui-selected': {
    color: '#ffffff',
    fontWeight: 'bold',
    '&:hover': {
      color: '#fff', // Optional: text color black on hover while selected
      border: '4px',
    },
  },
}));

