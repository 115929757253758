import React, { useRef, useEffect } from 'react';

const ImageCanvas = ({ image, style }) => {
  const canvasRef = useRef(null);

  const drawImage = (file) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const canvasWidth = canvas.clientWidth;
      const canvasHeight = canvasWidth / aspectRatio;
      
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      
      ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
    };

    img.src = URL.createObjectURL(file);
  };

  useEffect(() => {
    if (image) {
      drawImage(image);
    }
  }, [image]);

  return (
    <canvas 
      ref={canvasRef} 
      style={{ 
        outlineStyle: 'dashed', 
        outlineColor: 'rgba(0, 0, 0, 0.3)', 
        maxWidth: '90%', 
        height: 'auto',
        ...style 
      }} 
    />
  );
};

export default ImageCanvas;