import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Stage, Layer } from 'react-konva';
import Room from './Room';
import NewRoom from './NewRoom';
import { PIXELS_PER_METER } from './Constants';
import axios from 'axios';
import { createPortal } from 'react-dom'; 
import CanvasWithPrompt from './CanvasWithPrompt';
import { buildRoomPrompt } from './buildRoomPrompt';

const ZoomControls = ({
  zoom, 
  handleZoomChange, 
  handleDeleteRoom, 
  selectedRoomId, 
  floorNames, 
  selectedFloorID, 
  onFloorChange, 
  handleSaveFloorplan, 
  isSaving, 
  saveButtonText,
  currentJobID,
  currentFloorID,
  setCurrentFloorID
}) => {
  const [selectedFloorName, setSelectedFloorName] = useState('');

  useEffect(() => {
    const storedAppData = JSON.parse(localStorage.getItem('appData'));
    const storedAppState = JSON.parse(localStorage.getItem('appState'));
  
    if (storedAppData && storedAppState) {
      const currentJob = storedAppData.jobs?.find(job => job._id === storedAppState.currentJobID);
  
      if (currentJob && currentJob.FloorPlanID) {
        const selectedFloor = currentJob.FloorPlanID.Floors.find(floor => floor._id === storedAppState.currentFloorID);
        if (selectedFloor) {
          setSelectedFloorName(selectedFloor.Name);
        } else {
          console.error("Selected floor not found.");
        }
      } else {
        console.log("Current job or FloorPlanID not found.");
      }
    } else {
      console.log("App data or state not found in localStorage.");
    }
  }, [floorNames]);
  

  const handleFloorSelectionChange = (event) => {
    const newFloorName = event.target.value;
    setSelectedFloorName(newFloorName);

    const storedAppData = JSON.parse(localStorage.getItem('appData'));
    const storedAppState = JSON.parse(localStorage.getItem('appState'));

    if (storedAppData && storedAppState) {
      const currentJob = storedAppData.jobs?.find(job => job._id === storedAppState.currentJobID);

      if (currentJob && currentJob.FloorPlanID) {
        const selectedFloor = currentJob.FloorPlanID.Floors.find(floor => floor.Name === newFloorName);
        if (selectedFloor) {
          storedAppState.currentFloorID = selectedFloor._id;
          localStorage.setItem('appState', JSON.stringify(storedAppState));
          onFloorChange(selectedFloor._id);
        }
      } else {
        console.error("Current job or FloorPlanID not found.");
      }
    } else {
      console.error("App data or state not found in localStorage.");
    }
  };

  const deleteButtonStyle = {
    marginLeft: '20px',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '4px',
    cursor: selectedRoomId ? 'pointer' : 'not-allowed',
    backgroundColor: selectedRoomId ? '#ff4d4d' : '#ffb3b3',
    opacity: selectedRoomId ? 1 : 0.6,
    transition: 'all 0.3s ease',
  };

  const saveButtonStyle = {
    marginLeft: '20px',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '4px',
    cursor: isSaving ? 'not-allowed' : 'pointer',
    backgroundColor: isSaving ? 'gray' : 'green',
    transition: 'all 0.3s ease',
  };

  const selectStyle = {
    marginRight: '10px',
    padding: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  };

  return (
    <div style={{
      position: 'fixed',
      bottom: '20px',
      left: '60%',
      transform: 'translateX(-60%)',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      padding: '10px',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      width: '50%',
      justifyContent: 'space-between',
    }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <select
          style={selectStyle}
          value={selectedFloorName}
          onChange={handleFloorSelectionChange}
        >
         

         {floorNames && floorNames.length > 0 ? (
            floorNames.map((floor) => {
                return (
                <option key={floor._id} value={floor.Name}>
                  {floor.Name}
                </option>
              );
            })
          ) : (
            <option key="no-floors" value="">
              No floors available
            </option>
          )}

        </select>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'center' }}>
        <button onClick={() => handleZoomChange(Math.max(zoom - 10, 10))}>-</button>
        <input
          type="range"
          min="25"
          max="200"
          value={zoom}
          onChange={(e) => handleZoomChange(Number(e.target.value))}
          style={{ margin: '0 10px', width: '200px' }}
        />
        <button onClick={() => handleZoomChange(Math.min(zoom + 10, 200))}>+</button>
        <span style={{ marginLeft: '10px' }}>{zoom}%</span>
        <button
          onClick={() => handleZoomChange(100)}
          style={{ marginLeft: '10px' }}
        >
          Fit
        </button>
      </div>
      <button
        onClick={handleDeleteRoom}
        style={deleteButtonStyle}
        disabled={!selectedRoomId}
      >
        Delete Room
      </button>
      <button
        style={saveButtonStyle}
        onClick={handleSaveFloorplan}
        disabled={isSaving}
      >
        {saveButtonText}
      </button>
    </div>
  );
};

const FloorPlan = ({
  currentJobID,
  currentFloorID,
  setCurrentFloorID,
  rooms,
  setRooms,
  newRoom,
  setNewRoom,
  selectedRoomId,
  setSelectedRoomId,
  selectedColor,
  setSelectedColor,
}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [draggingRoomId, setDraggingRoomId] = useState(null);
  const [resizing, setResizing] = useState({ isResizing: false, roomId: null, handle: null });
  const [hoveringHandle, setHoveringHandle] = useState({ roomId: null, handle: null });
  const [selectedWall, setSelectedWall] = useState({ roomId: null, wall: null });
  const [hoveredSwatch, setHoveredSwatch] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(100);
  const [isSaving, setIsSaving] = useState(false);
  const [saveButtonText, setSaveButtonText] = useState("Save Floorplan");
  const [selectedElement, setSelectedElement] = useState(null);

  const [showCanvas, setShowCanvas] = useState(false); 
  
  const [generatedPrompt, setGeneratedPrompt] = useState('Loading prompt...');
  const [currentRoomData, setCurrentRoomData] = useState(null); 

  const [imageUrl, setImageUrl] = useState('');

  const storedImageUrl = localStorage.getItem('selectedImageUrl') || '/images/ai/bedroom_template/white/screen/bedroom_white_001.webp';
  const [selectedImageUrl, setSelectedImageUrl] = useState(null); // Declare the state for selectedImageUrl

  

  const handleGeneratePrompt = (roomData) => {
    setCurrentRoomData(roomData); // Set the room data to trigger the useEffect below
    setGeneratedPrompt('Loading prompt...'); // Display a loading message immediately
    console.log("Opening canvas and starting prompt generation for room:", roomData);
  };

  // Effect to handle when selectedImageUrl changes and set imageUrl accordingly
  useEffect(() => {
    console.log('Received selectedImageUrl:', selectedImageUrl);
    if (selectedImageUrl) {
      setImageUrl(selectedImageUrl); // Update imageUrl state when selectedImageUrl changes
    }
  }, [selectedImageUrl]);


  useEffect(() => {
    console.log('Parent component: selectedImageUrl updated to:', selectedImageUrl);
  }, [selectedImageUrl]);
  
  useEffect(() => {
    if (currentRoomData) {
      const fetchPrompt = async () => {
        try {
          const generatedPrompt = await buildRoomPrompt(currentRoomData); // Now correctly awaits the API response
          console.log("Prompt generated successfully:", generatedPrompt);
          setGeneratedPrompt(generatedPrompt); // Update the prompt in state
        } catch (error) {
          console.error("Error generating prompt:", error);
          setGeneratedPrompt("Error generating prompt"); // Handle error case
        }
      };
      fetchPrompt();
    }
  }, [currentRoomData]);
  
  const roomsRef = useRef(rooms);

  const onFloorChange = (selectedFloorID) => {
    const storedAppData = JSON.parse(localStorage.getItem('appData'));
    const storedAppState = JSON.parse(localStorage.getItem('appState'));

    if (storedAppData && storedAppState) {
      const currentJob = storedAppData.jobs?.find(job => job._id === storedAppState.currentJobID);

      if (currentJob && currentJob.FloorPlanID) {
        const selectedFloor = currentJob.FloorPlanID.Floors.find(floor => floor._id === selectedFloorID);

        if (selectedFloor) {
          if (selectedFloor.rooms && selectedFloor.rooms.length > 0) {
            setRooms(selectedFloor.rooms); // Load the rooms for the selected floor
          } else {
            setRooms([]); // No rooms available, set it to empty array
          }
        }
      }
    }
  };

  const handleSaveFloorplan = async () => {
    try {
      setIsSaving(true); 
      setSaveButtonText("Saving..."); 
  
      // Get the latest appData and appState from localStorage
      const storedAppData = JSON.parse(localStorage.getItem('appData'));
      const storedAppState = JSON.parse(localStorage.getItem('appState'));
  
      // Find the current job and floor from the appData and appState
      const currentJob = storedAppData.jobs.find(job => job._id === storedAppState.currentJobID);
      if (currentJob && currentJob.FloorPlanID) {
        const selectedFloor = currentJob.FloorPlanID.Floors.find(floor => floor._id === storedAppState.currentFloorID);
  
        if (selectedFloor) {
          // Prepare rooms for saving by stripping out unnecessary fields
          const roomsToSave = rooms.map(room => ({
            _id: room._id || undefined, // Include _id if room already exists
            id: room.id,
            name: room.name,
            width: room.width,
            length: room.length,
            x: room.x,
            y: room.y,
            doors: room.doors || 0,
            windows: room.windows || 0,
            walls: {
              top: room.walls?.top || {},
              bottom: room.walls?.bottom || {},
              left: room.walls?.left || {},
              right: room.walls?.right || {},
            },
            doorColour: room.doorColour || {},
            windowColour: room.windowColour || {},
            ceilingColour: room.ceilingColour || {},
            architraveColour: room.architraveColour || {},
            skirtingBoardColour: room.architraveColour || {},
          }));
  
          // Only send rooms data to the API
          const roomsData = { rooms: roomsToSave };
  
          // Make API call to update the rooms using the floorplanId
          await axios.put(`${API_ENDPOINT}/api/rooms/${selectedFloor._id}`, roomsData);
  
          // Update localStorage to ensure it's in sync
          selectedFloor.rooms = roomsToSave;
          localStorage.setItem('appData', JSON.stringify(storedAppData));
  
          // Indicate the save was successful
          setSaveButtonText("Floorplan Saved");
  
          // Revert the button back to "Save Floorplan" after a short delay (e.g., 2 seconds)
          setTimeout(() => {
            setSaveButtonText("Save Floorplan");
          }, 3000);
        } else {
          console.error("Selected floor not found");
        }
      } else {
        console.error("Current job or FloorPlanID not found");
      }
    } catch (error) {
      console.error("Error saving floorplan:", error);
      setSaveButtonText("Save Floorplan"); // Reset button text on error
    } finally {
      setIsSaving(false); // End the saving process
    }
  };
  
  
  
  useEffect(() => {
  const storedAppData = JSON.parse(localStorage.getItem('appData'));
  const storedAppState = JSON.parse(localStorage.getItem('appState'));

  if (storedAppData && storedAppState) {
    const currentJob = storedAppData.jobs?.find(job => job._id === storedAppState.currentJobID);

    // Ensure currentJob and FloorPlanID exist
    if (currentJob && currentJob.FloorPlanID) {
      setFloorNames(currentJob.FloorPlanID.Floors);

      const currentFloor = currentJob.FloorPlanID.Floors.find(floor => floor._id === storedAppState.currentFloorID);
      if (currentFloor && currentFloor.rooms) {
        setRooms(currentFloor.rooms);
      } else {
        setRooms([]); // No rooms available for this floor
      }
    } else {
      console.log("Current job or FloorPlanID not found. This may be a new user.");
      setFloorNames([]); // No floors available
      setRooms([]); // No rooms available
    }
  } else {
    console.log("App data or state not found in localStorage.");
    setFloorNames([]); // No floors available
    setRooms([]); // No rooms available
  }
}, []);



  const calculateBoundingBox = (rooms) => {
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
    rooms.forEach(room => {
      minX = Math.min(minX, room.x);
      minY = Math.min(minY, room.y);
      maxX = Math.max(maxX, room.x + room.width);
      maxY = Math.max(maxY, room.y + room.length);
    });
    return { minX, minY, maxX, maxY };
  };

  useEffect(() => {
    roomsRef.current = rooms;
  }, [rooms]);
  // console.log('Floorplan rendering, selectedElement:', selectedElement);

  const updateLocalStorage = (updatedRooms) => {
    const storedAppData = JSON.parse(localStorage.getItem('appData'));
    if (storedAppData && storedAppData.FloorPlanID) {
      const selectedFloorName = localStorage.getItem('selectedFloorName') || storedAppData.FloorPlanID.Floors[0].Name;
      const floorIndex = storedAppData.FloorPlanID.Floors.findIndex(floor => floor.Name === selectedFloorName);
      if (floorIndex !== -1) {
        storedAppData.FloorPlanID.Floors[floorIndex].rooms = updatedRooms;
        localStorage.setItem('appData', JSON.stringify(storedAppData)); // Sync localStorage
      }
    }
  };

  const stageRef = useRef(null);

  const [floorNames, setFloorNames] = useState(['Ground Floor']); 

  useEffect(() => {
    const storedAppData = JSON.parse(localStorage.getItem('appData'));
    if (storedAppData && storedAppData.FloorPlanID && storedAppData.FloorPlanID.Floors) {
      const newFloorNames = storedAppData.FloorPlanID.Floors.map(floor => floor.Name);
      setFloorNames(newFloorNames);
    }
  }, []); 

  const snapToGrid = useCallback((value) => {
    const gridSize = 0.1 * PIXELS_PER_METER; // 0.1m in pixels
    return Math.round(value / gridSize) * gridSize;
  }, []);

  const getMousePosition = useCallback((stage) => {
    const mousePos = stage.getPointerPosition();
    return {
      x: mousePos.x / stage.scaleX(),
      y: mousePos.y / stage.scaleY()
    };
  }, []);

  const handleZoomChange = (newZoom) => {
    setZoom(newZoom);
    const newScale = newZoom / 100;
    stageRef.current.scale({ x: newScale, y: newScale });
  
    if (newZoom === 100) { // If "Fit" button is clicked
      const boundingBox = calculateBoundingBox(rooms);
      const padding = 40; // Extra padding
      const stageWidth = window.innerWidth * 1.0;
      const stageHeight = window.innerHeight * 3.0;
      const scaleX = stageWidth / (boundingBox.maxX - boundingBox.minX + padding * 2);
      const scaleY = stageHeight / (boundingBox.maxY - boundingBox.minY + padding * 2);
      const scale = Math.min(scaleX, scaleY);
      
      stageRef.current.scale({ x: scale, y: scale });
      stageRef.current.position({
        x: (stageWidth - (boundingBox.maxX - boundingBox.minX) * scale) / 2 - boundingBox.minX * scale + padding * scale,
        y: (stageHeight - (boundingBox.maxY - boundingBox.minY) * scale) / 2 - boundingBox.minY * scale + padding * scale
      });
    }
  
    stageRef.current.batchDraw();
  };

  const handleDeleteRoom = async () => {
    if (selectedRoomId) {
      try {
        // Find the room object based on selectedRoomId
        const roomToDelete = rooms.find(room => room.id === selectedRoomId);
        if (!roomToDelete) {
          console.error('Selected room not found');
          return;
        }
  
        // Use the _id field if it exists, otherwise use the id field
        const idToDelete = roomToDelete._id || roomToDelete.id;
  
        // Delete the room from the database
        await axios.delete(`${API_ENDPOINT}/api/rooms/${idToDelete}`);
  
        // Update the local state
        const updatedRooms = rooms.filter(room => room.id !== selectedRoomId);
        setRooms(updatedRooms);
  
        // Get appData from localStorage
        const storedAppData = JSON.parse(localStorage.getItem('appData'));
        const storedAppState = JSON.parse(localStorage.getItem('appState'));
  
        const currentJob = storedAppData?.jobs?.find(job => job._id === storedAppState?.currentJobID);
        if (currentJob && currentJob.FloorPlanID) {
          const selectedFloor = currentJob.FloorPlanID.Floors.find(floor => floor._id === storedAppState.currentFloorID);
  
          if (selectedFloor) {
            // Remove the deleted room from appData
            const updatedFloorRooms = selectedFloor.rooms.filter(room => room.id !== selectedRoomId);
            selectedFloor.rooms = updatedFloorRooms;
  
            // Save the updated appData back to localStorage
            localStorage.setItem('appData', JSON.stringify(storedAppData));
          } else {
            console.error('Selected floor not found.');
          }
        } else {
          console.error('Current job or FloorPlanID not found.');
        }
  
        // Reset the selected room and wall
        setSelectedRoomId(null);
        setSelectedWall({ roomId: null, wall: null });
      } catch (error) {
        console.error('Error deleting room:', error);
      }
    }
  };
  

  const setInitialLayout = (rooms) => {
    // Check if rooms already have positions
    const needsLayout = rooms.some(room => room.x === undefined || room.y === undefined);
    
    if (!needsLayout) {
      return rooms;
    }
  
    const sortedRooms = [...rooms].sort((a, b) => a.name.localeCompare(b.name));
    const padding = 20;
    let currentX = padding;
    let currentY = padding;
    let maxHeightInRow = 0;
  
    return sortedRooms.map((room) => {
      if (currentX + room.width > window.innerWidth - padding) {
        currentX = padding;
        currentY += maxHeightInRow + padding;
        maxHeightInRow = 0;
      }
  
      const newRoom = {
        ...room,
        x: room.x !== undefined ? room.x : currentX,
        y: room.y !== undefined ? room.y : currentY
      };
  
      currentX += room.width + padding;
      maxHeightInRow = Math.max(maxHeightInRow, room.length);
  
      return newRoom;
    });
  };

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
  const loadRooms = () => {
    const storedAppData = JSON.parse(localStorage.getItem('appData'));
    const storedAppState = JSON.parse(localStorage.getItem('appState'));

    if (storedAppData && storedAppState) {
      const currentJob = storedAppData.jobs?.find(job => job._id === storedAppState.currentJobID);
      const selectedFloorID = storedAppState.currentFloorID;

      if (currentJob && currentJob.FloorPlanID) {
        setFloorNames(currentJob.FloorPlanID.Floors); // Set floor names

        const selectedFloor = currentJob.FloorPlanID.Floors.find(floor => floor._id === selectedFloorID);

        if (selectedFloor && selectedFloor.rooms && selectedFloor.rooms.length > 0) {
          setRooms(selectedFloor.rooms); // Load the rooms for the selected floor
        } else {
          fetchRoomsFromAPI(selectedFloorID); // Fetch rooms from the API only if not already loaded
        }
      } else {
        console.log("No current job or FloorPlanID found. This might be a new user.");
        setFloorNames([]); // Set empty floor names
        setRooms([]); // Set empty rooms
      }
    } else {
      console.log("No app data or state found in localStorage. This might be a new user.");
      setFloorNames([]); // Set empty floor names
      setRooms([]); // Set empty rooms
    }
  };

  const fetchRoomsFromAPI = async (floorID) => {
    try {
      const response = await axios.get(`${API_ENDPOINT}/api/rooms?floorID=${floorID}`);
      if (response.data && response.data.length > 0) {
        const initialRooms = setInitialLayout(response.data);
        setRooms(initialRooms);
        updateLocalStorage(initialRooms); // Save rooms for the selected floor to localStorage
      } else {
        console.log("No rooms found for this floor.");
        setRooms([]);
      }
    } catch (error) {
      console.error('Error fetching rooms:', error);
      setRooms([]);
    }
  };

  loadRooms();
}, [currentJobID, currentFloorID]);

  const addRoom = async (newRoom) => {
    try {
      let response;
      if (newRoom._id) {
        response = await axios.put(`${API_ENDPOINT}/api/rooms/${newRoom._id}`, newRoom);
      } else {
        response = await axios.post(`${API_ENDPOINT}/api/rooms`, newRoom);
      }
  
      const addedOrUpdatedRoom = response.data;
       const storedAppData = JSON.parse(localStorage.getItem('appData'));
      const storedAppState = JSON.parse(localStorage.getItem('appState'));
  
      const currentJob = storedAppData?.jobs?.find(job => job._id === storedAppState?.currentJobID);
      if (currentJob && currentJob.FloorPlanID) {
        const selectedFloor = currentJob.FloorPlanID.Floors.find(floor => floor._id === storedAppState.currentFloorID);
  
        if (selectedFloor) {
          const roomIndex = selectedFloor.rooms.findIndex(room => room.id === newRoom.id);
          if (roomIndex !== -1) {
            // Update the existing room
            selectedFloor.rooms[roomIndex] = addedOrUpdatedRoom;
          } else {
            // Add the new room
            selectedFloor.rooms.push(addedOrUpdatedRoom);
          }
          
          // Save updated appData to localStorage
          localStorage.setItem('appData', JSON.stringify(storedAppData));
  
          // Update the local state to reflect the changes
          setRooms([...rooms.filter(r => r.id !== newRoom.id), addedOrUpdatedRoom]);
        } else {
          console.error('Selected floor not found.');
        }
      } else {
        console.error('Current job or FloorPlanID not found.');
      }
    } catch (error) {
      console.error('Error adding or updating room:', error);
    }
  };
  
  useEffect(() => {
     if (selectedColor && selectedElement && typeof selectedElement === 'object') {
      setRooms(prevRooms => 
        prevRooms.map(room => {
          if (room.id === selectedElement.roomId) {
            let updatedRoom = { ...room };
  
             switch (selectedElement.type) {
              case 'wall':
                if (selectedElement.wall) {
                  updatedRoom.walls = {
                    ...(room.walls || {}),
                    [selectedElement.wall]: {
                      ...(room.walls?.[selectedElement.wall] || {}),
                      color: selectedColor,
                    },
                  };
                }
                break;
  
              case 'ceilingColour':
                updatedRoom.ceilingColour = {
                  ...(room.ceilingColour || {}),
                  color: selectedColor,
                };
                break;
  
              case 'windowColour':
                updatedRoom.windowColour = {
                  ...(room.windowColour || {}),
                  color: selectedColor,
                };
                break;
  
              case 'doorColour':
                updatedRoom.doorColour = {
                  ...(room.doorColour || {}),
                  color: selectedColor,
                };
                break;
  
              case 'architraveColour':
                updatedRoom.architraveColour = {
                  ...(room.architraveColour || {}),
                  color: selectedColor,
                };
                break;
  
              case 'skirtingBoardColour':
                updatedRoom.skirtingBoardColour = {
                  ...(room.skirtingBoardColour || {}),
                  color: selectedColor,
                };
                break;
  
              case 'room':
                updatedRoom.walls = {
                  ...(room.walls || {}),
                  top: {
                    ...(room.walls?.top || {}),
                    color: selectedColor,
                  },
                  bottom: {
                    ...(room.walls?.bottom || {}),
                    color: selectedColor,
                  },
                  left: {
                    ...(room.walls?.left || {}),
                    color: selectedColor,
                  },
                  right: {
                    ...(room.walls?.right || {}),
                    color: selectedColor,
                  },
                };
                break;
  
              default:
                console.warn(`Unhandled element type: ${selectedElement.type}`);
                break;
            }
            
            return updatedRoom;
          }
          return room;
        })
      );
      setSelectedColor(null);
    }
  }, [selectedColor, selectedElement, setRooms]);
  
  
  const handleMouseDown = async (e) => {
    const { target } = e;
    if (target.name() === 'room' || target.name() === 'handle' || target.name() === 'text') {
      return;
    }
  
    const stage = e.target.getStage();
    const mousePos = getMousePosition(stage);
  
    setIsDrawing(true);
  
    // Get the selected floor name or floor ID from localStorage (or state if it's stored there)
    const storedAppData = JSON.parse(localStorage.getItem('appData')) || {};
    const storedAppState = JSON.parse(localStorage.getItem('appState')) || {};
  
    // Ensure currentJob and FloorPlanID are valid
    const currentJob = storedAppData.jobs?.find(job => job._id === storedAppState.currentJobID);
    if (!currentJob || !currentJob.FloorPlanID) {
      // console.error('Current job or FloorPlanID not found.');
      return;
    }
  
    const selectedFloor = currentJob.FloorPlanID.Floors.find(floor => floor._id === storedAppState.currentFloorID);
    if (!selectedFloor) {
      // console.error('Selected floor not found.');
      return;
    }
  
    const floorId = selectedFloor ? selectedFloor._id : "UnknownFloor";
  
    try {
      // Fetch the seq value from the counter API
      const response = await fetch(`${API_ENDPOINT}/api/counters/roomID`);
      if (!response.ok) {
        throw new Error('Failed to fetch roomID seq value');
      }
      const roomID = await response.json(); // roomID.seq contains the seq value
  
      // Prepend the floor id to the room id for uniqueness across floors
      const newId = `${floorId}-room-${roomID.seq}`;
      const newName = `Room ${roomID.seq}`;
  
      setNewRoom({
        id: newId, // Ensure unique ID
        name: newName,
        x: snapToGrid(mousePos.x),
        y: snapToGrid(mousePos.y),
        width: 0,
        length: 0,
        walls: { top: {}, bottom: {}, left: {}, right: {} },
      });
    } catch (error) {
      console.error('Error adding new room:', error);
      // Handle error appropriately (e.g., show a message to the user)
    }
  };
  
  

  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const mousePos = getMousePosition(stage);
    setMousePosition(mousePos);
  
    // Ensure newRoom is not null and the user is drawing a room
    if (isDrawing && newRoom) {
      const width = snapToGrid(mousePos.x - newRoom.x);
      const length = snapToGrid(mousePos.y - newRoom.y);
      
      // Update newRoom dimensions only if newRoom is defined
      setNewRoom({
        ...newRoom,
        width,
        length,
      });
    } else if (resizing.isResizing) {
      const { roomId, handle } = resizing;
      const roomIndex = rooms.findIndex((room) => room.id === roomId);
      const newRooms = rooms.slice();
      const room = newRooms[roomIndex];
  
      const snappedX = snapToGrid(mousePos.x);
      const snappedY = snapToGrid(mousePos.y);
  
      setRooms(newRooms);
  
      switch (handle) {
        case 'top-left':
          room.width = snapToGrid(room.width + room.x - snappedX);
          room.length = snapToGrid(room.length + room.y - snappedY);
          room.x = snappedX;
          room.y = snappedY;
          break;
        case 'top-right':
          room.width = snapToGrid(snappedX - room.x);
          room.length = snapToGrid(room.length + room.y - snappedY);
          room.y = snappedY;
          break;
        case 'bottom-left':
          room.width = snapToGrid(room.width + room.x - snappedX);
          room.x = snappedX;
          room.length = snapToGrid(snappedY - room.y);
          break;
        case 'bottom-right':
          room.width = snapToGrid(snappedX - room.x);
          room.length = snapToGrid(snappedY - room.y);
          break;
        default:
          break;
      }
  
      setRooms(newRooms);
    }
  };

  

  const handleElementClick = useCallback((roomId, elementType, wall = null) => {
    
    const newSelectedElement = {
      type: elementType,
      roomId: roomId,
      wall: wall,
    };
  
    setSelectedElement(newSelectedElement);
    setSelectedRoomId(roomId);
    setSelectedWall(wall ? { roomId, wall } : { roomId: null, wall: null });
  }, []);
  
  
  

  const handleMouseUp = () => {
    if (isDrawing && newRoom) {
      if (Math.abs(newRoom.width) >= PIXELS_PER_METER * 2 && Math.abs(newRoom.length) >= PIXELS_PER_METER * 2) {
        // console.log("Adding new room:", newRoom); // Debug log
        // Add the new room to state
        setRooms((prevRooms) => [...prevRooms, newRoom]);
        // Add the room to the backend/database
        addRoom(newRoom);
      }
    } else if (resizing.isResizing) {
      const { roomId } = resizing;
      const roomIndex = rooms.findIndex((room) => room.id === roomId);
      const updatedRoom = rooms[roomIndex];
  
      // Update the room in the database
      updateDatabase([updatedRoom]); // Sending the updated room data to the backend
    }
    
    setIsDrawing(false);
    setNewRoom(null);
    setResizing({ isResizing: false, roomId: null, handle: null });
  };
  
  

  const handleDragStart = useCallback((id) => {
    setDraggingRoomId(id);
  }, []);

  const handleDragMove = useCallback((room, e) => {
    const { x, y } = e.target.position();
    const snappedX = snapToGrid(x);
    const snappedY = snapToGrid(y);

    setRooms(prevRooms => prevRooms.map(r => 
      r.id === room.id
        ? { ...r, x: snappedX, y: snappedY }
        : r
    ));

    // Force update on the specific room's Konva node
    e.target.position({ x: snappedX, y: snappedY });
    e.target.getLayer().batchDraw();
  }, [snapToGrid, setRooms]);

  const updateDatabase = async (updatedRooms) => {
    try {
      // console.log('Updating rooms:', updatedRooms); // Log to inspect the payload
  
      // Ensure that `selectedFloor` has the correct `_id`
      const storedAppData = JSON.parse(localStorage.getItem('appData'));
      const storedAppState = JSON.parse(localStorage.getItem('appState'));
      const currentJob = storedAppData.jobs.find(job => job._id === storedAppState.currentJobID);
      const selectedFloor = currentJob.FloorPlanID.Floors.find(floor => floor._id === storedAppState.currentFloorID);
  
      // Make sure selectedFloor exists
      if (!selectedFloor) {
        // console.error('Selected floor not found');
        return;
      }
  
      // Update API call with the correct floorplanId in the URL
      await axios.put(`${API_ENDPOINT}/api/rooms/${selectedFloor._id}`, { rooms: updatedRooms });
      
      // console.log('Rooms updated successfully');
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error('Server response:', error.response.data);
      } else if (error.request) {
        // Request was made but no response received
        console.error('No response received:', error.request);
      } else {
        // Something else happened
        console.error('Error:', error.message);
      }
    }
  };
  


  const handleDragEnd = useCallback((room, e) => {
    setDraggingRoomId(null);
    const { x, y } = e.target.position();
    const snappedX = snapToGrid(x);
    const snappedY = snapToGrid(y);
  
    // Update the state with the new room positions
    setRooms(prevRooms => {
      const updatedRooms = prevRooms.map(r => 
        r.id === room.id
          ? { ...r, x: snappedX, y: snappedY } // Update room position in state
          : r
      );
  
      // Update the appData in localStorage
      const storedAppData = JSON.parse(localStorage.getItem('appData'));
      const storedAppState = JSON.parse(localStorage.getItem('appState'));
  
      if (storedAppData && storedAppState) {
        const currentJob = storedAppData.jobs?.find(job => job._id === storedAppState.currentJobID);
        if (currentJob && currentJob.FloorPlanID) {
          const selectedFloor = currentJob.FloorPlanID.Floors.find(floor => floor._id === storedAppState.currentFloorID);
  
          if (selectedFloor) {
            // Find and update the room's position in the appData
            const roomIndex = selectedFloor.rooms.findIndex(r => r.id === room.id);
            if (roomIndex !== -1) {
              selectedFloor.rooms[roomIndex].x = snappedX;
              selectedFloor.rooms[roomIndex].y = snappedY;
  
              // Save updated appData back to localStorage
              localStorage.setItem('appData', JSON.stringify(storedAppData));
            }
          } else {
            console.error('Selected floor not found in appData.');
          }
        } else {
          console.error('Current job or FloorPlanID not found in appData.');
        }
      } else {
        console.error('appData or appState not found in localStorage.');
      }
  
      // Call the API to update the database
      updateDatabase(updatedRooms);
  
      return updatedRooms;
    });
  }, [snapToGrid, updateDatabase]);
  

  const handleResizeMouseUp = (roomId) => {
    const roomIndex = rooms.findIndex(room => room.id === roomId);
    const updatedRoom = rooms[roomIndex];
  
    // Save the updated room to the backend
    updateDatabase([updatedRoom]);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      updateLocalStorage(rooms);
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [rooms]);

  const handleRoomClick = useCallback((id) => {
    setSelectedRoomId(id);
    setSelectedWall({ roomId: null, wall: null });
    setSelectedElement({ type: 'room', roomId: id, wall: null });
  }, []);

  const handleWallClick = useCallback((roomId, wall) => {
    setSelectedWall({ roomId, wall });
    setSelectedRoomId(roomId);
    setSelectedElement({ type: 'wall', roomId, wall });
  }, []);
  

  const handleMouseEnter = (e) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = 'move';
    if (e.target.name() === 'room') {
      setHoveringHandle({ roomId: e.target.attrs.id, handle: null });
    }
  };



  const handleMouseLeave = (e) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = 'default';
    setHoveringHandle({ roomId: null, handle: null });
  };

  const handleResizeMouseEnter = (e, roomId, handle) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = 'nwse-resize';
    setHoveringHandle({ roomId, handle });
  };

  const handleResizeMouseLeave = (e) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = 'default';
    setHoveringHandle({ roomId: null, handle: null });
  };

  const handleResizeMouseDown = (roomId, handle) => {
    setResizing({ isResizing: true, roomId, handle });
  };

  const handleStageClick = (e) => {
    const {target} = e;
    if (target === stageRef.current) {
      setSelectedRoomId(null);
      setSelectedWall({ roomId: null, wall: null });
    }
  };


  return (
    <div style={{ position: 'relative', paddingBottom: '25%' }}>
      <Stage
        ref={stageRef}
        width={window.innerWidth * 1.0}
        height={window.innerHeight * 3.0}
        scaleX={zoom / 100}
        scaleY={zoom / 100}
        onMouseDown={(e) => {
          handleMouseDown(e);
          handleStageClick(e);
        }}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <Layer>
          {rooms.map((room) => (
            <Room
              key={room.id}
              room={room}
              selectedRoomId={selectedRoomId}
              draggingRoomId={draggingRoomId}
              selectedWall={selectedWall} 
              setSelectedWall={setSelectedWall} 
              handleDragStart={handleDragStart}
              handleDragMove={handleDragMove}
              handleDragEnd={handleDragEnd}
              handleRoomClick={handleRoomClick}
              handleWallClick={handleWallClick}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              handleResizeMouseEnter={handleResizeMouseEnter}
              handleResizeMouseLeave={handleResizeMouseLeave}
              handleResizeMouseDown={handleResizeMouseDown}
              onWallHover={setHoveredSwatch}
              handleElementClick={handleElementClick}
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
              showCanvas={showCanvas}
              setShowCanvas={setShowCanvas} 
              handleGeneratePrompt={handleGeneratePrompt} 
            />
          ))}
          <NewRoom newRoom={newRoom} />
        </Layer>
      </Stage>
      {showCanvas && (
        <div className="overlay-canvas-container">
           <CanvasWithPrompt
              key={selectedImageUrl || 'default-key'} // Ensures re-mounting
              initialImageUrl={localStorage.getItem('selectedImageUrl') || ''}
              selectedImageUrl={selectedImageUrl}
              defaultWidth={960}
              defaultHeight={540}
              prompt={generatedPrompt}
              setImageUrl={setImageUrl}
              setShowCanvas={setShowCanvas}
              selectedRoomId={selectedRoomId}
            />
        </div>
      )}

      <ZoomControls 
        floorNames={floorNames}
        zoom={zoom} 
        handleZoomChange={handleZoomChange} 
        handleDeleteRoom={handleDeleteRoom}
        selectedRoomId={selectedRoomId}
        handleSaveFloorplan={handleSaveFloorplan} 
        isSaving={isSaving} 
        saveButtonText={saveButtonText}
        onFloorChange={onFloorChange}
      />
    </div>
  );
};

export default FloorPlan;
