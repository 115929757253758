import { borderRadius, styled } from '@mui/system';
import { Box, Typography } from '@mui/material';

const commonFontFamily = '"Figtree", sans-serif';
const commonBorderRadius = '5px';
const commonTransition = '0.2s';

const theme = {
  palette: {
    primary: {
      main: '#007bff', // Example primary color
    },
    text: {
      secondary: '#666', // Example secondary text color
    },
  },

  typography: {
    fontFamily: commonFontFamily, // Set your default font here
    fontSize: 36,
    fontWeightBold: 700,
  },

  selectFormControl: {
    backgroundColor: 'rgba(255, 255, 255, 1)', 
    marginTop: '16px', 
    marginRight: '16px', 
    borderRadius: '12px',
    minWidth: '200px', 
    '& .MuiOutlinedInput-root': { 
      borderRadius: '12px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black', 
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'darkblue', 
    }
  },

  buttons: {
    delete: (selectedRoomId) => ({
      marginLeft: '20px',
      color: 'white',
      border: 'none',
      padding: '5px 10px',
      borderRadius: '4px',
      cursor: selectedRoomId ? 'pointer' : 'not-allowed',
      backgroundColor: selectedRoomId ? '#ff4d4d' : '#ffb3b3',
      opacity: selectedRoomId ? 1 : 0.6,
      transition: 'all 0.3s ease',
    }),
    save: {
      marginLeft: '20px',
      color: 'white',
      border: 'none',
      padding: '5px 10px',
      borderRadius: '4px',
      cursor: 'pointer',
      backgroundColor: 'green',
      transition: 'all 0.3s ease',
    },
  },

  tabs: {
    root: {
      backgroundColor: 'rgb(235,235,235)', // Background color of the Tabs container
    },
    tab: {
      base: {
        textTransform: 'none', // Prevents text from being all uppercase
        fontWeight: 'normal',
        fontSize: '1.0rem',
        fontFamily: commonFontFamily,
        color: '#000', // Normal text color
        '&:hover': {
          transition: 'background-color 1s',
          color: '#fff', // Text color on hover
          fontWeight: 'bold',
          backgroundColor: '#007bff'
        },
      },
      selected: {
        textTransform: 'UPPERCASE',
        color: '#28a745', // Text color when selected
        fontWeight: 'bold', // Font weight when selected
        fontSize: '1.0rem',
        backgroundColor: '#fff'
      },
    },
  },  

  outerBox: {
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)', // For Safari support
    border: '10px solid #fff',
    borderRadius: '100px',
    backgroundColor: 'rgba(0, 0, 0, 1)',
    padding: 0,
  },

  innerBox: {
    borderRadius: commonBorderRadius,
    padding: '8px',
    margin: '8px',
  },

  sliderLabel: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '5px 5px',
    color: 'black',
  },

  inputStyle: {
    margin: '10px 0',
    '& .MuiInputBase-root': {
      height: '50px', // Fixed height for consistency
      minHeight: '50px', // Ensures a minimum height
      lineHeight: '50px', // Adjust line-height to match height
      padding: '0 8px', // Adds padding for consistent text positioning
    },
    '& .MuiInputLabel-root': {
      fontSize: '1.0rem',
      color: 'black',
      fontFamily: commonFontFamily,
    },
    '& .MuiInputBase-input': {
      fontSize: '1.0rem',
      color: 'black',
      fontFamily: commonFontFamily,
      padding: '0', // Removes padding for text
    },
    '& .MuiOutlinedInput-root': {
      height: '50px', // Ensures total input height is 50px
      minHeight: '50px',
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1565c0',
      },
    },
  },
  

  successButton: {
    backgroundColor: 'green',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  blurredBackground: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)', // For Safari support
    border: '0px solid #fff',
  },

  canvas: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    position: 'relative',
    outlineStyle: 'dashed',
    outlineColor: 'rgba(0, 0, 0, 0.3)',
    maxWidth: '100%',
  },

  uploadButton: {
    margin: '10px 0',
    backgroundColor: '#007bff',
    color: 'white',
    '&:hover': {
      backgroundColor: '#28a745',
    },
  },

  positionedActionButton: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    backgroundColor: '#007bff',
    color: 'white',
    '&:hover': {
      backgroundColor: '#28a745',
    },
  },

  customTooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    border: '2px solid white',
    borderRadius: commonBorderRadius,
    fontSize: '18px',
    padding: '8px',
  },

  customToggleButton: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '5px 5px',
    color: 'black',
  },

  spacedCustomToggleButtonGroup: {
    '& .MuiToggleButtonGroup-grouped': {
      margin: '14px', // Adjust to increase or decrease spacing
      border: 0,
      '&:not(:first-of-type)': {
        borderRadius: commonBorderRadius, // Adjust to match your desired border radius
      },
      '&:first-of-type': {
        borderRadius: commonBorderRadius, // Adjust to match your desired border radius
      },
    },
  },

  linedCustomToggleButtonGroup: {
    '& .MuiToggleButtonGroup-grouped': {
      border: 0,
      '&:not(:last-of-type)': {
        borderRight: '4px solid rgba(0, 0, 0, 1)', // Adjust the color to match your design
      },
    },
  },

  centeredOverlayBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    backdropFilter: 'blur(100px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: commonBorderRadius,
    zIndex: 1000,
  },

  listItem: {
    cursor: 'pointer',
    borderRadius: '8px', 
    transition: 'background-color 0.5s',
    '&:hover': {
      backgroundColor: 'lightblue', 
    },
  },

  listItemTextName: {
    color: '#000', 
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: commonFontFamily, 
    '&:hover': {
      color: '#007bff', 
    },
  },

  listItemText: {
    color: '#666', // Text color
    fontSize: '14px',
    fontWeight: 'normal',
    fontFamily: commonFontFamily,
    '&:hover': {
      color: '#007bff', // Change text color on hover
    },
  },

  customFormBox: {
    mb: 1,
    border: '1px solid rgba(0, 0, 0, 0)',
    borderRadius: '0px 0px 0 0',
    p: 2,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  },
  
  customLogoBox: {
    backgroundImage: 'url("/images/avatars/backdrops/backdrop_0001.webp")', // Replace with actual image path or dynamically set
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '20px 20px 0 0', // Top-left and top-right corner radius
    width: '100%',
    height: '175px', // Adjust height as needed
    position: 'relative',
  },

  customLogoGreyBox: {
    backgroundColor: '#f0f0f0',
    width: '100%',
    height: '50px', // Adjust height as needed
    position: 'absolute',
    bottom: 0,
    left: 0,
  },

  customLogoAvatar: {
    width: '140px', 
    height: '140px',
    border: '4px solid white', 
    position: 'absolute',
    top: 'calc(100% - 85px)', 
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    '&:hover .avatar-overlay': {
      opacity: 1, 
    },
    position: 'relative', 
  },

  avatarOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    opacity: 0,
    transition: `opacity ${commonTransition} ease-in-out`,
    borderRadius: '50%',
  },

  overlayIcon: {
    fontSize: '2rem',
    marginBottom: '0.5rem',
  },

  overlayText: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },

  styledComponents: {
    SwatchBoxRoom: styled(Box)(({ selected }) => ({
      width: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '3px',
      borderRadius: commonBorderRadius,
      backgroundColor: '#fff',
      margin: '2px 2px',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
      transition: `transform ${commonTransition}, box-shadow ${commonTransition}, border ${commonTransition}`,
      transform: selected ? 'scale(0.9)' : 'none',
      border: selected ? '2px solid black' : 'none',
      '&:hover': {
        transform: 'scale(1.1)',
        border: '1px solid black',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.4)',
        cursor: 'pointer',
      },
    })),

    SwatchColorBoxRoom: styled(Box)({
      width: '95px',
      height: '95px',
      borderRadius: commonBorderRadius,
      padding: '3px',
      marginBottom: '15px',
      position: 'relative',
    }),

    SwatchNameRoom: styled(Typography)({
      fontSize: '12px',
      textAlign: 'center',
    }),

    SwatchBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '5px',
      borderRadius: '3px',
      backgroundColor: '#fff',
      margin: '15px 5px',
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 1)',
    },

    SwatchColorBox: {
      width: '150px',
      height: '175px',
      borderRadius: '3px',
      border: '0px solid #333',
      marginBottom: '5px',
      position: 'relative',
    },

    SwatchHex: {
      position: 'absolute',
      bottom: '5px',
      left: '10px',
      fontFamily: commonFontFamily,
      fontSize: '14px',
    },

    SwatchName: {
      marginTop: '5px',
      fontFamily: commonFontFamily,
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#222',
    },
  },
};

export default theme;
