import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Box, InputAdornment, Button, Divider, Typography, IconButton, Popover } from '@mui/material';
import { Check, PhoneIphone, MailOutline, HelpOutline } from '@mui/icons-material';
import theme from '../../theme';

const ContractProject = ({
  contractState,
  handleContractInputChange,
  handleSocialMediaChange,
  handleSubmit,
  updateSuccess,
  isUpdating,
  handleImageUpload,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');

  const handlePopoverOpen = (event, content) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent('');
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={theme.innerBox}>
      <Box sx={{ mb: 3, p: 1, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
        <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Job Details
                </Typography>
                <IconButton
                  onClick={(e) => handlePopoverOpen(e, 'This is the description for General Liability Insurance. It covers potential liabilities and damages.')}
                  size="small"
                >
                  <HelpOutline fontSize="small" />
                </IconButton>
              </Box>
          <TextField
            label="Job Name"
            value={contractState?.jobName || ''}
            onChange={(e) => handleContractInputChange('jobName', e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
          />
          <TextField
            label="Contract Date"
            type="date"
            value={contractState?.contractDate ? new Date(contractState.contractDate).toISOString().split('T')[0] : ''}
            onChange={(e) => handleContractInputChange('contractDate', new Date(e.target.value))}
            fullWidth
            variant="outlined"
            sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        
        <Divider sx={{ my: 2 }} />
        <Box sx={{ mb: 3, p: 1, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Company Details
            </Typography>
            <IconButton
              onClick={(e) => handlePopoverOpen(e, 'This is the description for General Liability Insurance. It covers potential liabilities and damages.')}
              size="small"
            >
              <HelpOutline fontSize="small" />
            </IconButton>
          </Box>
          <TextField
            label="Contractor Name"
            value={contractState?.contactName || ''}
            onChange={(e) => handleContractInputChange('contactName', e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
          />
          <TextField
            label="Contractor Company"
            value={contractState?.contractorDetails?.company || ''}
            onChange={(e) => handleContractInputChange('contractorDetails.company', e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
          />
          <TextField
            label="Contractor Phone"
            value={contractState?.contractorDetails?.phone || ''}
            onChange={(e) => handleContractInputChange('contractorDetails.phone', e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIphone />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Contractor Email"
            value={contractState?.contractorDetails?.email || ''}
            onChange={(e) => handleContractInputChange('contractorDetails.email', e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutline />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ mb: 3, p: 1, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Client Details
            </Typography>
            <IconButton
              onClick={(e) => handlePopoverOpen(e, 'This is the description for General Liability Insurance. It covers potential liabilities and damages.')}
              size="small"
            >
              <HelpOutline fontSize="small" />
            </IconButton>
          </Box>
          <TextField
            label="Client Name"
            value={contractState?.clientDetails?.name || ''}
            onChange={(e) => handleContractInputChange('clientDetails.name', e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
          />
          <TextField
            label="Client Address"
            value={contractState?.clientDetails?.address || ''}
            onChange={(e) => handleContractInputChange('clientDetails.address', e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
          />
          <TextField
            label="Additional Contact Person"
            value={contractState?.additionalContactPerson || ''}
            onChange={(e) => handleContractInputChange('additionalContactPerson', e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
          />
          <TextField
            label="Additional Contact Details"
            value={contractState?.rolesAndResponsibilities || ''}
            onChange={(e) => handleContractInputChange('rolesAndResponsibilities', e.target.value)}
            multiline
            rows={2}
            fullWidth
            variant="outlined"
            sx={{ ...theme.inputStyle, backgroundColor: 'rgb(255,255,255, 1.0)' }}
          />
          <Button
            variant="contained"
            color={updateSuccess ? 'success' : 'primary'}
            onClick={handleSubmit}
            fullWidth
            sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
            disabled={isUpdating}
          >
            {isUpdating
              ? 'Updating Details...'
              : updateSuccess
              ? 'Details Updated'
              : 'Update Details'}
            {updateSuccess && <Check style={{ marginLeft: '4px' }} />}
          </Button>
        </Box>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography>{popoverContent}</Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default ContractProject;