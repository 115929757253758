import React, { useCallback, useState, useEffect } from 'react';
import { Rect, Text, Image } from 'react-konva';
import { HANDLE_SIZE, PIXELS_PER_METER } from './Constants';


// import CanvasWithPrompt from './CanvasWithPrompt'; // Import CanvasWithPrompt
// import { createPortal } from 'react-dom'; // Import for React Portals

const Room = ({
  room,
  selectedRoomId,
  draggingRoomId,
  handleDragStart,
  handleDragMove,
  handleDragEnd,
  handleRoomClick,
  handleWallClick,
  handleMouseEnter,
  handleMouseLeave,
  handleResizeMouseEnter,
  handleResizeMouseLeave,
  handleResizeMouseDown,
  selectedWall,
  setSelectedWall,
  handleMouseEnterElement,
  handleElementClick,
  selectedElement,
  setSelectedElement,
  showCanvas,
  setShowCanvas,
  handleGeneratePrompt,
}) => {
  const showHandles = selectedRoomId === room.id;
  const [iconImages, setIconImages] = useState({});

const deselectAndSelectElement = useCallback((elementType) => {
      handleElementClick(room.id, elementType, null);
  }, [handleElementClick, room.id]);

  const handleBoxClick = useCallback((element) => {
    if (element === 'AI') {
      setShowCanvas(true); // Open the canvas immediately
      handleGeneratePrompt(room); // Trigger the prompt generation asynchronously
    }
    deselectAndSelectElement(element);
  }, [deselectAndSelectElement, setShowCanvas, handleGeneratePrompt, room]);
  
  
  
  const handleWallClickAndDeselect = useCallback((wall) => {
    handleElementClick(room.id, 'wall', wall); 
  }, [room.id, handleElementClick]);

  const onRoomClick = useCallback((e) => {
    e.cancelBubble = true;
    handleElementClick(room.id, 'room', null);
  }, [room.id, handleElementClick]);

  const onDragStart = useCallback(() => handleDragStart(room.id), [room.id, handleDragStart]);
  const onDragMove = useCallback((e) => handleDragMove(room, e), [room, handleDragMove]);
  const onDragEnd = useCallback((e) => handleDragEnd(room, e), [room, handleDragEnd]);

  const onWallClick = useCallback((wall) => (e) => {
    e.cancelBubble = true;
    handleWallClickAndDeselect(wall);
    setSelectedElement({ type: 'wall', roomId: room.id, wall });
  }, [handleWallClickAndDeselect, room.id, setSelectedElement]);

  const handleMouseLeaveElement = useCallback((e) => {
    e.target.getStage().container().style.cursor = 'default';
  }, []);

  const onTextClick = useCallback((e) => { e.cancelBubble = true; }, []);



  const getSelectedElementText = useCallback(() => {
    if (selectedWall.roomId === room.id) {
      if (selectedWall.wall === 'ceilingColour') {
        return 'Ceiling selected';
      } else if (selectedWall.wall) {
        return `${selectedWall.wall.charAt(0).toUpperCase() + selectedWall.wall.slice(1)} wall selected`;
      }
    }
    if (selectedElement && typeof selectedElement === 'object') {
      const elementType = selectedElement.type;
      return `${elementType.charAt(0).toUpperCase() + elementType.slice(1)}`;
    }
    return 'Room selected';
  }, [selectedWall, room.id, selectedElement]);

  const renderWall = useCallback((wall) => (
    <Rect
      key={wall}
      name="wall"
      x={wall === 'left' || wall === 'right' ? (wall === 'left' ? room.x : room.x + room.width - HANDLE_SIZE * 2.5) : room.x}
      y={wall === 'top' || wall === 'bottom' ? (wall === 'top' ? room.y : room.y + room.length - HANDLE_SIZE * 2.5) : room.y}
      width={wall === 'left' || wall === 'right' ? HANDLE_SIZE * 2.5 : room.width}
      height={wall === 'top' || wall === 'bottom' ? HANDLE_SIZE * 2.5 : room.length}
      fill={room.walls?.[wall]?.color || 'white'}
      stroke={selectedWall.roomId === room.id && selectedWall.wall === wall ? 'aqua' : 'white'}
      strokeWidth={selectedWall.roomId === room.id && selectedWall.wall === wall ? 4 : 1}
      onClick={onWallClick(wall)}
      onMouseEnter={handleMouseEnterElement}
      onMouseLeave={handleMouseLeaveElement}
    />
  ), [room, selectedWall, onWallClick, handleMouseEnterElement, handleMouseLeaveElement]);

  const renderHandle = useCallback((handle) => (
    <Rect
      key={handle}
      name="handle"
      x={handle.includes('left') ? room.x - HANDLE_SIZE / 2 : room.x + room.width - HANDLE_SIZE / 2}
      y={handle.includes('top') ? room.y - HANDLE_SIZE / 2 : room.y + room.length - HANDLE_SIZE / 2}
      width={HANDLE_SIZE}
      height={HANDLE_SIZE}
      stroke="darkblue"
      strokeWidth={2}
      fill="white"
      onMouseEnter={(e) => handleResizeMouseEnter(e, room.id, handle)}
      onMouseLeave={handleResizeMouseLeave}
      onMouseDown={() => handleResizeMouseDown(room.id, handle)}
      visible
    />
  ), [room, handleResizeMouseEnter, handleResizeMouseLeave, handleResizeMouseDown]);

  const renderCeiling = useCallback(() => {
    if (selectedRoomId !== room.id) {
      return null;
    }

    const ceilingYPosition = room.y - 70;
    const ceilingColor = room.ceilingColour?.color || 'lightgrey';
    const strokeWidthCeiling = selectedElement && selectedElement.type === 'ceilingColour' && selectedElement.roomId === room.id ? 6 : 1;
    const strokeColorCeiling = selectedElement && selectedElement.type === 'ceilingColour' && selectedElement.roomId === room.id ? 'aqua' : 'black';

    return (
      <>
        <Rect
          name="ceiling-outline"
          x={room.x}
          y={ceilingYPosition}
          width={room.width}
          height={40}
          strokeWidth={strokeWidthCeiling}
          stroke={strokeColorCeiling}
        />
        <Rect
          name="ceiling"
          x={room.x + 1}
          y={ceilingYPosition + 1}
          width={room.width - 2}
          height={38}
          fill={ceilingColor}
          stroke="white"
          strokeWidth={1}
          onClick={() => handleBoxClick('ceilingColour')}
          onMouseEnter={handleMouseEnterElement}
          onMouseLeave={handleMouseLeaveElement}
        />
      </>
    );
  }, [room, selectedRoomId, handleBoxClick, handleMouseEnterElement, handleMouseLeaveElement]);

  const renderLightIcon = useCallback(() => {
    if (!iconImages.light || selectedRoomId !== room.id) {
      return null;
    }

    const iconX = room.x + room.width / 2 - 15;
    const iconY = room.y - 72;

    return <Image image={iconImages.light} x={iconX} y={iconY} width={30} height={30} />;
  }, [iconImages.light, selectedRoomId, room]);

  const renderAdditionalBoxes = useCallback(() => {
    if (selectedRoomId !== room.id) {
      return null;
    }

    const boxWidth = 50;
    const boxHeight = 50;
    const gap = 20;
    const startXRight = room.x + room.width + gap;
    const startYRight = room.y;
    const startXBelow = room.x;
    const startYBelow = room.y + room.length + gap;

    const isSelected = (element) => selectedElement && selectedElement.type === element;

    return (
      <>
        {['windowColour', 'doorColour', 'architraveColour', 'skirtingBoardColour'].map((type, index) => (
          <React.Fragment key={type}>
            <Rect
              x={startXRight}
              y={startYRight + index * (boxHeight + gap)}
              width={boxWidth}
              height={boxHeight}
              fill={room[type]?.color || 'white'}
              stroke={isSelected(type) ? 'aqua' : 'black'}
              strokeWidth={6}
              cornerRadius={0}
              onClick={() => handleBoxClick(type)}
              onMouseEnter={handleMouseEnterElement}
              onMouseLeave={handleMouseLeaveElement}
            />
            {iconImages[type] && (
              <Image
                image={iconImages[type]}
                x={startXRight + 10}
                y={startYRight + index * (boxHeight + gap) + 10}
                width={30}
                height={30}
                onClick={() => handleBoxClick(type)}
                onMouseEnter={handleMouseEnterElement}
                onMouseLeave={handleMouseLeaveElement}
              />
            )}
          </React.Fragment>
        ))}

        {['AI', 'palette'].map((type, index) => (
          <React.Fragment key={type}>
            <Rect
              x={startXBelow + index * (boxWidth + gap)}
              y={startYBelow}
              width={boxWidth}
              height={boxHeight}
              fill="white"
              stroke={isSelected(type) ? 'aqua' : 'black'}
              strokeWidth={6}
              cornerRadius={0}
              onClick={() => handleBoxClick(type)}
              onMouseEnter={handleMouseEnterElement}
              onMouseLeave={handleMouseLeaveElement}
            />
            {iconImages[type] && (
              <Image
                image={iconImages[type]}
                x={startXBelow + index * (boxWidth + gap) + 10}
                y={startYBelow + 10}
                width={30}
                height={30}
                onClick={() => handleBoxClick(type)}
                onMouseEnter={handleMouseEnterElement}
                onMouseLeave={handleMouseLeaveElement}
              />
            )}
          </React.Fragment>
        ))}
      </>
    );
  }, [selectedRoomId, room, selectedElement, iconImages, handleBoxClick, handleMouseEnterElement, handleMouseLeaveElement]);

  useEffect(() => {
    const loadIcons = async () => {
      const iconTypes = ['windowColour', 'doorColour', 'skirtingBoardColour', 'light', 'architraveColour', 'AI', 'palette'];
      const loadedIcons = await Promise.all(
        iconTypes.map(async (type) => {
          try {
            const img = new window.Image();
            img.src = `/icons/${type}_icon.png`;
            await new Promise((resolve, reject) => {
              img.onload = resolve;
              img.onerror = reject;
            });
            return [type, img];
          } catch (error) {
            console.error(`Failed to load icon for ${type}:`, error);
            return [type, null];
          }
        })
      );
      const iconObject = Object.fromEntries(loadedIcons.filter(([, img]) => img !== null));
      setIconImages(iconObject);
    };

    loadIcons();
  }, []);

  return (
    <React.Fragment>
      {renderCeiling()}
      {renderLightIcon()}
      {renderAdditionalBoxes()}
      <Rect
        name="room"
        id={room.id}
        x={room.x}
        y={room.y}
        width={room.width}
        height={room.length}
        fill={selectedRoomId === room.id || draggingRoomId === room.id ? 'lightblue' : '#eee'}
        stroke={draggingRoomId === room.id ? 'black' : 'black'}
        strokeWidth={20}
        draggable
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        onClick={onRoomClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <Text
        name="roomName"
        x={room.x + room.width / 2}
        y={room.y + room.length / 2 - 20}
        text={room.name}
        fontSize={16}
        fill="black"
        align="center"
        verticalAlign="middle"
        width={Math.abs(room.width)}
        offsetX={Math.abs(room.width) / 2}
        onClick={onTextClick}
      />
      <Text
        name="selectedElementText"
        x={room.x + room.width / 2}
        y={room.y + room.length / 2}
        text={getSelectedElementText()}
        fontSize={14}
        fill="black"
        align="center"
        verticalAlign="middle"
        width={Math.abs(room.width)}
        offsetX={Math.abs(room.width) / 2}
        visible={selectedRoomId === room.id}
      />
      {Math.abs(room.width) >= PIXELS_PER_METER * 2 && Math.abs(room.length) >= PIXELS_PER_METER * 2 && (
        <Text
          name="roomDimensions"
          x={room.x + room.width / 2}
          y={room.y + room.length / 2 + 20}
          text={`${Math.abs(room.width / PIXELS_PER_METER).toFixed(2)}m x ${Math.abs(room.length / PIXELS_PER_METER).toFixed(2)}m`}
          fontSize={12}
          fill="black"
          align="center"
          verticalAlign="middle"
          width={Math.abs(room.width)}
          offsetX={Math.abs(room.width) / 2}
          onClick={onTextClick}
        />
      )}
      {['top', 'bottom', 'left', 'right'].map(renderWall)}
      {showHandles && ['top-left', 'top-right', 'bottom-left', 'bottom-right'].map(renderHandle)}

    </React.Fragment>
  );
};

export default Room;
