import { Slider } from '@mui/material';
import { styled } from '@mui/system';

const CustomSlider = styled(Slider)({
  '& .MuiSlider-track': {
    background: 'linear-gradient(to right, lightblue, darkblue)',
    height: 10, // Adjust the thickness of the slider bar
  },
  '& .MuiSlider-rail': {
    height: 5, // Adjust the thickness of the slider bar
    opacity: 0.5,
  },
  '& .MuiSlider-thumb': {
    width: 28, // Adjust the size of the thumb
    height: 28, // Adjust the size of the thumb
    backgroundColor: '#fff',
    border: '3px solid currentColor',
    '&:hover, &$active': {
      boxShadow: '0px 0px 0px 14px rgba(0, 0, 0, 0.66)',
    },
  },
});

export default CustomSlider;
