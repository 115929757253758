import React, { useState, useEffect } from 'react';
import { TextField, Box, Button, Typography, IconButton, Popover } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { Check, HelpOutline } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import theme from '../../theme';

const ContractAccess = ({
    contractState,
    handleContractInputChange,
    handleSubmit,
    updateSuccess,
    isUpdating,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState('');

    const parseTimeString = (timeString) => {
        if (!timeString) return null;
        const [hours, minutes] = timeString.split(':').map(Number);
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    };

    const [startTime, setStartTime] = useState(contractState.propertyAccessStartTime ? parseTimeString(contractState.propertyAccessStartTime) : null);
    const [endTime, setEndTime] = useState(contractState.propertyAccessEndTime ? parseTimeString(contractState.propertyAccessEndTime) : null);
    
    const [largeText, setLargeText] = useState(contractState.accessInstructions || ''); // Initial large text content
    const [textPopoverAnchorEl, setTextPopoverAnchorEl] = useState(null); // Anchor for the text popover
    const [alarmCodes, setAlarmCodes] = useState(
        (contractState.alarmCodes && contractState.alarmCodes.length === 6
            ? contractState.alarmCodes
            : Array(6).fill(''))
    );

    const formatTime = (date) => {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    
    useEffect(() => {
        handleContractInputChange('propertyAccessStartTime', startTime ? formatTime(startTime) : '');
        handleContractInputChange('propertyAccessEndTime', endTime ? formatTime(endTime) : '');
        handleContractInputChange('alarmCodes', alarmCodes);
    }, []);

    const handlePopoverOpen = (event, content) => {
        setAnchorEl(event.currentTarget);
        setPopoverContent(content);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverContent('');
    };

    const handleTextPopoverOpen = (event) => {
        setTextPopoverAnchorEl(event.currentTarget);
    };

    const handleTextPopoverClose = () => {
        setTextPopoverAnchorEl(null);
        handleContractInputChange('accessInstructions', largeText); // Update main text field
    };

    const handleTimeChange = (type, newValue) => {
        if (!newValue || !newValue.isValid()) return; // Ensure newValue is valid
    
        const formattedTime = `${newValue.hour().toString().padStart(2, '0')}:${newValue.minute().toString().padStart(2, '0')}`;
        
        if (type === 'start') {
            setStartTime(newValue);
            handleContractInputChange('propertyAccessStartTime', formattedTime);
        } else if (type === 'end') {
            setEndTime(newValue);
            handleContractInputChange('propertyAccessEndTime', formattedTime);
        }
    };
    
    

    const handleAlarmCodeChange = (index, value) => {
        if (!/^\d?$/.test(value)) return; // Only allow 1 digit and numeric input
        const updatedAlarmCodes = [...alarmCodes];
        updatedAlarmCodes[index] = value;
        setAlarmCodes(updatedAlarmCodes);
        handleContractInputChange('alarmCodes', updatedAlarmCodes);
    };

    const handleKeyUp = (index, event) => {
        if (event.target.value.length === 1 && index < 5) {
            // Move focus to the next input if filled and not the last field
            document.getElementById(`alarm-code-${index + 1}`).focus();
        } else if (event.key === 'Backspace' && !event.target.value && index > 0) {
            // Move focus to the previous input if backspace is pressed and input is empty
            document.getElementById(`alarm-code-${index - 1}`).focus();
        }
    };
    
    const open = Boolean(anchorEl);
    const textPopoverOpen = Boolean(textPopoverAnchorEl);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={theme.innerBox}>
                <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
                    <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                Property Access
                            </Typography>
                            <IconButton 
                                onClick={(e) => handlePopoverOpen(e, 'This is the description for General Liability Insurance. It covers potential liabilities and damages.')}
                                size="small"
                            >
                                <HelpOutline fontSize="small" />
                            </IconButton>
                        </Box>
                        <TimePicker
                            label="Start Time"
                            value={startTime}
                            onChange={(newValue) => handleTimeChange('start', newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth sx={theme.inputStyle} />}
                        />
                        <TimePicker
                            label="End Time"
                            value={endTime}
                            onChange={(newValue) => handleTimeChange('end', newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth sx={theme.inputStyle} />}
                        />
                    </Box>

                    <Box sx={{ mb: 0, p: 1, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                        {/* Alarm Security Codes */}
                        <Box sx={{ mt: 0,mb: 2 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                                Alarm Security Codes
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 0 }}>
                            {Array.from({ length: 6 }, (_, index) => (
                                <TextField
                                    key={index}
                                    id={`alarm-code-${index}`}
                                    value={alarmCodes[index] || ''}
                                    onChange={(e) => handleAlarmCodeChange(index, e.target.value)}
                                    onKeyUp={(e) => handleKeyUp(index, e)}
                                    inputProps={{
                                        maxLength: 1,
                                        style: { fontSize: '0.875rem', textAlign: 'center' }
                                    }}
                                    sx={{
                                        width: '40px',
                                        textAlign: 'center',
                                        '& .MuiOutlinedInput-root': {
                                            textAlign: 'center',
                                        },
                                    }}
                                />
                            ))}
</Box>

                        </Box>

                        {/* Small TextField that opens the larger TextField */}
                        <TextField
                            label="Access Instructions"
                            value={contractState.accessInstructions}
                            onClick={handleTextPopoverOpen}
                            fullWidth
                            variant="outlined"
                            sx={{
                                ...theme.inputStyle,
                                '& .MuiOutlinedInput-root': { border: 'none' },
                            }}
                            InputProps={{ readOnly: true }} // Make it read-only to trigger the larger field
                        />

                        <TextField
                            label="Client Preparation"
                            value={contractState.clientPreparation}
                            onChange={(e) => handleContractInputChange('clientPreparation', e.target.value)}
                            fullWidth
                            variant="outlined"
                            multiline
                            rows={3}
                            sx={{
                                ...theme.inputStyle,
                                '& .MuiOutlinedInput-root': { border: 'none' },
                            }}
                        />
                        <TextField
                            label="Utilities Access (e.g., Water, Electricity)"
                            value={contractState.utilitiesAccess}
                            onChange={(e) => handleContractInputChange('utilitiesAccess', e.target.value)}
                            fullWidth
                            variant="outlined"
                            sx={{
                                ...theme.inputStyle,
                                '& .MuiOutlinedInput-root': { border: 'none' },
                            }}
                        />

                        
                        <Button
                            variant="contained"
                            color={updateSuccess ? 'success' : 'primary'}
                            onClick={handleSubmit}
                            fullWidth
                            sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
                            disabled={isUpdating}
                        >
                            {isUpdating
                                ? 'Updating Details...'
                                : updateSuccess
                                ? 'Details Updated'
                                : 'Update Details'}
                            {updateSuccess && <Check style={{ marginLeft: '4px' }} />}
                        </Button>
                    </Box>
                </Box>
                
                {/* Popover for description */}
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{ p: 2 }}>
                        <Typography>{popoverContent}</Typography>
                    </Box>
                </Popover>

                {/* Popover for large text input */}
                <Popover
                    open={textPopoverOpen}
                    anchorEl={textPopoverAnchorEl}
                    onClose={handleTextPopoverClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Box sx={{ p: 2, width: 400 }}>
                        <TextField
                            label="Edit Access Instructions"
                            value={largeText}
                            onChange={(e) => setLargeText(e.target.value)}
                            fullWidth
                            multiline
                            rows={6}
                            variant="outlined"
                        />
                        <Button onClick={handleTextPopoverClose} sx={{ mt: 2 }} variant="contained">Save</Button>
                    </Box>
                </Popover>
            </Box>
        </LocalizationProvider>
    );
};

export default ContractAccess;
