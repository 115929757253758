import React, { useState, useEffect, useRef } from 'react';
import { AddAPhoto, Assessment } from '@mui/icons-material';
import { Box, Typography, InputLabel, Select, MenuItem, Button, CircularProgress, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { NavToggleButtonGroup, NavToggleButton, InPageToggleButtonGroup, InPageToggleButton } from './CustomToggleButton'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import theme from './theme';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const DropZone = styled(Box)(({ theme, isdragactive }) => ({
  border: `2px dashed ${isdragactive ? theme.palette.primary.main : theme.palette.grey[400]}`,
  borderRadius: '8px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'border 0.3s ease-in-out',
}));

const Thumbnail = styled(Box)({
  width: '240px',
  height: '120px',
  margin: '5px',
  position: 'relative',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '4px',
  },
});

const InspectionOptions = ({ 
  setInspectionReport, 
  activeTab,
  setActiveTab
}) => {
  const [rooms, setRooms] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState('');
  const [selectedOption, setSelectedOption] = useState('photos');
  const [images, setImages] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isdragactive, setIsDragActive] = useState(false);
  const [buttonState, setButtonState] = useState('Email IN');
  const [code, setCode] = useState('');

  const fileInputRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    try {
      const appData = JSON.parse(localStorage.getItem('appData'));
      if (appData?.jobs?.[0]?.FloorPlanID?.Floors) {
        const roomsList = appData.jobs[0].FloorPlanID.Floors.flatMap(
          floor => floor.rooms || []
        ).map(room => ({
          id: room._id,
          name: room.name || 'Unnamed Room',
        })).filter(room => room.id && room.name);
        
        setRooms(roomsList);
      }
    } catch (err) {
      console.error('Error loading rooms:', err);
      setError('Error loading rooms');
    }
  }, []);

  const handleRoomChange = (event) => {
    const value = event.target.value;
    setSelectedRoomId(value);
    
    // Find the index of the selected room and update the active tab
    const roomIndex = rooms.findIndex(room => room.id === value);
    if (roomIndex !== -1) {
      setActiveTab(roomIndex);
    }
  };

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  const handleEmailIn = () => {
    if (buttonState === 'Email IN') {
      const ctx = canvasRef.current.getContext('2d');
      ctx.fillStyle = '#ccc';
      ctx.fillRect(0, 0, 210, 210);
      const newCode = Math.floor(1000 + Math.random() * 9000).toString();
      setCode(newCode);

      ctx.fillStyle = '#000';
      ctx.font = '20px Arial';
      ctx.fillText(newCode, 10, 50);

      setButtonState('Check Email');
    } else if (buttonState === 'Check Email') {
      handleCheckEmail();
    }
  };

  const handleCheckEmail = async () => {
    setLoading(true);
    setError(null);

    try {
      const appData = JSON.parse(localStorage.getItem('appData'));
      const userEmail = appData.account.email;

      const response = await axios.get(`${API_ENDPOINT}/api/check-email`, {
        params: {
          subject: code,
          from: userEmail,
        },
      });

      if (response.data && response.data.imageUrl) {
        const imageResponse = await fetch(response.data.imageUrl);
        const imageBlob = await imageResponse.blob();

        const fileName = `email_image_${Date.now()}.jpg`;
        const file = new File([imageBlob], fileName, { type: imageBlob.type });

        const thumbnail = await createThumbnail(file);

        const newImages = [...images, { file, thumbnail }];
        if (newImages.length > 5) {
          newImages.splice(0, newImages.length - 5);
        }
        setImages(newImages);

        setButtonState('Generate Report');
      } else {
        setError('No matching email found or no image attached.');
      }
    } catch (err) {
      console.error('Error checking email:', err);
      setError('Error checking email');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateReport = async () => {
    if (!selectedRoomId || images.length === 0) return;
  
    const appData = JSON.parse(localStorage.getItem('appData')) || {};
    const appState = JSON.parse(localStorage.getItem('appState')) || {};
    const userId = appState.userId;
    const jobId = appState.currentJobID;
  
    if (!jobId) {
      console.warn("currentJobID not found in appState");
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const formData = new FormData();
      images.forEach((imageObj, index) => {
        formData.append('images', imageObj.file, `inspection_${index + 1}.jpg`);
      });
      formData.append('jobId', jobId);
      formData.append('roomId', selectedRoomId);
      formData.append('createdBy', userId);
  
      const response = await axios.post(`${API_ENDPOINT}/api/generate-inspection-report`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      if (!appData.jobs) appData.jobs = [];
      const job = appData.jobs.find(j => j._id === jobId) || {};
      if (!job.inspectionReports) job.inspectionReports = {};
  
      job.inspectionReports[selectedRoomId] = response.data;
  
      // Update localStorage
      localStorage.setItem('appData', JSON.stringify(appData));
  
      // Update the inspection report state
      setInspectionReport(prevReports => ({
        ...prevReports,
        [selectedRoomId]: response.data,
      }));
  
      // Sync inspection reports with Inspection component if needed
      window.dispatchEvent(new Event("inspectionReportUpdate"));
  
      setActiveTab(rooms.findIndex(room => room.id === selectedRoomId));
      setSelectedOption('report');
      setImages([]);
      setButtonState('Email IN');
  
    } catch (err) {
      console.error('Error generating report:', err);
      setError('Error generating report');
    } finally {
      setLoading(false);
    }
  };
  




  const createThumbnail = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const aspectRatio = img.width / img.height;

          const targetWidth = 210;
          const targetHeight = 210 / aspectRatio;
          canvas.width = targetWidth * 2;
          canvas.height = targetHeight * 2;

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          const dataUrl = canvas.toDataURL('image/jpeg');
          resolve(dataUrl);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (files) => {
    if (files && files.length > 0) {
      const maxImages = 5;
      let newImages = [...images];
      const remainingSlots = maxImages - newImages.length;
      const filesToProcess = Array.from(files).slice(0, remainingSlots);

      const imageObjects = await Promise.all(filesToProcess.map(async (file) => {
        const thumbnail = await createThumbnail(file);
        return { file, thumbnail };
      }));

      newImages = newImages.concat(imageObjects);
      setImages(newImages);
      setButtonState('Generate Report');
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);
    const files = Array.from(e.dataTransfer.files);
    handleFileChange(files);
  };

  const handleDrag = (e, isActive) => {
    e.preventDefault();
    setIsDragActive(isActive);
  };

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    handleFileChange(files);
  };

  const renderInspectionOptions = () => (
    <Box sx={{ mb: 3, border: '1px solid #fff', borderRadius: '8px', p: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Select Room</InputLabel>
        <Select
          value={selectedRoomId || ''}
          onChange={handleRoomChange}
          label="Select Room"
        >
          {rooms.length > 0 ? (
            rooms.map((room) => (
              <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>
            ))
          ) : (
            <MenuItem disabled>No rooms available</MenuItem>
          )}
        </Select>
      </FormControl>

      <DropZone
        mt={2}
        onDrop={handleDrop}
        onDragOver={(e) => handleDrag(e, true)}
        onDragLeave={(e) => handleDrag(e, false)}
        isdragactive={isdragactive ? 'true' : 'false'}
        onClick={() => fileInputRef.current.click()}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*"
          multiple
          onChange={handleFileInputChange}
        />
        <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
        <Typography variant="body1">
          Drag & drop images here, or click to select files (up to 5 images)
        </Typography>
      </DropZone>

      <Box display="flex" justifyContent="center" mt={2} flexWrap="wrap">
        {code && images.length === 0 && (
          <Thumbnail>
            <canvas ref={canvasRef} width={210} height={210} />
          </Thumbnail>
        )}
        {images.map((imageObj, index) => (
          <Thumbnail key={index}>
            <img src={imageObj.thumbnail} alt={`Thumbnail ${index + 1}`} />
          </Thumbnail>
        ))}
      </Box>

      <Box mt={2} textAlign="center">
        <Button
          variant="contained"
          onClick={buttonState === 'Generate Report' ? handleGenerateReport : handleEmailIn}
          disabled={
            loading ||
            (buttonState === 'Generate Report' && (!selectedRoomId || images.length === 0))
          }
        >
          {loading ? <CircularProgress size={24} /> : buttonState}
        </Button>
      </Box>
      {error && <Typography color="error" mt={2}>{error}</Typography>}
    </Box>
  );

  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
      <Box my={0}>
        <NavToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth>
          <NavToggleButton value="photos"><AddAPhoto /></NavToggleButton>
          <NavToggleButton value="report"><Assessment /></NavToggleButton>
        </NavToggleButtonGroup>
      </Box>
      <Box sx={theme.innerBox}>
        {renderInspectionOptions()}
      </Box>
    </Box>
  );
};

export default InspectionOptions;