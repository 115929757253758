import React, { useState, useEffect, useCallback } from 'react';
import { Popover, Avatar, TextField, Box, Typography, InputAdornment, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Business, Check } from '@mui/icons-material';
import theme from '../../theme';

const AccountInvoicing = ({
  accountState,
  handleInputChange,
  handleSubmit,
  updateSuccess,
  isUpdating,
  handleImageUpload,
  loading,
  error,
  invoiceFormat,
  selectedInvoiceFormat,
  setSelectedInvoiceFormat,
  invoiceCurrency,
  selectedInvoiceCurrency,
  setSelectedInvoiceCurrency,
  taxCodes,
  selectedTaxRate,
  setSelectedTaxRate
}) => {

  const [textPopoverAnchorEl, setTextPopoverAnchorEl] = useState(null);
  const [largeText, setLargeText] = useState(accountState.invoicePaymentInstructions || '');

  const handleTextPopoverOpen = (event) => {
    setTextPopoverAnchorEl(event.currentTarget);
  };

  const handleTextPopoverClose = () => {
    setTextPopoverAnchorEl(null);
    handleInputChange('invoicePaymentInstructions', largeText); // Update main text field
  };

  const textPopoverOpen = Boolean(textPopoverAnchorEl);

  return (
    <Box sx={theme.customFormBox}>
      <Box sx={theme.customLogoBox} onClick={() => document.getElementById('company-logo-input').click()}>
        <Box sx={theme.customLogoGreyBox} />
        <Avatar
          sx={theme.customLogoAvatar}
          src={accountState.logo || '/images/avatars/painter.webp'}
          onClick={(e) => {
            e.stopPropagation();
            document.getElementById('company-logo-input').click();
          }}
        />
      </Box>

      <input
        id="company-logo-input"
        type="file"
        hidden
        onChange={handleImageUpload}
      />
      <Box sx={{ ...theme.customFormBox }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            fontFamily: '"Figtree", sans-serif',
          }}
          gutterBottom
        >
          Invoice Numbering
        </Typography>
        <TextField
          label="Invoice Start Number"
          value={accountState.invoiceStartNum}
          onChange={(e) => handleInputChange('invoiceStartNum', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Business />
              </InputAdornment>
            ),
          }}
        />
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel>Invoice Format</InputLabel>
          <Select
              value={selectedInvoiceFormat || accountState.invoiceFormat} 
              onChange={(e) => {
                handleInputChange('invoiceFormat', e.target.value); // Update accountState when a format is selected
                setSelectedInvoiceFormat(e.target.value); // Keep the UI in sync
              }}
              label="Invoice Format"
            >
              {loading ? (
                <MenuItem disabled>Loading invoice format...</MenuItem>
              ) : error ? (
                <MenuItem disabled>Error loading invoice format</MenuItem>
              ) : invoiceFormat.length > 0 ? (
                invoiceFormat.map((formatObj) => (
                  <MenuItem key={formatObj.format} value={formatObj.format}>
                    {formatObj.format}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No invoice formats available</MenuItem>
              )}
            </Select>

        </FormControl>
        <TextField
          label="Invoice Prefix"
          value={accountState.invoicePrefix}
          onChange={(e) => handleInputChange('invoicePrefix', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
        />
      </Box>

      <Box sx={{ ...theme.customFormBox }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            fontFamily: '"Figtree", sans-serif',
          }}
          gutterBottom
        >
          Payment Terms
        </Typography>
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel>Invoice Terms</InputLabel>
          <Select
            value={accountState.paymentTerms}
            onChange={(e) => handleInputChange('paymentTerms', e.target.value)}
            label="Invoice Terms"
          >
            <MenuItem value="Net 30">Net 30</MenuItem>
            <MenuItem value="Net 60">Net 60</MenuItem>
            <MenuItem value="Due on Receipt">Due on Receipt</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Late Payment Fees"
          value={accountState.latePaymentFees}
          onChange={(e) => handleInputChange('latePaymentFees', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
        />
        <TextField
          label="Discounts"
          value={accountState.invoiceDiscounts}
          onChange={(e) => handleInputChange('invoiceDiscounts', e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            ...theme.inputStyle,
            '& .MuiOutlinedInput-root': { border: 'none' },
          }}
        />
      </Box>

      <Box sx={{ ...theme.customFormBox }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            fontFamily: '"Figtree", sans-serif',
          }}
          gutterBottom
        >
          Currency and Tax
        </Typography>
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel>Currency</InputLabel>
          <Select
              value={selectedInvoiceCurrency || accountState.selectedInvoiceCurrency}
              onChange={(e) => {
                const selectedCurrency = invoiceCurrency.find(currency => currency.code === e.target.value); // Get selected currency details
                if (selectedCurrency) {
                  handleInputChange('selectedInvoiceCurrency', selectedCurrency.code); // Save currency code in accountState
                  setSelectedInvoiceCurrency(selectedCurrency.code); // Save the value in component state
                }
              }}
              label="Currency"
            >
              {loading ? (
                <MenuItem disabled>Loading currencies...</MenuItem>
              ) : error ? (
                <MenuItem disabled>Error loading currencies</MenuItem>
              ) : invoiceCurrency.length > 0 ? (
                invoiceCurrency.map((currency) => (
                  <MenuItem key={currency.code} value={currency.code}>
                    {currency.name} - {currency.symbol}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No currencies available</MenuItem>
              )}
            </Select>

        </FormControl>


        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel>Tax Rate</InputLabel>
          <Select
            value={accountState.selectedTaxRate || selectedTaxRate} // Ensure the state reflects the selected tax code ID
            onChange={(e) => {
              const selectedTaxCode = taxCodes.find(taxcode => taxcode.id === e.target.value); // Get selected tax code details
              handleInputChange('selectedTaxRate', selectedTaxCode.id); // Save taxCodeId in accountState
              setSelectedTaxRate(selectedTaxCode.id); // Save in the component state
            }}
            label="Tax Rate"
          >
            {loading ? (
              <MenuItem disabled>Loading tax rates...</MenuItem>
            ) : error ? (
              <MenuItem disabled>Error loading tax rates</MenuItem>
            ) : taxCodes.length > 0 ? (
              taxCodes.map((taxcode) => (
                <MenuItem key={taxcode.id} value={taxcode.id}> {/* Use taxcode.id as value */}
                  {taxcode.countryName} - {taxcode.taxType} - {(typeof taxcode.taxRate === 'number' && !isNaN(taxcode.taxRate)) ? (taxcode.taxRate * 100).toFixed(0) + '%' : 'N/A'}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No tax rates available</MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ ...theme.customFormBox }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            fontFamily: '"Figtree", sans-serif',
          }}
          gutterBottom
        >
          Customer Reference
        </Typography>
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel>Customer Reference</InputLabel>
          <Select
            value={accountState.customerReferencePO}
            onChange={(e) => handleInputChange('customerReferencePO', e.target.value)}
            label="Customer Reference Required"
          >
            <MenuItem value="Optional">Optional</MenuItem>
            <MenuItem value="Required">Required</MenuItem>
          </Select>
        </FormControl>
        <TextField
            label="Payment Instructions"
            value={accountState.invoicePaymentInstructions}
            onClick={handleTextPopoverOpen}
            fullWidth
            variant="outlined"
            sx={{
              ...theme.inputStyle,
              '& .MuiOutlinedInput-root': { border: 'none' },
            }}
            InputProps={{ readOnly: true }}
          />
         <Button
          variant="contained"
          color={updateSuccess ? 'success' : 'primary'}
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
          disabled={isUpdating}
        >
          {isUpdating
            ? 'Updating...'
            : updateSuccess
            ? 'Account Updated'
            : 'Update Details'}
          {updateSuccess && <Check style={{ marginLeft: '4px' }} />}
        </Button>
      </Box>
       {/* Popover for large text input */}
       <Popover
        open={textPopoverOpen}
        anchorEl={textPopoverAnchorEl}
        onClose={handleTextPopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 2, width: 400 }}>
          <TextField
            label="Edit Payment Instructions"
            value={largeText}
            onChange={(e) => setLargeText(e.target.value)}
            fullWidth
            multiline
            rows={6}
            variant="outlined"
          />
          <Button onClick={handleTextPopoverClose} sx={{ mt: 2 }} variant="contained">
            Save
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

export default AccountInvoicing;
