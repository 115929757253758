import React from 'react';
import { Box, TextField, Typography, InputAdornment } from '@mui/material';
import { DatePicker, LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const SchedulePanel = ({ startDate, setStartDate, endDate, setEndDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box p={2} border="2px solid #ccc" borderRadius="8px" boxShadow={2} bgcolor="#f9f9f9">
        <Typography variant="h6" gutterBottom>
          Schedule
        </Typography>
        <Box my={2}>
          <div>Schedule Panel Loaded</div> {/* Add simple element */}
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newDate) => setStartDate(newDate)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                  style: { backgroundColor: 'white', borderColor: 'black', color: 'black' },
                }}
              />
            )}
          />
        </Box>
        <Box my={2}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newDate) => setEndDate(newDate)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                  style: { backgroundColor: 'white', borderColor: 'black', color: 'black' },
                }}
              />
            )}
          />
        </Box>
        <Box my={2} sx={{
          '& .MuiPickerStaticWrapper-root': {
            width: '80%',
            padding: '5px', // Reduced padding
            backgroundColor: '#f00',
            borderRadius: '8px',
          },
          '& .MuiPaper-root': {
            borderRadius: '8px',
            backgroundColor: '#00f',
            width: '80%', // Ensure the calendar width matches the panel width
            boxShadow: 'none', // Remove shadow for cleaner look
          },
          '& .MuiPickersBasePicker-container': {
            backgroundColor: '#00f',
            width: '80%',
          },
          '& .MuiPickersCalendarHeader-root': {
            width: '80%',
            minHeight: 'auto', // Reduce header height
            padding: '5px 0', // Reduce padding
            backgroundColor: '#0f0',
          },
          '& .MuiPickersCalendarHeader-switchHeader': {
            padding: '0 5px', // Reduce padding
          },
          '& .MuiTypography-h4': {
            fontSize: '1rem', // Reduce font size
          },
          '& .MuiPickersDay-root': {
            width: '24px', // Reduce day size
            height: '24px', // Reduce day size
            margin: '0 2px', // Reduce margin
          },
          '& .MuiPickersDay-dayWithMargin': {
            margin: '0 2px', // Ensure consistent margin
          },
          '& .MuiPickersDay-today': {
            borderColor: 'transparent', // Remove today's border
          },
        }}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            openTo="day"
            value={startDate}
            onChange={(newDate) => setStartDate(newDate)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                InputProps={{
                  ...params.InputProps,
                  style: { backgroundColor: 'white', borderRadius: '8px', padding: '5px' },
                }}
              />
            )}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default SchedulePanel;
