import React from 'react';
import { Rect, Text } from 'react-konva';
import { PIXELS_PER_METER } from './Constants';

const NewRoom = ({ newRoom }) => {
  if (!newRoom) return null;

  return (
    <React.Fragment>
      <Rect
        name="room"
        x={newRoom.x}
        y={newRoom.y}
        width={newRoom.width}
        height={newRoom.length}
        fill="white"
        stroke="black"
        strokeWidth={8}
      />
      <Text
        name="text"
        x={newRoom.x + newRoom.width / 2}
        y={newRoom.y + newRoom.length / 2 - 10}
        text={newRoom.name}
        fontSize={16}
        fill="black"
        align="center"
        verticalAlign="middle"
        width={Math.abs(newRoom.width)}
        offsetX={Math.abs(newRoom.width) / 2}
        onClick={(e) => (e.cancelBubble = true)}
      />
      {Math.abs(newRoom.width) >= PIXELS_PER_METER * 2 && Math.abs(newRoom.length) >= PIXELS_PER_METER * 2 && (
        <Text
          name="text"
          x={newRoom.x + newRoom.width / 2}
          y={newRoom.y + newRoom.length / 2 + 10}
          text={`${Math.abs(newRoom.width / PIXELS_PER_METER).toFixed(2)}m x ${Math.abs(newRoom.length / PIXELS_PER_METER).toFixed(2)}m`}
          fontSize={12}
          fill="black"
          align="center"
          verticalAlign="middle"
          width={Math.abs(newRoom.width)}
          offsetX={Math.abs(newRoom.width) / 2}
          onClick={(e) => (e.cancelBubble = true)}
        />
      )}
    </React.Fragment>
  );
};

export default NewRoom;
