import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';

const Mapping = () => {
  const mapRef = useRef(null);
  const [steps, setSteps] = useState([]);
  
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  // Function to convert postal code to coordinates using Mapbox Geocoding API
  const getCoordinatesFromPostalCode = async (postalCode) => {
    try {
      const geocodeResponse = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(postalCode)}.json`,
        {
          params: {
            access_token: mapboxgl.accessToken,
            limit: 1,
          },
        }
      );
      const coordinates = geocodeResponse.data.features[0]?.geometry?.coordinates;
      if (!coordinates) {
        throw new Error(`Unable to get coordinates for postal code: ${postalCode}`);
      }
      return coordinates;
    } catch (error) {
      console.error(`Error fetching coordinates for postal code ${postalCode}:`, error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchDirections = async () => {
      try {
        // Retrieve appState from localStorage
        const appState = JSON.parse(localStorage.getItem('appState'));
        const userId = appState?.userId;
        const jobId = appState?.currentJobID; // Get jobId from appState

        if (!userId || !jobId) {
          console.error('User ID or Job ID not found in appState');
          return;
        }

        // Fetch account address using userId
        const accountResponse = await axios.get(`${API_ENDPOINT}/api/accounts/user/${userId}`);
        const accountData = accountResponse.data;
        const accountPostalCode = accountData.postalCode;

        if (!accountPostalCode) {
          console.error('Account postal code not found.');
          return;
        }

        // Fetch job data to get the client details
        const jobsResponse = await axios.get(`${API_ENDPOINT}/api/jobs`, { params: { userId } });
        const job = jobsResponse.data.find((job) => job._id === jobId);
        const clientId = job?.ClientID?._id;

        if (!clientId) {
          console.error('Client ID not found for the selected job.');
          return;
        }

        // Fetch client address using clientId
        const clientsResponse = await axios.get(`${API_ENDPOINT}/api/clients`);
        const client = clientsResponse.data.find((client) => client._id === clientId);
        const clientPostalCode = client?.clientPostalCode;

        if (!clientPostalCode) {
          console.error('Client postal code not found.');
          return;
        }

        // Convert postal codes to coordinates
        const accountCoordinates = await getCoordinatesFromPostalCode(accountPostalCode);
        const clientCoordinates = await getCoordinatesFromPostalCode(clientPostalCode);

        // Fetch directions from account coordinates to client coordinates
        const directionsResponse = await axios.get(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${accountCoordinates.join(',')};${clientCoordinates.join(',')}`,
          {
            params: {
              geometries: 'geojson',
              access_token: mapboxgl.accessToken,
            },
          }
        );

        const directionsData = directionsResponse.data;
        const route = directionsData.routes && directionsData.routes[0];

        if (!route || !route.geometry || !route.geometry.coordinates || route.geometry.coordinates.length === 0) {
          console.error('No valid route or coordinates found');
          return;
        }

        const directionsCoordinates = route.geometry.coordinates;
        setSteps(route.legs[0].steps.map((step) => step.maneuver.instruction));

        // Initialize the Mapbox 3D map
        const map = new mapboxgl.Map({
          container: mapRef.current,
          style: 'mapbox://styles/mapbox/streets-v12', // Using a valid style URL
          center: directionsCoordinates[0],
          zoom: 12,
          pitch: 45, // Enable pitch for 3D effect
          bearing: -17.6, // Adjust bearing for better 3D view
          antialias: true,
        });

        map.on('load', () => {
          // Add the route to the map
          map.addSource('route', {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: directionsCoordinates,
              },
            },
          });

          map.addLayer({
            id: 'route',
            type: 'line',
            source: 'route',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#3887be',
              'line-width': 5,
            },
          });

          // Add 3D buildings
          map.addLayer({
            id: '3d-buildings',
            source: 'composite',
            'source-layer': 'building',
            filter: ['==', 'extrude', 'true'],
            type: 'fill-extrusion',
            minzoom: 15,
            paint: {
              'fill-extrusion-color': '#aaa',
              'fill-extrusion-height': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                16.05,
                ['get', 'height'],
              ],
              'fill-extrusion-base': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                16.05,
                ['get', 'min_height'],
              ],
              'fill-extrusion-opacity': 0.6,
            },
          });

          // Add terrain to the map for 3D effect
          map.addSource('mapbox-dem', {
            type: 'raster-dem',
            url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
            tileSize: 512,
            maxzoom: 14,
          });
          map.setTerrain({ source: 'mapbox-dem', exaggeration: 3.5 });

          // Fit the map to the route
          const bounds = directionsCoordinates.reduce((bounds, coord) => {
            return bounds.extend(coord);
          }, new mapboxgl.LngLatBounds(directionsCoordinates[0], directionsCoordinates[0]));

          map.fitBounds(bounds, { padding: 50 });
        });
      } catch (error) {
        console.error('Failed to fetch directions:', error);
      }
    };

    fetchDirections();
  }, [API_ENDPOINT]);

  return (
    <Box display="flex" justifyContent="center" width="95%">
      {/* Map container */}
      <div ref={mapRef} style={{ height: '500px', width: '100%' }}></div>
      {/* Directions */}
      <Box>
        <Typography variant="h6">Directions:</Typography>
        <ul>
          {steps.map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export default Mapping;
